import { env } from '../../../src/appConfig'
import getApiResponse from "../../services/getApiResponse";

export const generateInvoice = (payload) => {
    return async (dispatch, getState) => {
        const corrId = getState().searchForm.uuid;
        const isInternalUserAutheticated = getState().internalUser.isInternalUserAutheticated;

        const config = {
            method: "POST",
            url: env.REACT_APP_API_BASE_URL + `/generateInvoice`,
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "cache-control": "no-cache",
                correlationId: corrId,
            },
            data: payload
        }


        const result = await getApiResponse(config);

        if (result) {
            return result
        }
    }
}