import { setRepriceQuote} from '../../appStore/actionCreators/myQuotes'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { env } from '../../../src/appConfig'
import { setBrandCode } from "../../appStore/actionCreators/fareOption"
import { setItenaryData, setItinaryFaresType, setRepriceFare, setRepriceFareLoader } from '../actionCreators/flightBooking';
import getApiResponse from "../../services/getApiResponse";
import { taxIncreasedStatusArray } from '../../utils/constant';

export const getRepriceBeforePayment = (recordLocator) => {
    return async (dispatch, getState) => {
        // dispatch(setRepriceFareLoader(true))
        const corrId = getState().searchForm.uuid;
        const orgId = getState().agentProfile.orgId;
        const agencyId = getState().agentProfile.agencyId;
        const agencyGroupId = getState().agentProfile.agencyGroupId; 
        const flightType = getState().searchForm.flightType;
        const isInternalUserAutheticated = getState().internalUser.isInternalUserAutheticated;
        const bookingChannel = isInternalUserAutheticated ? "internal" : "external";
       
        //Axios payload

        const config = {
            method: "POST",
            url : env.REACT_APP_API_BASE_URL + '/booking/process/verifyPNRPrice',
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                
                "Access-Control-Allow-Credentials": true,
                correlationId: corrId,
                flightType:flightType,
                bookingChannel:bookingChannel
                
            },
            data:
                
                    {
                        organizationId: orgId,
                        agencyId: agencyId,
                        agencyGroupId: agencyGroupId,
                        recordLocator: recordLocator       
                    }
        }


        const rePriceResult = await getApiResponse(config);
        // dispatch(setRepriceFareLoader(false))
        if(rePriceResult && rePriceResult.hasOwnProperty("fareChange")){
         dispatch(setRepriceFare(rePriceResult))
        }  
        if(rePriceResult && rePriceResult.hasOwnProperty("itineraryFare")){  
            if(rePriceResult && taxIncreasedStatusArray.includes(rePriceResult?.fareChange?.status)) {        
            dispatch(setItenaryData(rePriceResult.itineraryFare))
            dispatch(setItinaryFaresType(rePriceResult && rePriceResult.itineraryFare && rePriceResult.itineraryFare.type))
            }
        }
        return rePriceResult

    }
}