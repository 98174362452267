import moment from "moment"

export const setMeal = (newMeal, oldMeal ,isEdit) => {

  if (newMeal?.code === undefined && oldMeal?.code && oldMeal?.code?.length !== 0){
    return "delete"
  }
  if (newMeal?.code === undefined) return false
  if (newMeal?.code === "" || newMeal?.code?.length === 0) return false
  if (newMeal?.code === oldMeal?.code) {
    return false
  } else {
    if (!isEdit && ((oldMeal?.code === undefined) || (oldMeal?.code && oldMeal?.code == "") || (oldMeal?.code && oldMeal?.code?.length === 0))) {
      return "add"
    } else {
      return "edit"
    }
  }

}

export const setAssistance = (newAssistane, oldAssistance, isEdit) => {

  if (newAssistane?.code === undefined && oldAssistance?.code && oldAssistance?.code?.length !== 0){
    return "delete"
  }
  if (newAssistane?.code === undefined) return false
  if (newAssistane?.code === "" || newAssistane?.code?.length === 0) return false
  if (newAssistane?.code === oldAssistance?.code) {

    return false
  } else {
    if (!isEdit && ((oldAssistance?.code === undefined) || (oldAssistance?.code && oldAssistance?.code == "") || (oldAssistance?.code && oldAssistance?.code?.length === 0))) {
      return "add"
    } else {
      return "edit"
    }
  }

}

export const setWheelChair = (newWheelChair, oldWheelChir , isEdit) => {

  if (newWheelChair?.code === undefined && oldWheelChir?.code && oldWheelChir?.code?.length !== 0){
    return "delete"
  }
  if (newWheelChair?.code === undefined) return false
  if (newWheelChair?.code === "" || newWheelChair?.code?.length === 0) return false
  if (newWheelChair?.code === oldWheelChir?.code) {
    return false
  } else {
    if (!isEdit && ((oldWheelChir?.code === undefined) || (oldWheelChir?.code && oldWheelChir?.code == "") || (oldWheelChir?.code && oldWheelChir?.code?.length === 0))) {
      return "add"
    } else {
      return "edit"
    }
  }

}

export const setSeatDetail = (newSeat, oldSeat,isEdit) => {

  if (!isEdit && newSeat?.seatNumber === undefined) return false

  if (newSeat?.seatNumber === "" || newSeat?.seatNumber?.length === 0) return false
  if (newSeat?.seatNumber === oldSeat?.seatNumber) {
    return false
  } else {
    if (((oldSeat === undefined) || (oldSeat?.seatNumber === undefined) || (oldSeat?.seatNumber && oldSeat?.seatNumber == "") || (oldSeat?.seatNumber && oldSeat?.seatNumber?.length === 0))) {
      return "add"
    } else {
      return "edit"
    }
  }

}


export const addSeatData =(flightData,j,flightArr,addSeat,carrierData)=>{

  if (Object.keys(flightData[j].seat).length === 0) {
       
      let flightIndex = flightArr.findIndex(function (element) {
        return element.flightNumber == flightData[j].flightNumber;
      });

      if (flightIndex === -1) {
        flightArr.push({ flightNumber: flightData[j].flightNumber, seat: addSeat, carrier: carrierData && carrierData[0].carrier })
      } else {
        flightArr = [
          ...flightArr.slice(0, flightIndex),
          {
            ...flightArr[flightIndex],
            seat: addSeat
          },
          ...flightArr.slice(flightIndex + 1),
        ];
      }

  } else {
      let flightIndex = flightArr.findIndex(function (element) {
        return element.flightNumber == flightData[j].flightNumber;
      });

      if(flightIndex>=0){
        flightArr = [
          ...flightArr.slice(0, flightIndex),
          {
            ...flightArr[flightIndex],
            seat: addSeat,
          },
          ...flightArr.slice(flightIndex + 1),
        ];
      } else{
        flightArr.push({ flightNumber: flightData[j].flightNumber, seat: addSeat, carrier: carrierData && carrierData[0].carrier })
      }
  
  }

    return {flightArr:flightArr}

 }



 export const addMealData =(flightData,j,flightArr,addMeal,carrierData)=>{

  if (Object.keys(flightData[j].meal).length === 0 || flightData[j].meal === undefined) {

        let flightIndex = flightArr.findIndex(function (element) {
          return element.flightNumber == flightData[j].flightNumber;
        });

        if (flightIndex === -1) {
          flightArr.push({ flightNumber: flightData[j].flightNumber, meal: addMeal, carrier: carrierData && carrierData[0].carrier })
        } else {
          flightArr = [
            ...flightArr.slice(0, flightIndex),
            {
              ...flightArr[flightIndex],
              meal: addMeal
            },
            ...flightArr.slice(flightIndex + 1),
          ];


        }
    
  } else {
      let flightIndex = flightArr.findIndex(function (element) {
        return element.flightNumber == flightData[j].flightNumber;
      });

      if(flightIndex>=0){
        flightArr = [
          ...flightArr.slice(0, flightIndex),
          {
            ...flightArr[flightIndex],
            meal: addMeal
          },
          ...flightArr.slice(flightIndex + 1),
        ];
      } else{
        flightArr.push({ flightNumber: flightData[j].flightNumber, meal: addMeal, carrier: carrierData && carrierData[0].carrier })
      }
       
  }

  return {flightArr:flightArr}

 }


 export const addWheelChirData=(flightData,j,flightArr,addWhewlchair,carrierData)=>{

  if (Object.keys(flightData[j].wheelChair).length === 0) {

      let flightIndex = flightArr.findIndex(function (element) {
        return element.flightNumber == flightData[j].flightNumber;
      });

      if (flightIndex === -1) {
        flightArr.push({ flightNumber: flightData[j].flightNumber, wheelChair: addWhewlchair, carrier: carrierData && carrierData[0].carrier })
      } else {
        flightArr = [
          ...flightArr.slice(0, flightIndex),
          {
            ...flightArr[flightIndex],
            wheelChair: addWhewlchair
          },
          ...flightArr.slice(flightIndex + 1),
        ];
      }
    
  } else {

      let flightIndex = flightArr.findIndex(function (element) {
        return element.flightNumber == flightData[j].flightNumber;
      });
      if(flightIndex>=0){
        flightArr = [
          ...flightArr.slice(0, flightIndex),
          {
            ...flightArr[flightIndex],
            wheelChair: addWhewlchair
          },
          ...flightArr.slice(flightIndex + 1),
        ];
      } else{
        flightArr.push({ flightNumber: flightData[j].flightNumber, wheelChair: addWhewlchair, carrier: carrierData && carrierData[0].carrier })
      }
  
  }

  return {flightArr:flightArr}

 }


 export const addAssistData=(flightData,j,flightArr,addAssistance,carrierData)=>{

  if (Object.keys(flightData[j].assistance).length === 0) {

      let flightIndex = flightArr.findIndex(function (element) {
        return element.flightNumber == flightData[j].flightNumber;
      });

      if (flightIndex === -1) {
        flightArr.push({ flightNumber: flightData[j].flightNumber, assistance: addAssistance, carrier: carrierData && carrierData[0].carrier })
      } else {
        flightArr = [
          ...flightArr.slice(0, flightIndex),
          {
            ...flightArr[flightIndex],
            assistance: addAssistance
          },
          ...flightArr.slice(flightIndex + 1),
        ];
      }
  
  } else {

      let flightIndex = flightArr.findIndex(function (element) {
        return element.flightNumber == flightData[j].flightNumber;
      });

      if(flightIndex>=0){
        flightArr = [
          ...flightArr.slice(0, flightIndex),
          {
            ...flightArr[flightIndex],
            assistance: addAssistance
          },
          ...flightArr.slice(flightIndex + 1),
        ];
      } else{
        flightArr.push({ flightNumber: flightData[j].flightNumber, assistance: addAssistance, carrier: carrierData && carrierData[0].carrier })
      }
  }

  return {flightArr:flightArr}

 }


 

 export const  getAge = (dateString,departDate) => {
  let date2 = moment(departDate).format('MM/DD/YYYY') ;
  let date1 = moment(dateString).format('MM/DD/YYYY') ;
  const diffTime = Math.abs(new Date(date1) - new Date(date2));
  const diffYear = diffTime / (1000 * 60 * 60 * 24 * 365);
  return(diffYear)
  // var ageInMilliseconds = new Date(departDate) - new Date(dateString);
  // console.log("ageInMilliseconds",ageInMilliseconds/1000/60/60/24/365)
  // return Math.floor(ageInMilliseconds/1000/60/60/24/365); // convert to years
  // return (ageInMilliseconds/1000/60/60/24/365); // convert to years

}

