import enUS from "date-fns/locale/en-US";
import { registerLocale } from "react-datepicker";
import { useSelector } from "react-redux";
registerLocale("en", enUS)

export default function GetCalenderLanguage(){
const lang = useSelector( (state) => state.lang.lang)
const CustomFrenchDays = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
const CustomFrenchMonths = ["Janvier","Février","Mars","Avril","Mai",
"Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"]
const locale = {
  localize: {
    day: n => CustomFrenchDays[n],
    month: n => CustomFrenchMonths[n]
  },
  formatLong: {
    date: () => 'mm/dd/yyyy'
  }
}

const language  = lang == "en" ? "en" : locale
return language;
}
 
export const EnglishMonts =
{
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",
}

export const FrenchMonts =
{
    January: "Janvier",
    February: "Février",
    March: "Mars",
    April: "Avril",
    May: "Mai",
    June: "Juin",
    July: "Juillet",
    August: "Août",
    September: "Septembre",
    October: "Octobre",
    November: "Novembre",
    December: "Décembre",
}

export const EnglishShortMonths =
{
  "Jan": "Jan(1)",
  "Feb": "Feb(2)",
  "Mar": "Mar(3)",
  "Apr": "Apr(4)",
  "May.": "May(5)",
  "Jun": "Jun(6)",
  "Jul": "Jul(7)",
  "Aug": "Aug(8)",
  "Sep": "Sep(9)",
  "Oct": "Oct(10)",
  "Nov": "Nov(11)",
  "Dec": "Dec(12)",
}

export const FrenchShortMonths =
{
  "Jan": "Jan(1)",
  "Feb": "Fév(2)",
  "Mar": "Mars(3)",
  "Apr": "Avr(4)",
  "May.": "Mai(5)",
  "Jun": "Juin(6)",
  "Jul": "Juil(7)",
  "Aug": "Août(8)",
  "Sep": "Sept(9)",
  "Oct": "Oct(10)",
  "Nov": "Nov(11)",
  "Dec": "Déc(12)",
}