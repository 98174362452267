export const AGENT_PROFILE_CHANGE = "AGENT_PROFILE_CHANGE";
export const AGENT_PROFILE_INPUT_CHANGE = "AGENT_PROFILE_INPUT_CHANGE";
export const AGENT_PROFILE_ID = "AGENT_PROFILE_ID";
export const AGENT_PROFILE_ENABLE ="AGENT_PROFILE_ENABLE";


export const AGENT_PROFILE_CHANGE_ONE = "AGENT_PROFILE_CHANGE_ONE";
export const AGENT_PROFILE_INPUT_CHANGE_ONE = "AGENT_PROFILE_INPUT_CHANGE_ONE";
export const AGENT_PROFILE_ID_ONE = "AGENT_PROFILE_ID_ONE";
export const AGENT_PROFILE_ENABLE_ONE ="AGENT_PROFILE_ENABLE_ONE";
export const AGENT_PROFILE_DETAIL ="AgentProfileDetail";
export const AGENT_PROFILE_FEE ="AgentProfileFee";