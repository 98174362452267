// search
export const OPENJAW_FROM_A = 'OPENJAW_FROM_A'; 
export const OPENJAW_TO_A = 'OPENJAW_TO_A'; 

export const OPENJAW_FROM_B = 'OPENJAW_FROM_B'; 
export const OPENJAW_TO_B = 'OPENJAW_TO_B';

// date
export const OPENJAW_DATE_A = "OPENJAW_DATE_A";
export const OPENJAW_DATE_B = "OPENJAW_DATE_B";

// time
export const OPENJAW_TIME_A = "OPENJAW_TIME_A";
export const OPENJAW_TIME_B = "OPENJAW_TIME_B";

// counter
export const OPEN_JAW_ADULT_VALUE  = "OPEN_JAW_ADULT_VALUE";
export const OPEN_JAW_CHILD_VALUE  = "OPEN_JAW_CHILD_VALUE";
export const OPEN_JAW_INFANT_VALUE = "OPEN_JAW_INFANT_VALUE";


// checkbox
export const OPEN_JAW_AIR = "OPEN_JAW_AIR";
export const OPEN_JAW_LAND = "OPEN_JAW_LAND";
export const OPEN_JAW_CRUISE = "OPEN_JAW_CRUISE";
export const OPEN_JAW_LEAST = "OPEN_JAW_LEAST";
export const OPEN_JAW_BASIC = "OPEN_JAW_BASIC";
export const OPEN_JAW_MULTI = "OPEN_JAW_MULTI";
export const OPEN_JAW_NEAR = "OPEN_JAW_NEAR";
export const OPEN_JAW_FLEXIBLE = "OPEN_JAW_FLEXIBLE";


// dropdown
export const OPEN_JAW_CABIN = "OPEN_JAW_CABIN";
export const OPEN_JAW_MAXIMUM_CONN = "OPEN_JAW_MAXIMUM_CONN";



// Radio button

export const OPEN_JAW_FREE = "OPEN_JAW_FREE";
export const OPEN_JAW_FEE = "OPEN_JAW_FEE";
export const OPEN_ANY_ALLIANCE = "OPEN_ANY_ALLIANCE";
export const OPEN_SKY_TEAM = "OPEN_SKY_TEAM";
export const OPEN_STAR_ALLIANCE = "OPEN_STAR_ALLIANCE";
export const OPEN_JAW_WORLD = "OPEN_JAW_WORLD";


export const REST_OPEN_JAW = "REST_OPEN_JAW";


// validation

export const FLYING_FROM_OJ_ERROR = "FLYING_FROM_OJ_ERROR"
export const FLYING_TO_OJ_ERROR = "FLYING_TO_OJ_ERROR"
export const FLYING_FROM_OJ_B_ERROR = "FLYING_FROM_OJ_B_ERROR"
export const FLYING_TO_OJ_B_ERROR = "FLYING_TO_OJ_B_ERROR"

export const FLYING_DATE_OJA_ERROR = "FLYING_DATE_OJA_ERROR"
export const FLYING_DATE_OJB_ERROR = "FLYING_DATE_OJB_ERROR"

export const ADULT_OJ_ERROR = "ADULT_OJ_ERROR"

// Set OpenJaw Token

export const SET_OPENJAW_TOKEN = "SET_OPENJAW_TOKEN"

// Set openJaw Data

export const SET_OPENJAW_DATA = "SET_OPENJAW_DATA" 