import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Header from '../home/Header';
import { BookingProvider } from '../home/bookingContext';
import SpinnerLoader from '../../../components/common/spinner';
import BookingFor from "../home/Bookingfor";
import tick from '../../../Assets/tickIcon.png'
import { useTranslation } from 'react-i18next';


export default function IntermediateBookingConfirmation(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const pnr = useSelector((state) => state.flightBooking.pnr);
  let isDuffleFlow = useSelector((state)=> state.itinenaries.providerType);

  return (
    <>
      <Header setClass="container-fluid" show="d-none" />
      {true ? <BookingProvider>
        <BookingFor />

        <div className="booking-main">

          <div className="container bd-main-container pt-5 pb-5">
            {/* <div className="configProfit-col intermediateConfirmMain" >
              <div className="bd-alertBox intermediateConfirmAlert" >
                <img className='intermediateConfirmAlertImg' src={tick}></img>
                <div className='intermediateConfirmContainer'>
                  <span> <h3 className='intermediateConfirmAlertMainText'>{t("Success")}</h3> </span><span style={{ "fontSize": "1rem" }}>{t("Thank you. Your payment has been saved in your file and tickets will be issued. Click on the file number to view the ticketing status.")}</span>
                  <div className='intermediateConfirmRecordLocator' onClick={() => { history.push("/booking-confirmation") }}>
                    {pnr}
                  </div>
                </div>
              </div>
            </div> */}

            <div className='travel__IntermediateBox'>
              <div className='travel__IntermediateLeft'>
                <div className='travel__IntermeCheckBox'>
                  <div className='travel__IntermeCheck'>
                  <i className='fa fa-check'></i>
                  </div>
                </div>
                <h3 className='travel__IntermediateTitle'>{isDuffleFlow ? t("Thank you for submitting your payment.") : t("Payment Successful!")}</h3>

                <div><span className='travel__IntermediateSubTitle'>{isDuffleFlow ? t("We are redirecting your payment information to the carrier.") : t("Thank you. Your payment has been saved in your file and tickets will be issued. Click on the file number to view the ticketing status.")}</span></div>
                { isDuffleFlow ? 
              <><br/><div className='travel__IntermediateSubTitle'>{t("If payment with the carrier is successful, the booking status will")}</div>
              <br/><div className='travel__IntermediateSubTitle'>{t("If payment with the carrier is unsuccessful, the booking status will")}</div></> : null}
              </div>
              <div className='travel__IntermediateRight' onClick={() => { history.push("/booking-confirmation") }}>
                <div className='travel__IntermeRightBox  px-0'>
                  <h5>{t("File Number")} </h5>
                </div>
                <div className='travel__IntermeRightBox travel__IntermeBorder'>
                  <h3 className='travel__IntermediatePNR'>{pnr}</h3>
                </div>
              </div>
            </div>




          </div>
        </div>

      </BookingProvider >
        :
        <div className="col-md-12 text-center mt-3" style={{ height: "50vh" }}>
          <SpinnerLoader />
        </div>
      }


    </>
  )
}