import React, { useState, useEffect } from "react";
import planeBlack from "../../Assets/plane-black.svg";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import { setFlightData, setSaveSeat ,setSeat } from "../../appStore/actionCreators/itinenary"
import { PassengerType } from "../../../src/utils/constant";
import Dialog from "@material-ui/core/Dialog";
import { useTranslation } from "react-i18next";

toast.configure()

function SeatUserCnx(props) {
    const [passengerId, setPassengerId] = useState(props.passengerData && props.passengerData[0] && props.passengerData[0].id)
    const flightData = useSelector((state) => state.itinenaries.flightData);
    const seatData = useSelector((state) => state.itinenaries.seat);
    const dispatch = useDispatch();
    const [isMobile, setMobileView] = useState(false)
    const [flight, setFlight] = useState({})
    
   
    useEffect(() => {

        if (seatData.length > 0) {
            let id
            let updatedSeat = props.seatStatus && props.seatStatus.updatedSeat
           
        if (updatedSeat && updatedSeat.passengerId == passengerId && updatedSeat.seat && updatedSeat.flightNumber === flightData.flightNumber && Number(passengerId)<props.passengerData.length) {
            if(Number(passengerId)<props.passengerData.length){
                id = Number(passengerId) + 1
                setPassengerId(`${id}`)
            }  
            } else if (Object.keys(props.seatStatus).length > 0 && Number(passengerId)<props.passengerData.length) {

                id = Number(passengerId) + 1
                setPassengerId(`${id}`)  
            } else if (Object.keys(props.seatStatus).length === 0) {
                id = passengerId
            } else{
                id = passengerId
            }
            
            let newData = seatData.filter(obj => obj.flightNumber === (flightData &&flightData.flightNumber))[0]
            let data ={}
            if (newData) {
                let data ={}
                if(newData.passengerId==id) data=newData
                if(id){
                    data.passengerId = `${id}`
                }
            
                data.flightNumber = flightData.flightNumber
                data.id = flightData.id
                dispatch(setFlightData(data))
                let obj
                if (props.flight.number === flightData.flightNumber) {
                    obj = props.flight
                }

                if (props.flight) {
                    if (document.getElementById(props.flight.departureAirport + `_` + props.flight.arrivalAirport + `_` + id) !== null) {
                        document.getElementById(props.flight.departureAirport + `_` + props.flight.arrivalAirport + `_` + id).checked = true
                    }
                }
            }
            
        }

    }, [props.seatStatus])


    useEffect(() => {
        setMobileView(false)

    }, [flightData])

    useEffect(() => {
        if (flight && flight.number !== props.flight && props.flight.number) {
            let newPassengerId = props.passengerData && props.passengerData[0] && props.passengerData[0].id
            let flightNumber = props.flight && props.flight.number
            let flightId =  props.flight && props.flight.id
            setPassengerId(newPassengerId)
            dispatch(setFlightData({ id: flightId, index: 0, flightNumber: flightNumber }))

            addPassengerId(newPassengerId, flightNumber, flightId, props.flight)

            if (document.getElementById(props.flight.departureAirport + `_` + props.flight.arrivalAirport + `_` + newPassengerId) !== null) {
                document.getElementById(props.flight.departureAirport + `_` + props.flight.arrivalAirport + `_` + newPassengerId).checked = true
            }
        }
        if (props.flight) {
            setFlight(props.flight)
        }

    }, [props.flight])

    const addPassengerId = (id, flightNumber, flightId, obj) => {

        let data = {}
        if(id){
            data.passengerId = id
        }
        data.flightNumber = flightNumber
        data.id = flightId
        dispatch(setFlightData(data))
        if (obj) {
            if (document.getElementById(obj.departureAirport + `_` + obj.arrivalAirport + `_` + id) !== null) {
                document.getElementById(obj.departureAirport + `_` + obj.arrivalAirport + `_` + id).checked = true
            }
        }

    }

    const addSeat = () => {
        let validation = props.validationOfSeat() 
        if(validation){
         props.handleClickOpen()
        }else{
        let seatDataDetail = seatData
        dispatch(setSaveSeat(seatDataDetail));
        props.closeSeatMap()
        }
    }

    const getPassengerId = (id) => {
        setPassengerId(id)
        props.passengerId(id)
    }


    const toggleMobiltView = () => {
        if (isMobile) {
            setMobileView(false)
        } else {
            setMobileView(true)
        }

    }

    let amount = 0;
    seatData && seatData.map(data => {

        amount = amount + data.amount

    })

    const nextFlight = () => {
        let validation = props.validationOfSeat() 
        if(validation){
         props.handleClickOpen()
        }else{
        let seatDataDetail = seatData
        dispatch(setSaveSeat(seatDataDetail));
        props.nextFlight()
        }
    }
    let flightCarrierName = props.flight && props.flight.carrierName ? props.flight.carrierName : props.flight.carrierName;
    const { t } = useTranslation();
    return (
        <>
         
            <div className="seatCnx-tab">

                <div className="ss-airways-label">
                    <img src={planeBlack} alt="plan" />{flightCarrierName} {props.flight && props.flight.number}
                </div>
                <div className="seat-userCta">
                    <div className={isMobile ? `seat-user-list-wrapper active` : `seat-user-list-wrapper`}>
                        <div className="mobile-label" onClick={toggleMobiltView}>{t("Traveller details")} <span></span></div>
                        <div className="seat-user-list">
                            {props.passengerData && props.passengerData.map((passenger, i) => {
                                let data

                                if (seatData && seatData.length > 0 && flightData)
                                    data = seatData.filter(obj => (obj.passengerId == passenger.id && obj.flightNumber == flightData.flightNumber))[0]
                             
                                if(passenger.paxType !== PassengerType.INFANTINLAP)
                                return (
                                    <label className="seatUser-item" key={i}>
                                        <div className="seatUser-name">
                                            <input  type="radio" name="user-radio" id={props?.flight?.departureAirport + `_` + props?.flight?.arrivalAirport + `_` + passenger.id} onClick={() => getPassengerId(passenger.id)} />
                                            {passenger.firstName} {passenger.middleName} {passenger.lastName}
                                        </div>
                                        <div className="seatUser-type">
                                            <span>
                                                {passenger.paxType === PassengerType.INFANTINLAP ? t("Infant") : t(passenger.paxType)}{" "}
                                                {passenger.count}
                                            </span>
                                            <span className="selected-seat" >
                                                {(data && flightData.flightNumber == data.flightNumber) ? data.seat : "__"}
                                            </span>
                                            
                                            <span>{ (data && flightData.flightNumber == data.flightNumber) ? data.amount !== undefined ? `$`+ data.amount : `$`+ 0 : "__"}</span>
                                        </div>
                                    </label>
                                )
                            })}

                        </div>
                    </div>

                    <div className="seatUser-farePriceCta">
                        {amount !== 0 && amount !== undefined &&
                            <div className="farePrice">
                                {t("Added in the fare:")} <strong>{ `${window.selectedDollarValue} `} {Number(amount).toFixed(2)}</strong>
                            </div>
                        }
                        <div className="seatUser-cta">
                            {props.index === props.flightCount - 1 ?
                                <button onClick={addSeat} >{t("Complete Selection")}</button> :
                                <button onClick={nextFlight} >{t("Next Flight")}</button>
                            }



                        </div>
                    </div>
                </div>

            <Dialog
                open={props.open}
                keepMounted
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className="bd-pi-modal"
            >
                <div className="bd-pi-modal-body">
                    <h4 className="bd-pi-modal-title bd-seat-msg">
                      {t("For seats to be confirmed")} <strong>{t("all")}</strong> {t("the travelers.")}                   
                    </h4>
                    <div className="bd-pi-modal-btn">
                        <button className="btn-yes" onClick={()=>props.handleCloseOnYes(true)}>{t("Continue Adding Seats")}</button>
                        <button className="btn-no-seat" onClick={()=>props.handleClose(false)}>{t("Remove Selected Seats")}</button>
                    </div>
                    
                </div>
            </Dialog>

        </div>
        </>
    );
}

export default SeatUserCnx;