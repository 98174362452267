export const shortFormFareType = (data) => {
    if (data === "Published") {
      return "PUB"
    } 
    else if(data === "Negotiated") {
       return "NET"
    }
    else {
      return data
    }
  };