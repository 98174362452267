import { env } from '../../../src/appConfig'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setSeatMapDetail, setseatMapLoading ,setSeatMapData} from '../../appStore/actionCreators/itinenary'
import getApiResponse from "../../services/getApiResponse";
toast.configure()


export const getSeatMapDataAfterPnr = (requestPayload,id,number) => {

    return async (dispatch, getState) => {
        dispatch(setseatMapLoading(false));
        let group = [];
        const seatMapArray = getState().itinenaries.seatMap;
        seatMapArray && group.push(...seatMapArray);
        const corrId = getState().searchForm.uuid;
        const flightType = getState().searchForm.flightType;
        const isInternalUserAutheticated = getState().internalUser.isInternalUserAutheticated;
        const bookingChannel = isInternalUserAutheticated ? "internal" : "external";
        let key = id+`-`+number       

        // Axios payload
        const config = {
            method: "POST",
            url: env.REACT_APP_API_BASE_URL + `/seatMapping/getSeatMap`,
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",

                correlationId: corrId,
                flightType:flightType,
                bookingChannel:bookingChannel
            },
            data: requestPayload
                
        }

        const seatMapData = await getApiResponse(config);

        if (seatMapData.results && seatMapData.results.error) {
            dispatch(setSeatMapDetail(seatMapData.results.error));
            dispatch(setseatMapLoading(true));
                const result = {
                    [key] : seatMapData.results.error
                }
            group.push(result);
            dispatch(setSeatMapData(group));
        } else 
        if (seatMapData) {
            dispatch(setSeatMapDetail(seatMapData.results?.cabins ?seatMapData.results:[]));
            dispatch(setseatMapLoading(true));
            const result = {
                [key] : (seatMapData.results &&seatMapData.results.cabins) ?seatMapData.results:[]
            }
           group.push(result);
            dispatch(setSeatMapData(group));
        }
        return seatMapData

    }
}