import React from "react";
import { Route, Redirect } from "react-router-dom/cjs/react-router-dom";

export const ProtectedRoutes = ({auth, component: Component, ...rest}) => {

    return(
        <Route exact {...rest} render={(props) => {
            return auth ? <Component/>  : <Redirect to={{path:'/'}} />
        }} />
    )
}