import React, { useState, useEffect } from "react";

import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { setEditSeat, setEditSeatRecord, setSeat } from "../../appStore/actionCreators/itinenary";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from "@material-ui/core/Dialog";
import { splitFromCapital } from "../../utils/utility/commenFunctions"
import ToastMessagesDarks from '../../utils/utility/ToastMessagesDark'
import { msg } from '../../utils/utility/MessagesContainer'
import { opratingCarrier } from "../../utils/constant";
import closet from "../../Assets/closet.svg";
import galley from "../../Assets/GALLEY.svg";
import lavatory from "../../Assets/LAVATORY.svg";
import dollar from "../../Assets/dollar.svg";
import { useTranslation } from "react-i18next";
toast.configure()

function SelectSeatMapNew(props) {
    const flightDetail = useSelector((state) => state.flightBooking);
    const platingCarrier = flightDetail && flightDetail.bookingDetails &&
        flightDetail.bookingDetails.itineraryFare &&
        flightDetail.bookingDetails.itineraryFare.platingCarrierDetails &&
        flightDetail.bookingDetails.itineraryFare.platingCarrierDetails.carrier

    const bookingStatus = flightDetail && flightDetail.bookingStatus && flightDetail.bookingStatus.code
    const dispatch = useDispatch();
    const [seatMap, setSeatMap] = useState([])
    const [colsArray, setColsArr] = useState([])
    const [colsGroup, setColsGroup] = useState([])
    const [seatStatus, setSeatStatus] = useState({})
    const [flightId, setFlightId] = useState("")
    const [prePaidSeatFlag, setPrePaidSeatFlag] = useState(false)
    const seatMapArr = useSelector((state) => state.itinenaries.singleSeatMap);
    const flightIds = useSelector((state) => state.itinenaries.flightData);
    const isLoder = useSelector((state) => state.itinenaries.seatLoading)
    const seatArr = useSelector((state) => state.itinenaries.seat);
    const editSeat = useSelector((state) => state.itinenaries.editSeat);
    const editSeatRecord = useSelector((state) => state.itinenaries.editSeatRecord);

    const [seatMapError, setSeatMapError] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const [seatMapErrorMessage, setSeatMapErrorMessage] = useState("");
    const [paidSeatFlagCheck, setPaidSeatFlagCheck] = useState(false)
    const [totalSeatAmount, setSeatAmount] = useState(0)

    const carrierPrePaidSeatData = useSelector((state) => state.itinenaries.carrierPaidSeatData);
    const operatingCarrierData = useSelector((state) => state.itinenaries.operatingCarrierData);
    const marketingCarrierData = useSelector((state) => state.itinenaries.marketingCarrierData);

    let prePaidSeatCarrierFlag = carrierPrePaidSeatData && carrierPrePaidSeatData.paidSeatsAllowed
    let prePaidOperatingCarrierFlag = operatingCarrierData && operatingCarrierData.paidSeatsAllowed
    let prePaidMarketingCarrierFlag = marketingCarrierData && marketingCarrierData.paidSeatsAllowed
    const handleErrorClick = (message) => {
        const msg = message ? message : "Blank response from Seat Map API"
        setShowDialog(true)
        setDialogMessage(msg)
    }

    const handleOk = () => {
        setShowDialog(false);
    }

    const handleClose = () => {
        setShowDialog(false);
    }

    useEffect(() => {
        props.seatSelected(seatStatus)
        let editSeatRecordArr = editSeatRecord && [...editSeatRecord]
        //    handle to save edit eat details
        if (seatStatus.status === "chnaged seat") {
            editSeatRecordArr.push(seatStatus.updatedSeat)
            dispatch(setEditSeatRecord(editSeatRecordArr))

        }
        //   handle to save edit eat details end


    }, [seatStatus, props.passengerId])

    useEffect(() => {
        if (flightIds && flightIds.flightNumber) {
            if (flightIds.id !== flightId) {
                setFlightId(flightIds.id)
                setPaidSeatFlagCheck(false)
                let lastArr = seatArr
                let newArr = lastArr && lastArr.filter(obj => obj.flightNumber === flightIds.flightNumber)

                if (newArr && newArr.length > 0)
                    newArr.map((newObj) => {
                        if (document.getElementById(newObj.seat)) {
                            if (!document.getElementById(newObj.seat).checked) {
                                document.getElementById(newObj.seat).checked = true
                            }

                        }
                    })

            }


        }
        let index = ""
        let prevArr = seatArr
        if (seatArr && seatArr.length > 0) {
            seatArr.map((obj, i) => {
                if (obj.passengerId === flightIds && flightIds.passengerId && obj.flightNumber === flightIds && flightIds.flightNumber) {
                    index = i
                }
            })
        }


    }, [flightIds])

    useEffect(() => {

        getData()

    }, [seatMapArr])


    const getData = async () => {

        if (seatMapArr) {
            let seatList = (seatMapArr && seatMapArr.cabins) ? seatMapArr.cabins : []
            setSeatMap(seatList)
            let columns = seatList && seatList[0] && seatList[0].columns;

            let colsArray = [];

            let columngroup = [];
            let columnsCharacter = [];
            let nameOfCharecterPostion;
            if (columns) {


                for (let i = 0; i < columns.length; i++) {
                    if (columns && columns[i]) {
                        let colObj = {
                            columns: columns[i]
                        }
                        columnsCharacter.push(colObj);
                        if (columns[i].type == "Aisle" && columns[i + 1] && columns[i + 1].type == "Aisle") {

                            let obj = {
                                columns: columnsCharacter
                            }
                            columngroup.push(obj)
                            columnsCharacter = []

                        } else {
                            if (columns.length - 1 === i) {
                                let obj = {
                                    columns: columnsCharacter
                                }
                                columngroup.push(obj)
                                columnsCharacter = []

                            }
                        }



                    }
                }

            }

            setColsGroup(columngroup)

            if (columns) {
                for (let i = 0; i < columns.length; i++) {
                    if (columns && columns[i]) {
                        colsArray.push(columns[i].character);
                        if (columns[i].type === "Aisle" && columns[i + 1] && columns[i + 1].type === "Aisle") {
                            colsArray.push(" ");
                        }
                    }
                }
            }
            setColsArr(colsArray)
            if (seatList.length === 0) {
                setSeatMapError(true)
                setSeatMapErrorMessage(seatMapArr && seatMapArr.message)
            } else setSeatMapError(false)
        } else {
            setSeatMapError(true)
            setSeatMapErrorMessage(seatMapArr && seatMapArr.message)
        }
    }


    useEffect(() => {
        let totalAmount = 0
        if (seatArr && seatArr.length > 0) {
            seatArr.map(obj => {
                let amount = obj.amount ? obj.amount : 0
                totalAmount = Number(totalAmount) + Number(amount)
            })
        }
        setSeatAmount(totalAmount)
    }, [])

    const getSeatMapValue = (seatMapVal) => {
        let clsNameActive;
        // FREE
         if(seatMapVal?.isAvailable === true && seatMapVal?.chargeable === "Free"){
           clsNameActive = "free" 
         }
       // PAID
         if(seatMapVal?.isAvailable === true && seatMapVal?.chargeable === "Paid"){
           clsNameActive = "paid" ;
         }
       // Paid Contact Carrier  
         if(seatMapVal?.isAvailable === true && seatMapVal?.chargeable === "PaidContactCarrier"){
           clsNameActive = "PaidContactCarrier" ;
         }
       // Free Preferred 
       if(seatMapVal?.isAvailable === true && seatMapVal?.chargeable === "Free" && seatMapVal?.isPreferred === true){
           clsNameActive =  "preferred free" ;
       }  
       // PAID PREFERRED
       if(seatMapVal?.isAvailable === true && seatMapVal?.chargeable === "Paid" && seatMapVal?.isPreferred === true){
           clsNameActive = "preferred paid" ;
       }
      
       // Unavailable
       if(seatMapVal?.isAvailable === false){
           clsNameActive = "not-available carrier-prePaid-SeatFlag" ;
       }
       // Not a Seat 
       if(seatMapVal?.isAvailable === false && seatMapVal && seatMapVal.locations && seatMapVal.locations.includes("NoSeat")){
           clsNameActive = "occupied carrier-prePaid-SeatFlag" ;
       }
          
       if(seatMapVal && (seatMapVal?.limitations?.includes("NoSeatLavatory") || seatMapVal?.facilities?.includes("NoSeatLavatory") || seatMapVal?.locations?.includes("Lavatory"))){
           clsNameActive = "Lavatory" ;
       }
       if(seatMapVal && (seatMapVal?.limitations?.includes("NoSeatGalley") || seatMapVal?.facilities?.includes("NoSeatGalley") || seatMapVal?.locations?.includes("Galley"))){
           clsNameActive = "Galley" ;
       }
       if(seatMapVal && (seatMapVal?.limitations?.includes("NoSeatCloset") || seatMapVal?.facilities?.includes("NoSeatCloset") || seatMapVal?.locations?.includes("Closet"))){
           clsNameActive = "Closet" ;
       }
       
       return clsNameActive
   
    }

    const selectSeat = (e, seat, amount, currency, clsName) => {
        // FREE SEAT VALUE SET AS 0
        amount = amount == undefined ? 0 :amount;
        

        // CONDITION ADDED FOR NOT-AVAILABLE AND OCCUPIED SEAT 
        if (clsName === "not-available carrier-prePaid-SeatFlag" || clsName === "occupied carrier-prePaid-SeatFlag" || clsName === undefined || clsName === "PaidContactCarrier") return

        // CONDITION ADDED FOR TICKETING FAILED AND NON-TICKETED
        if (totalSeatAmount === 0 && (bookingStatus === "PNP" || bookingStatus === "TF") && clsName === "paid") {
            setPrePaidSeatFlag(true)
            ToastMessagesDarks(t('111'));
            return false
        }

        if (props.class === "onBookingPage") {
            if ((bookingStatus == "") && (clsName === "paid" || clsName === "preferred paid")) {
                setPrePaidSeatFlag(true)
                return ToastMessagesDarks(t('110'));
            } else {
                setPrePaidSeatFlag(false)
            }
        } else {
            if ((bookingStatus === "PNP" || bookingStatus === "TF") && amount > 0 && totalSeatAmount === 0) {
                // setPrePaidSeatFlag(true)
                // return ToastMessagesDarks(t("111"));
            } else {
                setPrePaidSeatFlag(false)
            }
        }


        if (clsName === "Lavatory" || clsName === "Galley" || clsName === "Closet" || clsName === "occupied carrier-prePaid-SeatFlag") return
        if (((!prePaidSeatCarrierFlag || !prePaidOperatingCarrierFlag || !prePaidMarketingCarrierFlag) && clsName === "paid carrier-prePaid-SeatFlag")) return
        if ((!prePaidSeatCarrierFlag || !prePaidOperatingCarrierFlag || !prePaidMarketingCarrierFlag) && clsName === "preferred paid carrier-prePaid-SeatFlag") return
        if (totalSeatAmount > 0 && (bookingStatus === "PT" || bookingStatus === "TF" || bookingStatus === "PNP") && (clsName === "paid carrier-prePaid-SeatFlag" || clsName === "preferred paid carrier-prePaid-SeatFlag")) return



        let id = seat

        if (e.target.checked) {

            let arr = seatArr
            let editseatArr = editSeat



            let filteredArr = arr && arr.filter(obj => (obj.passengerId !== undefined && obj.passengerId == flightIds.passengerId && obj.flightNumber == flightIds.flightNumber))
            if (filteredArr && filteredArr.length === 0) {
                let passengerId = flightIds.passengerId
                let obj = { seat: seat, flightNumber: flightIds.flightNumber, id: flightIds.id, amount: amount, currency: currency }
                //arr.push(obj)
                if (passengerId) {
                    obj.passengerId = `${passengerId}`
                }
                if (props.class === "onBookingPage") {
                    if (obj.amount > 0) {
                        arr = [...arr, obj]
                    } else {
                        setPrePaidSeatFlag(false)
                        arr = [...arr, obj]
                    }
                } else {
                    arr = [...arr, obj]
                }
                dispatch(setSeat(arr))
                if (props.from !== "booking" && props.from !== "savedQuotes") {

                    editseatArr = [...editseatArr, obj]
                    dispatch(setEditSeat(arr))
                }

                setSeatStatus({ status: "added", length: arr.length, updatedSeat: obj })

            } else {

                if ((filteredArr && filteredArr[0] && filteredArr[0].passengerId) == flightIds.passengerId) {
                    let obj = filteredArr[0]
                    console.log("filteredArr", filteredArr)
                    if (obj.seat !== seat && props.from !== "booking" && props.from !== "savedQuotes") {
                        let newArr = arr && arr.filter((newObj) => obj.seat != newObj.seat)
                        obj.seat = seat
                        obj.amount = amount
                        obj.currency = currency

                        if (obj.hasOwnProperty("isEdit")) {
                            obj.isEdit = true
                        }

                        newArr = [...newArr, obj]
                        arr = newArr

                        // dispatch(setSeat(arr))
                        dispatch(setEditSeat(arr))

                        editseatArr = [...editseatArr, obj]
                        setSeatStatus({ status: "edited", length: arr.length, updatedSeat: obj })

                    } else {
                        if (document.getElementById(obj.seat)) {
                            document.getElementById(obj.seat).checked = false
                        }

                        let newArr = arr && arr.filter((newObj) => obj.seat !== newObj.seat)
                        obj.seat = seat
                        obj.amount = amount
                        obj.currency = currency

                        newArr = [...newArr, obj]
                        arr = newArr

                    }
                    setSeatStatus({ status: "chnaged seat", length: arr.length, updatedSeat: obj })

                }
            }
            document.getElementById(id).checked = true
        } else {
            if (props.from !== "booking" && props.from !== "savedQuotes") {

                let obj = { passengerId: flightIds.passengerId, seat: seat, flightNumber: flightIds.flightNumber, id: flightIds.id }

                let editData = editSeat
                dispatch(setEditSeat(seatArr))
                let updateIndex = editSeat.findIndex(obj => obj.passengerId === flightIds.passengerId && obj.flightNumber === flightIds.flightNumber)
                if (updateIndex >= 0) {
                    dispatch(setEditSeat(seatArr))
                }
                setSeatStatus({ status: "edit_delete seat", updatedSeat: obj })
                let index = 0
                seatArr.map((obj, i) => {
                    if (obj.passengerId === flightIds.passengerId && obj.flightNumber === flightIds.flightNumber && obj.seat === seat) {
                        index = i
                    }
                })

                let arr = seatArr
                let oldArr = arr.splice(index, 1)
                dispatch(setSeat(arr))
            } else {
                let index = 0
                seatArr.map((obj, i) => {
                    if (obj.passengerId === flightIds.passengerId && obj.flightNumber === flightIds.flightNumber && obj.seat === seat) {
                        index = i
                    }
                })
                let obj = { seat: seat, flightNumber: flightIds.flightNumber, id: flightIds.id }
                if (flightIds.passengerId) {
                    obj.passengerId = flightIds.passengerId
                }
                let arr = seatArr
                let oldArr = arr.splice(index, 1)
                dispatch(setSeat(arr))
                setSeatStatus({ status: "removed seat", length: oldArr.length, updatedSeat: obj })
            }

        }

    }

    const { t } = useTranslation();
    return (
        <>
            {((!prePaidSeatCarrierFlag || !prePaidOperatingCarrierFlag || !prePaidMarketingCarrierFlag) && paidSeatFlagCheck && !seatMapError && isLoder) &&
                <div className="sv-infoText">
                    <i className="fas fa-info-circle"></i>{t("Prepaid seats must be booked")}
                </div>
            }
            {bookingStatus === "" && (prePaidSeatCarrierFlag && prePaidOperatingCarrierFlag && prePaidMarketingCarrierFlag) && !seatMapError && isLoder &&
                <div className="sv-infoText">
                    <i className="fas fa-info-circle"></i> {t("Prepaid seats can be selected")}
                </div>
            }
            {(bookingStatus === "PNP" || bookingStatus === "TF") && totalSeatAmount === 0 &&
                <div className="sv-infoText">
                    <i className="fas fa-info-circle"></i> Please wait for the tickets to be issued before selecting paid seats.
                </div>
            }
            {!seatMapError &&
                <div className="seat-guid-wrapper">
                    <div className="seat-guid">
                        <div className="seat-guid-item"><span className="preferred free" style={{ backgroundColor: '#74B257' }}></span>{t("Preferred Free")}</div>
                        <div className="seat-guid-item"><span className="preferred paid" style={{ backgroundColor: '#5DACD5' }}></span>{t("Preferred Paid")}</div>
                        <div className="seat-guid-item"><span className="free"></span>{t("Free")}</div>
                        <div className="seat-guid-item"><span className="paid"></span>{t("PaidSeat")}</div>
                        <div className="seat-guid-item"><span className="occupied"></span>{t("Not a Seat")}</div>
                        <div className="seat-guid-item"><span className="not-available"></span>{t("Not Available")}</div>
                        <div className="seat-guid-item"><img className="other-seat-legends" src={closet} alt="closet" />{t("Closet")}</div>
                        <div className="seat-guid-item"><img className="other-seat-legends" style={{ height: "24px", width: "24px" }} src={galley} alt="closet" />{t("Galley")}</div>
                        <div className="seat-guid-item"><img className="other-seat-legends" style={{ height: "24px", width: "24px" }} src={lavatory} alt="closet" />{t("Lavatory")}</div>
                        <div className="seat-guid-item"><img className="other-seat-legends" src={dollar} alt="closet" /> {t("Contact Carrier")}</div>
                        <div className="seat-guid-item"><span className="exit"><i className="fas fa-sign-out-alt"></i></span>{t("Exit")}</div>
                    </div>


                </div>
            }
            {isLoder ?

                seatMapError ?

                    <div className='seat-error-msg-wrapper'>
                        <div className="seat-error-status">
                            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                            {/* {seatMapErrorMessage} */}
                            {t("109")}
                        </div>
                    </div>

                    :
                    <>
                        <div className="seatView-char">
                            {colsArray && colsArray.map((col, i) => {
                                return (<span key={i}>{col}</span>)
                            })}

                        </div>
                        <div className="seatView-wrapper">
                            <div className="seatView-body">
                                {

                                    seatMap && seatMap.map((item, index) => {
                                        let seatStyles = [];
                                        let overwingRowsStart = item.overwingRowsRange && item.overwingRowsRange.length > 0 && item.overwingRowsRange[0];
                                        let overwingRowsEnd = item.overwingRowsRange && item.overwingRowsRange.length > 0 && item.overwingRowsRange[item.overwingRowsRange.length - 1];
                                        let overwingLength;
                                        let overwingValue;
                                        let indexValue;

                                        let overwingRowsRange = item.overwingRowsRange;
                                        let rowsRange = item.rowsRange;
                                        if (overwingRowsRange) {
                                            overwingLength = overwingRowsRange.length;
                                            overwingValue = overwingRowsRange[0];
                                        }

                                        if (rowsRange) {
                                            indexValue = rowsRange.indexOf(overwingValue);
                                        }

                                        return (<div key={index}>
                                            <div className="flyWings" style={{ height: overwingLength * 39.5 + "px", top: indexValue * 41 + "px" }}></div>
                                            <div className="seatView-row">
                                                <>
                                                    <div>
                                                        {item.rows && item.rows.map((rowNumber, index) => {


                                                            let rowsNumber = rowNumber.number
                                                            return (

                                                                <div className="seatView-col row-number">
                                                                    <span className="seatView-item">{rowsNumber}</span>
                                                                </div>


                                                            )
                                                        })}

                                                    </div>

                                                </>
                                                <span className="cabinType">{item.cabinType && t(splitFromCapital(item.cabinType))}</span>
                                                {item.rows.forEach((rowVal, index) => {

                                                    let rowNumber = rowVal.number
                                                    let rowFacilities = rowVal.RowFacilities
                                                    let seatRowNumberFilter = item.seats.filter(seatNum => seatNum.rowNumber === rowNumber && rowFacilities.length === 0)

                                                    // 1. SEAT INFO HAVING NO FACILITIES
                                                    if (seatRowNumberFilter.length > 0 && index !== rowNumber.length - 1) {
                                                        seatRowNumberFilter && seatRowNumberFilter.forEach(seatInfo => {

                                                            var keyItem = `${seatInfo.rowNumber}${seatInfo.columnCharacter}`;
                                                            let rowCharacter = item.rows.find((data) => data.number === seatInfo.rowNumber)

                                                            var itemObject = {
                                                                chargeable: seatInfo.chargeable,
                                                                isAvailable: seatInfo.isAvailable,
                                                                isPreferred: seatInfo.isPreferred,
                                                                facilities: seatInfo.facilities,
                                                                locations: seatInfo.locations,
                                                                limitations: seatInfo.limitations,
                                                                rowNumber: seatInfo.rowNumber,
                                                                columnCharacter: seatInfo.columnCharacter,
                                                                characteristics: seatInfo.characteristics[0],
                                                                amount: seatInfo?.fare?.totalAmount && seatInfo?.fare?.totalAmount?.amount,
                                                                rowCharacterstics: rowCharacter.RowFacilities && rowCharacter.RowFacilities[0],
                                                                seatAmount: seatInfo && seatInfo.fare && seatInfo.fare.totalAmount && seatInfo.fare.totalAmount.amount,
                                                                allRowCharactor: rowCharacter.characteristics,
                                                            }


                                                            let element = {
                                                                [keyItem]: itemObject
                                                            }


                                                            seatStyles.push(element);

                                                        })

                                                    } else {
                                                        // 2. SEAT INFO HAVING SEAT AND FACILITIES BOTH
                                                        let seatInfoAndFacilities = item.seats.filter(seatNum => seatNum.rowNumber === rowNumber && rowFacilities.length > 0)
                                                        if (seatInfoAndFacilities.length > 0) {
                                                            seatInfoAndFacilities && seatInfoAndFacilities.forEach(seatInfo => {
                                                                var keyItem = `${seatInfo.rowNumber}${seatInfo.columnCharacter}`;
                                                                let rowCharacter = item.rows.find((data) => data.number === seatInfo.rowNumber)

                                                                var itemObject = {
                                                                    chargeable: seatInfo.chargeable,
                                                                    isAvailable: seatInfo.isAvailable,
                                                                    isPreferred: seatInfo.isPreferred,
                                                                    facilities: seatInfo.facilities,
                                                                    locations: seatInfo.locations,
                                                                    limitations: seatInfo.limitations,
                                                                    rowNumber: seatInfo.rowNumber,
                                                                    columnCharacter: seatInfo.columnCharacter,
                                                                    amount: seatInfo?.fare?.totalAmount && seatInfo?.fare?.totalAmount?.amount,
                                                                    rowCharacterstics: rowCharacter.RowFacilities && rowCharacter.RowFacilities[0],
                                                                    seatAmount: seatInfo && seatInfo.fare && seatInfo.fare.totalAmount && seatInfo.fare.totalAmount.amount,
                                                                    allRowCharactor: rowCharacter.characteristics,
                                                                }
                                                                let element = {
                                                                    [keyItem]: itemObject
                                                                }

                                                                seatStyles.push(element);
                                                            })
                                                        }

                                                        rowFacilities && rowFacilities.forEach(facilitiesVal => {
                                                            let location = facilitiesVal.location

                                                            if (location === "Rear" || location === "front") {
                                                                let facilitiesArr = facilitiesVal.facilities
                                                                let activeRowNumber;
                                                                if (location === "Rear") {
                                                                    activeRowNumber = rowNumber + 1
                                                                }
                                                                if (location === "front") {
                                                                    activeRowNumber = rowNumber - 1
                                                                }

                                                                let seatRowNumberForRear = item.seats.filter(seatNum => seatNum.rowNumber === activeRowNumber)
                                                                let noSeatLocation = seatRowNumberForRear && seatRowNumberForRear.filter(val => val?.locations?.includes("NoSeat"))

                                                                // 3. INSERT FACILITIES IN NEXT ROW AT NO-SEAT LEGENDS  
                                                                if (noSeatLocation.length > 0) {
                                                                    noSeatLocation && noSeatLocation.map((data, noSeatIndex) => {

                                                                        if (noSeatIndex < facilitiesArr.length) {
                                                                            let columnCharacterVal = noSeatLocation[noSeatIndex].columnCharacter
                                                                            let rowNumberVal = noSeatLocation[noSeatIndex].rowNumber
                                                                            let facilitiesArrCharcter = facilitiesArr && facilitiesArr[noSeatIndex] && facilitiesArr[noSeatIndex].characteristic;
                                                                            if(facilitiesArrCharcter !== "ExitDoor"){
                                                                            var keyItem = `${rowNumberVal}${columnCharacterVal}`;
                                                                            var itemObject = {
                                                                                locations: [facilitiesArrCharcter],
                                                                                rowNumber: rowNumberVal,
                                                                                columnCharacter: columnCharacterVal,
                                                                                rowCharacterstics: facilitiesArrCharcter,
                                                                            }
                                                                            let element = {
                                                                                [keyItem]: itemObject
                                                                            }

                                                                            seatStyles.push(element);
                                                                        }
                                                                        }

                                                                    })

                                                                } else {

                                                                    // 4. INSERT FACILITIES AT BLANK ROW (NO SEAT INFO)


                                                                    let leftLocationArray = colsGroup && colsGroup[0]
                                                                    let rightLocationArray = colsGroup && colsGroup[colsGroup.length - 1]
                                                                    let centerLocationArray = colsGroup.length === 3 && colsGroup && colsGroup[1]

                                                                    let leftLocation = leftLocationArray && Object?.values(leftLocationArray)[0]
                                                                    let rightLocation = rightLocationArray && Object?.values(rightLocationArray)[0]
                                                                    let centerLocation = centerLocationArray && Object?.values(centerLocationArray)[0]
                                                                    let rowNumberFilter = rowFacilities

                                                                    let columnGroupFlag = colsGroup && colsGroup.length % 2 === 0 ? "even" : "odd";
                                                                    let leftColumnGrpFlag = leftLocation && leftLocation.length % 2 === 0 ? "even" : "odd";
                                                                    let rightColumnGrpFlag = rightLocation  && rightLocation.length % 2 === 0 ? "even" : "odd";

                                                                    let activeRowValNumber;
                                                                    if (location === "Rear") {
                                                                        activeRowValNumber = rowVal.number + 1
                                                                    }
                                                                    if (location === "front") {
                                                                        activeRowValNumber = rowVal.number - 1
                                                                    }

                                                                    let seatInfoAndFacilities = item.seats.filter(seatNum => seatNum.rowNumber === activeRowValNumber)
                                                                    if (seatInfoAndFacilities.length === 0)
                                                                        rowNumberFilter && rowNumberFilter.forEach(rowData => {

                                                                            let facilities = rowData.facilities
                                                                            let number = activeRowValNumber
                                                                            let centerCount = 0

                                                                            facilities && facilities.map((data, index) => {

                                                                                let characteristic = data.characteristic
                                                                                let location = data.location

                                                                                let centerLocationIndex = location === "Center" && centerLocation && Math.round((centerLocation.length) / 2) - 1 + centerCount;
                                                                                centerCount = location === "Center" ? centerCount + 1 : centerCount;
                                                                                let centerLocationCharecter = location === "Center" && centerLocation &&
                                                                                    Object?.values(centerLocation) &&
                                                                                    Object?.values(centerLocation)[centerLocationIndex] &&
                                                                                    Object?.values(Object?.values(centerLocation)[centerLocationIndex])[0]?.character;

                                                                                if(columnGroupFlag === "even" && location === "Center"){
                                                                                    let centerOfEvenGrp = Math.round(item.columns.length / 2) - 1;
                                                                                    centerLocationCharecter = item.columns[centerOfEvenGrp].character;                  
                                                                                }

                                                                                let leftColumnLocationCharecter = leftLocation &&
                                                                                    Object?.values(leftLocation) &&
                                                                                    Object?.values(leftLocation)[0] &&
                                                                                    Object?.values(Object?.values(leftLocation)[0])[0]?.character;

                                                                                let centerLeftLocationIndex = location === "LeftCenter" && leftLocation && Math.round((leftLocation.length) / 2) - 1;

                                                                                let centerLeftLocationCharecter = location === "LeftCenter" &&
                                                                                    leftLocation && Object?.values(leftLocation) && Object?.values(leftLocation)[centerLeftLocationIndex]
                                                                                    && Object?.values(Object?.values(leftLocation)[centerLeftLocationIndex])[0].character;

                                                                                if(leftColumnGrpFlag === "even" && location === "LeftCenter"){
                                                                                    centerLeftLocationIndex = Math.round(leftLocation.length % 2) + 1 ;
                                                                                    centerLeftLocationCharecter = leftLocation && Object?.values(leftLocation) && Object?.values(leftLocation)[centerLeftLocationIndex] && Object?.values(Object?.values(leftLocation)[centerLeftLocationIndex])[0].character;                   
                                                                                } 

                                                                                let rightColumnLocationCharecter = rightLocation &&
                                                                                    Object?.values(rightLocation) &&
                                                                                    Object?.values(rightLocation)[rightLocation.length - 1] &&
                                                                                    Object?.values(Object?.values(rightLocation)[rightLocation.length - 1])[0].character;

                                                                                let centerRightLocationIndex = location === "RightCenter" && rightLocation && Math.round((rightLocation.length) / 2) - 1;

                                                                                let centerRighLocationCharecter = location === "RightCenter" && rightLocation && Object?.values(rightLocation) &&
                                                                                    Object?.values(rightLocation)[centerRightLocationIndex] &&
                                                                                    Object?.values(Object?.values(rightLocation)[centerRightLocationIndex])[0].character;

                                                                                if(rightColumnGrpFlag === "even" && location === "RightCenter"){
                                                                                    centerRightLocationIndex  = Math.round(rightLocation.length / 2) - 1 ;
                                                                                    centerRighLocationCharecter = rightLocation && Object?.values(rightLocation) && Object?.values(rightLocation)[centerRightLocationIndex] && Object?.values(Object?.values(rightLocation)[centerRightLocationIndex])[0].character;                   
                                                                                }

                                                                                let keyItem;
                                                                                let itemObject;
                                                                                if (location === "Center") {
                                                                                    keyItem = `${number}${centerLocationCharecter}`;
                                                                                    itemObject = {

                                                                                        locations: [characteristic],
                                                                                        rowNumber: number,
                                                                                        columnCharacter: centerLocationCharecter,

                                                                                    }

                                                                                }
                                                                                if (location === "Left") {
                                                                                    keyItem = `${number}${leftColumnLocationCharecter}`;
                                                                                    itemObject = {

                                                                                        locations: [characteristic],
                                                                                        rowNumber: number,
                                                                                        columnCharacter: leftColumnLocationCharecter,
                                                                                        rowCharacterstics: characteristic,

                                                                                    }

                                                                                }
                                                                                if (location === "Right") {
                                                                                    keyItem = `${number}${rightColumnLocationCharecter}`;
                                                                                    itemObject = {

                                                                                        locations: [characteristic],
                                                                                        rowNumber: number,
                                                                                        columnCharacter: rightColumnLocationCharecter,
                                                                                        rowCharacterstics: characteristic,

                                                                                    }

                                                                                }
                                                                                if (location === "LeftCenter") {
                                                                                    keyItem = `${number}${centerLeftLocationCharecter}`;
                                                                                    itemObject = {

                                                                                        locations: [characteristic],
                                                                                        rowNumber: number,
                                                                                        columnCharacter: centerLeftLocationCharecter,
                                                                                        rowCharacterstics: characteristic,

                                                                                    }

                                                                                }
                                                                                if (location === "RightCenter") {
                                                                                    keyItem = `${number}${centerRighLocationCharecter}`;
                                                                                    itemObject = {

                                                                                        locations: [characteristic],
                                                                                        rowNumber: number,
                                                                                        columnCharacter: centerRighLocationCharecter,
                                                                                        rowCharacterstics: characteristic,

                                                                                    }

                                                                                }

                                                                                let element = {
                                                                                    [keyItem]: itemObject
                                                                                }




                                                                                seatStyles.push(element);


                                                                            })


                                                                        })



                                                                }

                                                            }

                                                        })

                                                        


                                                    }


                                                })}
                                                {/* {
                                                    item.seats && item.seats.map(seatInfo => {

                                                        var keyItem = `${seatInfo.rowNumber}${seatInfo.columnCharacter}`;
                                                        let rowCharacter = item.rows.find((data) => data.number === seatInfo.rowNumber)

                                                        var itemObject = {
                                                            chargeable: seatInfo.chargeable,
                                                            isAvailable: seatInfo.isAvailable,
                                                            isPreferred: seatInfo.isPreferred,
                                                            facilities: seatInfo.facilities,
                                                            locations: seatInfo.locations,
                                                            limitations: seatInfo.limitations,
                                                            columnCharacter: seatInfo.columnCharacter,
                                                            preferred: seatInfo.preferred,
                                                            rowNumber: seatInfo.rowNumber,
                                                            characteristics: seatInfo.characteristics[0],
                                                            rowCharacterstics: rowCharacter.characteristics && rowCharacter.characteristics[0],
                                                            seatAmount: seatInfo && seatInfo.fare && seatInfo.fare.totalAmount && seatInfo.fare.totalAmount.amount,
                                                            currency: seatInfo?.fare?.totalAmount?.currency

                                                        }


                                                        let element = {
                                                            [keyItem]: itemObject
                                                        }
                                                        seatStyles.push(element);
                                                    })

                                                } */}
                                                {
                                                    colsArray.map((col, index) => {
                                                        return (

                                                            <div className={`seatView-col`} key={index}>
                                                                {
                                                                    col !== " "
                                                                        ?
                                                                        item.rows && item.rows.map(rowItem => {
                                                                            let exitDoorFlag = rowItem.isExitRow
                                                                            let rowNumber = rowItem.number
                                                                            var key = `${rowNumber}${col}`;
                                                                            let style;

                                                                            {

                                                                                for (let i = 0; i < seatStyles.length; i++) {

                                                                                    style = seatStyles[i][key];

                                                                                    if (style) {

                                                                                        break;
                                                                                    }

                                                                                }
                                                                            }

                                                                            let charactor = style && style.rowCharacterstics


                                                                            let rowsArray = item.rows

                                                                            let rowNumberFilter = rowsArray && rowsArray.filter(num => num.number === rowNumber && num.RowFacilities.length > 0)


                                                                            let leftLocationArray = colsGroup && colsGroup[0]
                                                                            let rightLocationArray = colsGroup && colsGroup[colsGroup.length - 1]
                                                                            let centerLocationArray = colsGroup.length === 3 && colsGroup && colsGroup[1]

                                                                            let leftLocation = Object.values(leftLocationArray)[0]
                                                                            let rightLocation = Object.values(rightLocationArray)[0]
                                                                            let centerLocation = Object.values(centerLocationArray)[0]

                                                                            let leftColumnGrpFlag = leftLocation && leftLocation.length % 2 === 0 ? "even" : "odd";
                                                                            let rightColumnGrpFlag = rightLocation  && rightLocation.length % 2 === 0 ? "even" : "odd";

                                                                            let clsName;

                                                                            let seatActiveClsName = getSeatMapValue(style)
                                                                            clsName = seatActiveClsName


                                                                            let facilitiesValue = style && style.facilities
                                                                            let limitationsValue = style && style.limitations
                                                                            let preferredFlag;
                                                                            if (clsName === "occupied carrier-prePaid-SeatFlag" && style.preferred === "true") {
                                                                                preferredFlag = true;
                                                                            }

                                                                            if ((!prePaidSeatCarrierFlag || !prePaidOperatingCarrierFlag || !prePaidMarketingCarrierFlag) && clsName === "paid") {
                                                                                clsName = 'paid carrier-prePaid-SeatFlag'
                                                                                if (!paidSeatFlagCheck) {
                                                                                    setPaidSeatFlagCheck(true)
                                                                                }
                                                                            }
                                                                            if ((!prePaidSeatCarrierFlag || !prePaidOperatingCarrierFlag || !prePaidMarketingCarrierFlag) && clsName === "preferred paid") {
                                                                                clsName = 'preferred paid carrier-prePaid-SeatFlag'
                                                                                if (!paidSeatFlagCheck) {
                                                                                    setPaidSeatFlagCheck(true)
                                                                                }
                                                                            }

                                                                            if (totalSeatAmount > 0 && (bookingStatus === "PT" || bookingStatus === "TF" || bookingStatus === "PNP") && (clsName === "paid")) {
                                                                                clsName = 'paid carrier-prePaid-SeatFlag'
                                                                            }
                                                                            if (totalSeatAmount > 0 && (bookingStatus === "PT" || bookingStatus === "TF" || bookingStatus === "PNP") && (clsName === "preferred paid")) {
                                                                                clsName = 'preferred paid carrier-prePaid-SeatFlag'
                                                                            }
                                                                            if ((bookingStatus === "PNP" || bookingStatus === "TF") && totalSeatAmount === 0) {
                                                                                clsName = 'paid carrier-prePaid-SeatFlag'

                                                                            }

                                                                            let clsNameForNotSeat;
                                                                            let popUpmsg;
                                                                            if (clsName === "occupied carrier-prePaid-SeatFlag") {
                                                                                clsNameForNotSeat = "occupied carrier-prePaid-SeatFlag"
                                                                                popUpmsg = "Not a seat"
                                                                            }
                                                                            if (clsName === "Lavatory") {
                                                                                clsNameForNotSeat = "Lavatory"
                                                                                popUpmsg = "Lavatory"
                                                                            }
                                                                            if (clsName === "Galley") {
                                                                                clsNameForNotSeat = "Galley"
                                                                                popUpmsg = "Galley"
                                                                            }
                                                                            if (clsName === "Closet") {
                                                                                clsNameForNotSeat = "Closet"
                                                                                popUpmsg = "Closet"
                                                                            }



                                                                            let currency = style?.currency;
                                                                            let seatAmount = style?.seatAmount;
                                                                            let lastRowNumberFaciliesFlag = item?.rows[item?.rows.length - 1].RowFacilities.length > 0 ? true : false;

                                                                            let lastRowNumber = item?.rows[item?.rows.length - 1].number;
                                                                            let filterPaxTypePass = props.passengerData && props.passengerData.filter(val => val.paxType !== "InfantInLap")
                                                                           



                                                                            let newArr = seatArr && seatArr.filter(obj => obj.flightNumber === flightIds.flightNumber)

                                                                            return (
                                                                                <>
                                                                                 {exitDoorFlag && index === 0 &&
                                                                                    <div className={'seatView-exit'}>
                                                                                        <div className="exit-first">
                                                                                            <i className="fas fa-sign-out-alt"></i>
                                                                                            </div>   
                                                                                    </div>
                                                                                  }
                                                                                    {exitDoorFlag && index === colsArray.length - 1 &&
                                                                                        <div className={'seatView-exit'}>
                                                                                            <div className="exit-last">
                                                                                                <i className="fas fa-sign-out-alt"></i>
                                                                                                </div>   
                                                                                        </div>
                                                                                    }

                                                                                    <OverlayTrigger trigger='hover' rootClose placement="top" overlay={
                                                                                        <Popover className={clsName !== undefined ? "seatView-popover" : "seatView-popover-noSeat"}>
                                                                                            {clsName === clsNameForNotSeat ? <div className="seatView-popover-row seatView-popover-amount">{popUpmsg}</div> :
                                                                                                <>
                                                                                                    <div className="seatView-popover-row seatView-popover-amount">{t("Seat")} {key}
                                                                                                        {preferredFlag &&
                                                                                                            <span>{t("Preferred")}</span>
                                                                                                        }
                                                                                                        {clsName !== "free" && seatAmount !== undefined &&
                                                                                                            <span>{ `${window.selectedDollarValue} `} {seatAmount > 0 ? seatAmount : ""}</span>
                                                                                                        }
                                                                                                    </div>

                                                                                                    <hr />
                                                                                                    <div className="seatView-popover-body">

                                                                                                        <div className="svpb-head">{item.cabinType && t(splitFromCapital(item.cabinType))} {t("Class Seat")} </div>

                                                                                                        {facilitiesValue && facilitiesValue.length > 0 &&
                                                                                                            <>
                                                                                                                <div className='svpb-head'>{t("Facilities")}</div>
                                                                                                                <ul>
                                                                                                                    {facilitiesValue && facilitiesValue.map(val => {
                                                                                                                        return (
                                                                                                                            <li>{val}</li>
                                                                                                                        )
                                                                                                                    })}
                                                                                                                </ul>
                                                                                                            </>
                                                                                                        }
                                                                                                        {limitationsValue && limitationsValue.length > 0 &&
                                                                                                            <div className='svpb-head'>
                                                                                                                {t("Limitation")}
                                                                                                                <div>
                                                                                                                    <ul>
                                                                                                                        {limitationsValue && limitationsValue.map(val => {
                                                                                                                            return (

                                                                                                                                <li>{val}</li>

                                                                                                                            )
                                                                                                                        })}
                                                                                                                    </ul>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }

                                                                                                    </div>
                                                                                                </>
                                                                                            }
                                                                                        </Popover>
                                                                                    }
                                                                                    >
                                                                                        <Button className={`bf-popover-btn ${clsName === "occupied" && ((newArr && newArr.length > 0) && (newArr.filter((obj) => obj && obj.seat === key).length > 0)) ? 'selectOccupied' : 'disableOccupied'}`}>

                                                                                            <input type="checkbox"
                                                                                                name="seat-radio"
                                                                                                className={clsName}
                                                                                                onChange={(e) => selectSeat(e, key, seatAmount, currency, clsName)}
                                                                                                id={key}                                                                                                
                                                                                                disabled={newArr && newArr.length > 0 && newArr.filter((obj) => obj.seat === key && obj.passengerId !== flightIds.passengerId).length > 0}
                                                                                                checked={!prePaidSeatFlag && newArr && newArr.length > 0 && newArr.filter((obj) => obj.seat === key).length > 0}
                                                                                            />
                                                                                            <span className={overwingRowsStart === rowNumber ? `seatView-item  flyingStart` : overwingRowsEnd === rowNumber ? `seatView-item flyingEnd` : clsName === undefined ? "seatView-item undefined-seat" : `seatView-item `}>
                                                                                            {clsName === "Closet" && <img src={closet} alt="closet" />}
                                                                                            {clsName === "Galley" && <img style={{ height: "24px", width: "24px" }} src={galley} alt="closet" />}
                                                                                            {clsName === "Lavatory" && <img style={{ height: "24px", width: "24px" }} src={lavatory} alt="closet" />}
                                                                                            {clsName === "PaidContactCarrier" && <img src={dollar} alt="closet" />}
                                                                                            </span>

                                                                                        </Button>
                                                                                    </OverlayTrigger>

                                                                                 

                                                                                    {/* LAST ROW FACILITIES LOCATION */}
                                                                                    {lastRowNumber === style?.rowNumber && lastRowNumberFaciliesFlag &&
                                                                                        <span className='seatView-item'>
                                                                                            {rowNumberFilter && rowNumberFilter[0] && rowNumberFilter[0].RowFacilities?.map(rowData => {
                                                                                                let facilities = rowData.facilities
                                                                                                let number = rowNumberFilter && rowNumberFilter[0].number


                                                                                                return (
                                                                                                    <>
                                                                                                        {facilities && facilities.map((data, index) => {

                                                                                                            let characteristic = data.characteristic
                                                                                                            let location = data.location
                                                                                                            let columnGroupFlag =colsGroup && colsGroup.length % 2 === 0 ? "even" : "odd";


                                                                                                            let centerLocationIndex = location === "Center" && centerLocation && Math.round((centerLocation.length) / 2) - 1
                                                                                                            let centerLocationCharecter = location === "Center" && centerLocation && Object?.values(centerLocation) && Object?.values(centerLocation)[centerLocationIndex] && Object?.values(Object?.values(centerLocation)[centerLocationIndex])[0]?.character;

                                                                                                            if(columnGroupFlag === "even" && location === "Center"){
                                                                                                                let centerOfEvenGrp = Math.round(item.columns.length / 2) - 1;
                                                                                                                centerLocationCharecter = item.columns[centerOfEvenGrp].character;       
                                                                                                            }


                                                                                                            let leftColumnLocationCharecter = Object?.values(Object?.values(leftLocation)[0])[0].character;
                                                                                                            let centerLeftLocationIndex = location === "LeftCenter" && Math.round((leftLocation.length) / 2) - 1
                                                                                                            let centerLeftLocationCharecter = location === "LeftCenter" &&
                                                                                                                leftLocation && Object?.values(leftLocation) &&
                                                                                                                Object?.values(leftLocation)[centerLeftLocationIndex] &&
                                                                                                                Object?.values(Object?.values(leftLocation)[centerLeftLocationIndex])[0].character;

                                                                                                            if(leftColumnGrpFlag === "even" && location === "LeftCenter"){
                                                                                                                centerLeftLocationIndex = Math.round(leftLocation.length % 2) + 1 ;
                                                                                                                centerLeftLocationCharecter = location === "LeftCenter" && leftLocation && Object?.values(leftLocation) && Object?.values(leftLocation)[centerLeftLocationIndex] && Object?.values(Object?.values(leftLocation)[centerLeftLocationIndex])[0].character;                   
                                                                                                            }
                                                                                                            let rightColumnLocationCharecter = Object?.values(Object?.values(rightLocation)[rightLocation.length - 1])[0].character;

                                                                                                            let centerRightLocationIndex = location === "RightCenter" && Math.round((rightLocation.length) / 2) - 1
                                                                                                            let centerRighLocationCharecter = location === "RightCenter" &&
                                                                                                                rightLocation && Object?.values(rightLocation) &&
                                                                                                                Object?.values(rightLocation)[centerRightLocationIndex] &&
                                                                                                                Object?.values(Object?.values(rightLocation)[centerRightLocationIndex])[0].character;

                                                                                                            if(rightColumnGrpFlag === "even" && location === "RightCenter"){
                                                                                                                centerRightLocationIndex  = Math.round(rightLocation.length / 2) - 1 ;
                                                                                                                centerRighLocationCharecter = rightLocation && Object?.values(rightLocation) && Object?.values(rightLocation)[centerRightLocationIndex] && Object?.values(Object?.values(rightLocation)[centerRightLocationIndex])[0].character;                   
                                                                                                            }    


                                                                                                            if (location === "Center" && style.columnCharacter === centerLocationCharecter && number === rowNumber) {
                                                                                                                return (
                                                                                                                    <OverlayTrigger trigger="hover" placement="top" overlay={
                                                                                                                        <Popover className="seatView-popover">
                                                                                                                            {characteristic === "Closet" && <div className="seatView-popover-row seatView-popover-amount">{t("Closet")}</div>}
                                                                                                                            {characteristic === "Galley" && <div className="seatView-popover-row seatView-popover-amount">{t("Galley")}</div>}
                                                                                                                            {characteristic === "Lavatory" && <div className="seatView-popover-row seatView-popover-amount">{t("Lavatory")}</div>}
                                                                                                                        </Popover>
                                                                                                                    }
                                                                                                                    >
                                                                                                                        <div className='seatView-item '>
                                                                                                                            {characteristic === "Closet" && <img src={closet} alt="closet" />}
                                                                                                                            {characteristic === "Galley" && <img className="other-seat-legends1" src={galley} alt="closet" />}
                                                                                                                            {characteristic === "Lavatory" && <img className="other-seat-legends1" src={lavatory} alt="closet" />}

                                                                                                                        </div>

                                                                                                                    </OverlayTrigger>

                                                                                                                )
                                                                                                            }
                                                                                                            if (location === "Left" && style.columnCharacter === leftColumnLocationCharecter && number === rowNumber && characteristic !== "ExitDoor") {
                                                                                                                return (
                                                                                                                    <OverlayTrigger trigger="hover" placement="top" overlay={
                                                                                                                        <Popover className="seatView-popover">
                                                                                                                            {characteristic === "Closet" && <div className="seatView-popover-row seatView-popover-amount">{t("Closet")}</div>}
                                                                                                                            {characteristic === "Galley" && <div className="seatView-popover-row seatView-popover-amount">{t("Galley")}</div>}
                                                                                                                            {characteristic === "Lavatory" && <div className="seatView-popover-row seatView-popover-amount">{t("Lavatory")}</div>}
                                                                                                                            {characteristic === "ExitDoor" && <div className="seatView-popover-row seatView-popover-amount">{t("Exit Door")}</div>}
                                                                                                                        </Popover>
                                                                                                                    }
                                                                                                                    >
                                                                                                                        <div className='seatView-item'>
                                                                                                                            {characteristic === "Closet" && <img src={closet} alt="closet" />}
                                                                                                                            {characteristic === "Galley" && <img className="other-seat-legends1" src={galley} alt="closet" />}
                                                                                                                            {characteristic === "Lavatory" && <img className="other-seat-legends1" src={lavatory} alt="closet" />}
                                                                                                                            {characteristic === "ExitDoor" &&<span className="bd-exit-seat"> <i className="fas fa-sign-out-alt seat-left"></i></span> }
                                                                                                                        </div>

                                                                                                                    </OverlayTrigger>
                                                                                                                )
                                                                                                            }
                                                                                                            if (location === "Right" && style.columnCharacter === rightColumnLocationCharecter && number === rowNumber && characteristic !== "ExitDoor") {
                                                                                                                return (<OverlayTrigger trigger="hover" placement="top" overlay={
                                                                                                                    <Popover className="seatView-popover">
                                                                                                                        {characteristic === "Closet" && <div className="seatView-popover-row seatView-popover-amount">{t("Closet")}</div>}
                                                                                                                        {characteristic === "Galley" && <div className="seatView-popover-row seatView-popover-amount">{t("Galley")}</div>}
                                                                                                                        {characteristic === "Lavatory" && <div className="seatView-popover-row seatView-popover-amount">{t("Lavatory")}</div>}
                                                                                                                        {characteristic === "ExitDoor" && <div className="seatView-popover-row seatView-popover-amount">{t("Exit Door")}</div>}
                                                                                                                    </Popover>
                                                                                                                }
                                                                                                                >
                                                                                                                    <div className='seatView-item'>
                                                                                                                        {characteristic === "Closet" && <img src={closet} alt="closet" />}
                                                                                                                        {characteristic === "Galley" && <img className="other-seat-legends1" src={galley} alt="closet" />}
                                                                                                                        {characteristic === "Lavatory" && <img className="other-seat-legends1" src={lavatory} alt="closet" />}
                                                                                                                        {characteristic === "ExitDoor" &&<span className="bd-exit-seat"> <i className="fas fa-sign-out-alt"></i></span> }
                                                                                                                    </div>

                                                                                                                </OverlayTrigger>
                                                                                                                )
                                                                                                            }
                                                                                                            if (location === "LeftCenter" && style.columnCharacter === centerLeftLocationCharecter && number === rowNumber) {
                                                                                                                return (<OverlayTrigger trigger="hover" placement="top" overlay={
                                                                                                                    <Popover className="seatView-popover">
                                                                                                                        {characteristic === "Closet" && <div className="seatView-popover-row seatView-popover-amount">{t("Closet")}</div>}
                                                                                                                        {characteristic === "Galley" && <div className="seatView-popover-row seatView-popover-amount">{t("Galley")}</div>}
                                                                                                                        {characteristic === "Lavatory" && <div className="seatView-popover-row seatView-popover-amount">{t("Lavatory")}</div>}
                                                                                                                    </Popover>
                                                                                                                }
                                                                                                                >
                                                                                                                    <div className='seatView-item '>
                                                                                                                        {characteristic === "Closet" && <img src={closet} alt="closet" />}
                                                                                                                        {characteristic === "Galley" && <img className="other-seat-legends1" src={galley} alt="closet" />}
                                                                                                                        {characteristic === "Lavatory" && <img className="other-seat-legends1" src={lavatory} alt="closet" />}

                                                                                                                    </div>

                                                                                                                </OverlayTrigger>
                                                                                                                )
                                                                                                            }
                                                                                                            if (location === "RightCenter" && style.columnCharacter === centerRighLocationCharecter && number === rowNumber) {
                                                                                                                return (<OverlayTrigger trigger="hover" placement="top" overlay={
                                                                                                                    <Popover className="seatView-popover">
                                                                                                                        {characteristic === "Closet" && <div className="seatView-popover-row seatView-popover-amount">{t("Closet")}</div>}
                                                                                                                        {characteristic === "Galley" && <div className="seatView-popover-row seatView-popover-amount">{t("Galley")}</div>}
                                                                                                                        {characteristic === "Lavatory" && <div className="seatView-popover-row seatView-popover-amount">{t("Lavatory")}</div>}
                                                                                                                    </Popover>
                                                                                                                }
                                                                                                                >
                                                                                                                    <div className='seatView-item '>
                                                                                                                        {characteristic === "Closet" && <img src={closet} alt="closet" />}
                                                                                                                        {characteristic === "Galley" && <img className="other-seat-legends1" src={galley} alt="closet" />}
                                                                                                                        {characteristic === "Lavatory" && <img className="other-seat-legends1" src={lavatory} alt="closet" />}

                                                                                                                    </div>

                                                                                                                </OverlayTrigger>
                                                                                                                )
                                                                                                            }




                                                                                                        })}

                                                                                                    </>
                                                                                                )



                                                                                            })}



                                                                                        </span>

                                                                                    }

                                                                                </>)

                                                                        }) :
                                                                        item.rowsRange && item.rowsRange.map(rowNumber => {
                                                                            //     return (
                                                                            //         <div className="seatView-col row-number" key={rowNumber}>
                                                                            //             <span className="seatView-item">{rowNumber}</span>
                                                                            //         </div>

                                                                            //     )
                                                                        })
                                                                }
                                                            </div>

                                                        )
                                                    })}



                                            </div>
                                        </div>)
                                    })}



                            </div>
                        </div>
                    </>
                :
                <div className="col-md-12 text-center mt-3">
                    {/* <SpinnerLoader /> */}
                    <div className="new-loader">
                        <span style={{ "--i": "1" }} class="green"></span>
                        <span style={{ "--i": "2" }} class="green"></span>
                        <span style={{ "--i": "3" }} class="green"></span>
                        <span style={{ "--i": "4" }} class="green"></span>
                        <span style={{ "--i": "5" }} class="green"></span>
                        <span style={{ "--i": "6" }} class="yellow"></span>
                        <span style={{ "--i": "7" }} class="yellow"></span>
                        <span style={{ "--i": "8" }} class="yellow"></span>
                        <span style={{ "--i": "9" }} class="yellow"></span>
                        <span style={{ "--i": "10" }} class="yellow"></span>
                        <span style={{ "--i": "11" }} class="red"></span>
                        <span style={{ "--i": "12" }} class="red"></span>
                        <span style={{ "--i": "13" }} class="red"></span>
                        <span style={{ "--i": "14" }} class="red"></span>
                        <span style={{ "--i": "15" }} class="red"></span>
                        <span style={{ "--i": "16" }} class="blue"></span>
                        <span style={{ "--i": "17" }} class="blue"></span>
                        <span style={{ "--i": "18" }} class="blue"></span>
                        <span style={{ "--i": "19" }} class="blue"></span>
                        <span style={{ "--i": "20" }} class="blue"></span>

                        <div className="rocket"><i className="fa fa-plane" aria-hidden="true"></i></div>
                    </div>
                </div>
            }
            <Dialog
                open={showDialog}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <div className="bd-pi-contact-modal bd-alertBox">
                    {dialogMessage}
                    <div className="btn-wrapper">
                        <button className="yes" onClick={handleOk}>{t("OK")}</button>
                    </div>
                </div>
            </Dialog>


        </>
    );
}

export default SelectSeatMapNew;