import { SET_AGENT_AUTHETICATION, SET_AGENT_PROFILE, SET_ORG_ID, SET_AGENCY_ID,
         SET_AGENCY_GROUP_ID, SET_USER_ID, SET_AGENCY_LOCATION, 
         SET_AGENT_ID, SET_AGENT_NAME,SET_AGENT_TOKEN, SET_AGENT_CODE, 
         SET_AGENCY_NAME, SET_AGENT_EMAIL_ID, SET_AGENCY_DETAILS, SET_AGENT_DETAILS,
         AGENT_PROFILE_FEE,SET_INTERNAL_AGENT_ID, SET_AGENT_OLD_USER_ID, SET_INTERNAL_USER_P6 } from '../storeConstants';

export const setAgentAuthetication = (value) => {
    return {
        type: SET_AGENT_AUTHETICATION,
        payload: value
    }
}

export const setAgentProfile = (value) => {
    return {
        type: SET_AGENT_PROFILE,
        payload: value
    }
}

export const setOrgId = (value) => {
    return {
        type: SET_ORG_ID,
        payload: value
    }
}
export const setAgencyDetails = (value) => {
    return {
        type: SET_AGENCY_DETAILS,
        payload: value
    }
}
export const setAgentDetails = (value) => {
    return {
        type: SET_AGENT_DETAILS,
        payload: value
    }
}

export const setAgencyId = (value) => {
    return {
        type: SET_AGENCY_ID,
        payload: value
    }
}

export const setAgencyGroupId = (value) => {
    return {
        type: SET_AGENCY_GROUP_ID,
        payload: value
    }
}

export const setUserId = (value) => {
    return {
        type: SET_USER_ID,
        payload: value
    }
}
export const setInternalUserP6 = (value) => {
    return {
        type: SET_INTERNAL_USER_P6,
        payload: value
    }
}

export const setAgencyLocation = (value) => {
    return {
        type: SET_AGENCY_LOCATION,
        payload: value
    }
}

export const setAgentId = (value) => {
    return {
        type: SET_AGENT_ID,
        payload: value
    }
}

export const setAgentName = (value) => {
    return {
        type: SET_AGENT_NAME,
        payload: value
    }
}

export const setAgentToken = (value) => {
    return {
        type: SET_AGENT_TOKEN,
        payload: value
    }
}

export const setAgentCode = (value) => {
    return {
        type: SET_AGENT_CODE,
        payload: value
    }
}
export const setAgencyName = (value) => {
    return {
        type: SET_AGENCY_NAME,
        payload: value
    }
}
export const setAgentEmailId = (value) => {
    return {
        type: SET_AGENT_EMAIL_ID,
        payload: value
    }
}

export const setProfileFee = (value) => { 
    return {
     type:AGENT_PROFILE_FEE,
     val:value,
    };
  };



export const setIternalAgentId = (value) => {
    console.log("value",value)
    return {
        type: SET_INTERNAL_AGENT_ID,
        payload: value
    }
}

export const setAgentOldUserId = (value) => {
    return {
        type: SET_AGENT_OLD_USER_ID,
        payload: value
    }
}