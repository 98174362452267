import { setfreeTextFareRules,setstructuredFareRules } from '../../appStore/actionCreators/penaltiesTab';
import { Penalties, TripType } from '../../utils/constant';
import { env } from '../../../src/appConfig'
// Api Function for Text Fare Rules
import getApiResponse from '../../services/getApiResponse';
import { setFareRulesError, setFareRulesLoader } from '../actionCreators/searchForm';

export const getPenaltiesDataForQuote = (fareId,flowFrom) => {

    
    return async (dispatch, getState) => {
        dispatch(setFareRulesLoader(true))
        let group = [];
        let textFareRule=[];    
        const quoteTokenData = getState().savedQuotes;
        const quoteToken = quoteTokenData && quoteTokenData.repriceQuote && quoteTokenData.repriceQuote.token;
        const quoteId = getState().savedQuotes.repriceQuote.quoteId;
        const corrId = getState().searchForm.uuid;
        const tripTypeValue = getState().searchForm.tripType;
        const flightType = getState().searchForm.flightType;
        const isInternalUserAutheticated = getState().internalUser.isInternalUserAutheticated;
        const bookingChannel = isInternalUserAutheticated ? "internal" : "external";
        const penaltiesStructureData = getState().Penalaties.structuredFareRules;
        penaltiesStructureData && group.push(...penaltiesStructureData);
        const penaltiesTextFareData = getState().Penalaties.freeTextFareRules;
        penaltiesTextFareData && textFareRule.push(...penaltiesTextFareData);
        
        
      
        // Api For FareRules       
       
        
        const config = {
            method: "POST",
            url: env.REACT_APP_API_BASE_URL+`/revisedFareRules?token=${quoteToken}`,
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",               
                "Access-Control-Allow-Credentials" : true,
                correlationId: corrId,
                flightType:flightType,
                bookingChannel:bookingChannel               
            },
            data: { fareId: fareId, quoteId:quoteId}
        }
         

        let penaltiesResultFareRules = await getApiResponse(config);
        dispatch(setFareRulesLoader(false))
        
       
        
        let key = fareId
        
        if (penaltiesResultFareRules.error) {           
            const result = {
                [key]: Penalties.STR_FARE_RULES_ERROR,
            };
            textFareRule.push(result);
            dispatch(setfreeTextFareRules(textFareRule));
            dispatch(setFareRulesError(true))
        } else {
            const result = {
                [key]: penaltiesResultFareRules.fareRules,
            };
            textFareRule.push(result);
            dispatch(setfreeTextFareRules(textFareRule));
        }
       
       
    }
}