//import {getApiResponse} from "./../../utils/utility/getCommonApiResponse"
import { setPaymentRules ,setMarkupApplied, setPaymentRulesForCheque, setItenaryData, setItinaryFaresType} from "../../appStore/actionCreators/flightBooking"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { env } from '../../../src/appConfig'
import { config } from "@fortawesome/fontawesome-svg-core";
import getApiResponse from '../../services/getApiResponse'
import { t } from "i18next";

toast.configure();



export const getApplyConfiguredBookingAmount = (requestbody, from) => {
    
    return async (dispatch, getState) => {
        
        const flightType = getState().searchForm.flightType;
        const corrId = getState().searchForm.uuid;
        const isInternalUserAutheticated = getState().internalUser.isInternalUserAutheticated;
        const bookingChannel = isInternalUserAutheticated ? "internal" : "external";
        const tokenData = getState().initToken;
        const token = tokenData && tokenData.token;
        //Axios payload
       
        let config = {}
        if(from == "booking"){

            config = {
                method: "POST",
                url: env.REACT_APP_API_BASE_URL + `/configureBookingAmount/applyConfiguredBookingAmount?token=${token}`,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": true,
                    "cache-control": "no-cache",
                    correlationId: corrId,
                    flightType: flightType,
                    bookingChannel: bookingChannel
                },
                data: requestbody
            }
        }else{
            config = {
                method: "POST",
                url: env.REACT_APP_API_BASE_URL + `/configureBookingAmount/applyConfiguredBookingAmount`,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": true,
                    "cache-control": "no-cache",
                    correlationId: corrId,
                    flightType: flightType,
                    bookingChannel: bookingChannel
                },
                data: requestbody
            }
        }
        const paymetRules = await getApiResponse(config);
        if(paymetRules == "error"){
            toast.error(t("1009"), { position: toast.POSITION.TOP_CENTER, autoClose:true, progressStyle:{"transform-origin": "right"}});
        }else if(paymetRules &&paymetRules?.error){
            dispatch(setMarkupApplied(false))
            toast.error(paymetRules.error.message, { position: toast.POSITION.TOP_CENTER, autoClose: true });
            return paymetRules
        }else if (paymetRules) {
            // dispatch(setPaymentRules(paymetRules))
            // dispatch(setItenaryData(paymetRules.itineraryFare))
            // dispatch(setItinaryFaresType(paymetRules && paymetRules.itineraryFare && paymetRules.itineraryFare.type))
            let result = paymetRules
            if(result?.itineraryFare){
                dispatch(setItenaryData(result?.itineraryFare))
                dispatch(setItinaryFaresType(result?.itineraryFare?.type))
            }
            // dispatch(setMarkupApplied(true))
            let defaultTransaction = result?.defaultTransactionDetails;
            for (let index = 0; index < defaultTransaction?.length; index++) {
                defaultTransaction[index]["agencyMarkups"] = result?.agencyMarkups
                defaultTransaction[index]["itineraryFare"] = result?.itineraryFare
                if (defaultTransaction[index]?.type == "CreditCard") {
                    dispatch(setPaymentRules(defaultTransaction[index]))
                }
                else if (defaultTransaction[index]?.type == "Cheque") {
                    dispatch(setPaymentRulesForCheque(defaultTransaction[index]))
                }
                else {
                    dispatch(setPaymentRules(defaultTransaction[index]))
                }
            }
        }
        return paymetRules
        

    }
}