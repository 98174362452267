const initialState = {
  lang: { lang: "en" }
};

const Language_Reducers = (state = initialState.lang, action) => {
  switch (action.type) {
    case "CHANGE LANGUAGE":
      return {
        ...state,
        lang: action.data,
      };
    default:
      return state;
  }
};

export default Language_Reducers;
