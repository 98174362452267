import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';


function DufflePopUp(props) {
    const { open, from, data, flowFrom } = props

    const handleClose = () => {
        props.handleClose()
    }
    const { t } = useTranslation();


    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="fareType-dialog"
        >
            <div class="modal-header">
                <h5 class="modal-title" >PUB WEB Conditions</h5>
            </div>
            <div className='modal-body webFares_popup'>
                <ul className='ml-3'>
                    <li>
                        {t("Base fare and total taxes per passenger adult, child and infant is not available.  Only the total for the whole party traveling is showing.")}
                    </li>
                    <li>
                        {t("Number of open seats to book is not obtainable.")}
                    </li>
                    <li class='text-danger'>
                        {t("Passenger gender, date of birth and contact details must be accurate at time of booking as they cannot be modified afterwards through Gigi.")}
                    </li>
                    <li class='text-danger'>
                        {t("Only the same payment method for all the travelers is allowed and passenger credit card billing address is required.")}
                    </li>
                    
                    <li>
                        {t("Gigi file number is the airline record locator number.")}
                    </li>
                    <li>
                        {t("GIGI booking confirmation and electronic ticket emails are automatically sent.")}
                    </li><li>
                        {t("Invoicing is done manually and may take up to 48 hours to be sent.")}
                    </li>
                    <li className='font-weight-bold'>
                        {t("By selecting this fare, the booking will be done directly with the carrier. You will be able to access this booking by going on the carrier’s website and use the options that are provided such as:")}
                    </li>    
                        {/* <ul> */}
                            <div>
                                {t("View baggage allowances and information about the fare option  booked.")}
                            </div>
                            <div>
                                {t("Add and change some travel options.")}
                            </div>
                            <div>
                                {t("Do some special requests, add frequent traveler and passport information.")}
                            </div>
                            <div>
                                {t("View seat map, make and pay seat selection.")}
                            </div>
                            <div>
                                {t("Print and email the itinerary or electronic ticket.")}
                            </div>
                        {/* </ul> */}
                    {/* </li> */}
                </ul>
            </div>
        </Dialog>
    );
}

export default DufflePopUp;

