export const MULTICITY_ADDINPUT = "MULTICITY_ADDINPUT";
export const MULTICITY_REMOVEINPUT = "MULTICITY_REMOVEINPUT";

export const SET_MULTICITY_FROM_A = 'SET_MULTICITY_FROM_A'; 
export const SET_MULTICITY_TO_A = 'SET_MULTICITY_TO_A'; 

export const SET_MULTICITY_FROM_B = 'SET_MULTICITY_FROM_B'; 
export const SET_MULTICITY_TO_B = 'SET_MULTICITY_TO_B'; 

export const SET_MULTICITY_FROM_0 = 'SET_MULTICITY_FROM_0'; 
export const SET_MULTICITY_TO_0 = 'SET_MULTICITY_TO_0'; 

export const SET_MULTICITY_FROM_1 = 'SET_MULTICITY_FROM_1'; 
export const SET_MULTICITY_TO_1 = 'SET_MULTICITY_TO_1'; 

export const SET_MULTICITY_FROM_2 = 'SET_MULTICITY_FROM_2'; 
export const SET_MULTICITY_TO_2 = 'SET_MULTICITY_TO_2'; 

export const SET_MULTICITY_FROM_3 = 'SET_MULTICITY_FROM_3'; 
export const SET_MULTICITY_TO_3 = 'SET_MULTICITY_TO_3'; 

export const SET_MULTICITY_DATE_A = 'SET_MULTICITY_DATE_A'; 
export const SET_MULTICITY_TIME_A = 'SET_MULTICITY_TIME_A'; 

export const SET_MULTICITY_DATE_B = 'SET_MULTICITY_DATE_B'; 
export const SET_MULTICITY_TIME_B = 'SET_MULTICITY_TIME_B';

export const SET_MULTICITY_DATE_0 = 'SET_MULTICITY_DATE_0'; 
export const SET_MULTICITY_TIME_0 = 'SET_MULTICITY_TIME_0';  

export const SET_MULTICITY_DATE_1 = 'SET_MULTICITY_DATE_1'; 
export const SET_MULTICITY_TIME_1 = 'SET_MULTICITY_TIME_1';

export const SET_MULTICITY_DATE_2 = 'SET_MULTICITY_DATE_2'; 
export const SET_MULTICITY_TIME_2 = 'SET_MULTICITY_TIME_2';  

export const SET_MULTICITY_DATE_3 = 'SET_MULTICITY_DATE_3'; 
export const SET_MULTICITY_TIME_3 = 'SET_MULTICITY_TIME_3';

export const REST = "REST"

export const MULTICITY_ADULT_VALUE  = "MULTICITY_ADULT_VALUE";
export const MULTICITY_CHILD_VALUE  = "MULTICITY_CHILD_VALUE";
export const MULTICITY_INFANT_VALUE = "MULTICITY_INFANT_VALUE";

export const MULTICITY_SET_CABIN = "MULTICITY_SEMULTICITY_MULTICITY_T_CABIN";

export const MULTICITY_AIR = "MULTICITY_AIR";
export const MULTICITY_LAND = "MULTICITY_LAND";
export const MULTICITY_CRUISE = "MULTICITY_CRUISE";

export const MULTICITY_AT_LEAST_FREE_BAG = "MULTICITY_AT_LEAST_FREE_BAG";
export const MULTICITY_EXCLUDE_BASIC_ECONOMY_FARES = "MULTICITY_EXCLUDE_BASIC_ECONOMY_FARES";
export const MULTICITY_EXCLUDE_MULTI_TICKET_PRICING = "MULTICITY_EXCLUDE_MULTI_TICKET_PRICING";

export const MULTICITY_MAXIMUM_CONN = "MULTICITY_MAXIMUM_CONN";

export const MULTICITY_SEARCH_NEARBY_AIRPORTS = "MULTICITY_SEARCH_NEARBY_AIRPORTS";
export const MULTICITY_FLEXI_DATE = "MULTICITY_FLEXI_DATE";

export const MULTICITY_ANY_ALLIANCE = "MULTICITY_ANY_ALLIANCE";
export const MULTICITY_SKY_TEAM = "MULTICITY_SKY_TEAM";
export const MULTICITY_STAR_ALLIANCE = "MULTICITY_STAR_ALLIANCE";
export const MULTICITY_ONEWORLD = "MULTICITY_ONEWORLD";

export const MULTICITY_FREE = "MULTICITY_FREE";
export const MULTICITY_FEE = "MULTICITY_FEE";

// validation

export const FLYING_FROM_MC_ERROR_A = "FLYING_FROM_MC_ERROR_A"
export const FLYING_TO_MC_ERROR_A = "FLYING_TO_MC_ERROR_A"

export const FLYING_FROM_MC_ERROR_B = "FLYING_FROM_MC_ERROR_B"
export const FLYING_TO_MC_ERROR_B = "FLYING_TO_MC_ERROR_B"

export const FLYING_FROM_MC_ERROR_0 = "FLYING_FROM_MC_ERROR_0"
export const FLYING_TO_MC_ERROR_0 = "FLYING_TO_MC_ERROR_0"

export const FLYING_FROM_MC_ERROR_1 = "FLYING_FROM_MC_ERROR_1"
export const FLYING_TO_MC_ERROR_1 = "FLYING_TO_MC_ERROR_1"

export const FLYING_FROM_MC_ERROR_2 = "FLYING_FROM_MC_ERROR_2"
export const FLYING_TO_MC_ERROR_2 = "FLYING_TO_MC_ERROR_2"

export const FLYING_FROM_MC_ERROR_3 = "FLYING_FROM_MC_ERROR_3"
export const FLYING_TO_MC_ERROR_3 = "FLYING_TO_MC_ERROR_3"


export const FLYING_DATE_MC_ERROR_A = "FLYING_DATE_MC_ERROR_A"
export const FLYING_DATE_MC_ERROR_B = "FLYING_DATE_MC_ERROR_B"
export const FLYING_DATE_MC_ERROR_0 = "FLYING_DATE_MC_ERROR_0"
export const FLYING_DATE_MC_ERROR_1 = "FLYING_DATE_MC_ERROR_1"
export const FLYING_DATE_MC_ERROR_2 = "FLYING_DATE_MC_ERROR_2"
export const FLYING_DATE_MC_ERROR_3 = "FLYING_DATE_MC_ERROR_3"

export const ADULT_MC_ERROR = "ADULT_MC_ERROR"

// Set MultiCity Token

export const SET_MULTICITY_TOKEN = "SET_MULTICITY_TOKEN"

// Set MultiCity Data

export const SET_MULTICITY_DATA = "SET_MULTICITY_DATA" 