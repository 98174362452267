import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import { englishMessages } from "./utils/utility/EN-US-MSG";
import { frenchMessages } from "./utils/utility/FR-DA-MSG";
import { msg } from "./utils/utility/MessagesContainer";
import { frmsg } from "./utils/utility/MessageContainerFr";
import { EnglishMonts, FrenchMonts, EnglishShortMonths, FrenchShortMonths } from "./appStore/thunks/getCalenderLanguage";
i18n.use(initReactI18next).init({
  lng: "en",
  resources: {
    en: {
      translation: {
        ...englishMessages,
        ...msg,
        ...EnglishMonts,
        ...EnglishShortMonths
      }
    },
    fr: {
      translation: {
       ...frenchMessages,
       ...frmsg,
       ...FrenchMonts,
       ...FrenchShortMonths
      }
    }
  },
  keySeparator: false,
  interpolation: { escapeValue: false }
});

export default i18n;
