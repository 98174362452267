export const frmsg = {
  // error messages  //
  101: "Veuillez spécifier votre numéro d'agence ou votre numéro de téléphone et votre nom d'agent(e)",
  102: "Veuillez spécifier votre numéro d'agence ou votre numéro de téléphone",
  103: "Veuillez spécifier votre nom d'agent(e)",
  104: "Veuillez spécifier l'origine et la destination",
  105: "Veuillez spécifier les dates du voyage",
  106: "Veuillez spécifier le transporteur",
  107: "Le système n'est pas en mesure d'effectuer la recherche, veuillez essayer de nouveau.",
  108: "Veuillez sélectionner le bon tarif",
  109: "Nous sommes désolés, le système du transporteur n'a pas répondu et nous ne sommes pas en mesure d'afficher le plan de cabine.",
  110: "Vous devez compléter le dossier avant de sélectionner des sièges payants. Les frais pour les sièges payants peuvent changer après la réservation.	",
  111: "Veuillez attendre que les billets s'émettent avant de sélectionner des sièges payants.",
  112:"Nous sommes désolés, le système du transporteur n'a pas répondu et nous ne sommes pas en mesure d'afficher les règles tarifaires.",
  113: "Un problème technique s'est produit, veuillez essayer de nouveau",
  114: "Veuillez entrer une valeur (0 ou plus)",
  115: "Veuillez remplir tous les champs requis",
  // "116":"Please specify a gender", replaced by yash
  117: "Échec de l'envoi du courriel, veuillez essayer de nouveau.",
  // "118":"Failed to update the quote, please contact us.", commented
  119: "Votre itinéraire sauvegardé n'est plus valide. Veuillez effectuer une nouvelle recherche.",
  120: "Une erreur s'est produite lors de la sauvegarde du tarif, veuillez essayer de nouveau.",
  121: "Veuillez spécifier le numéro du dossier",
  122: "Veuillez spécifier la source de la réservation",
  123: `Veuillez insérer pour rechercher`,
  124: "Veuillez spécifier le nom complet",
  125: "Veuillez sélectionner un nombre de bébés plus petit ou égal au nombre d'adultes.",
  126: "Veuillez ajouter le numéro de dossier",
  127: "Numéro de dossier invalide",
  128: "Veuillez sélectionner des dates valides",
  129: "Le serveur a mis plus de temps à répondre. Veuillez vérifier à partir de mes fichiers.",
  130: "Le tarif n'a pu être sauvegardé dans le PNR. Veuillez essayer de le sauvegarder de nouveau.",
  131: "Il y a un délai pour émettre vos billets. Veuillez réafficher et réviser votre dossier.",
  132: "Échec de l'ajout/la modification de la surcommission. Veuillez essayer de nouveau.",
  133: "Le tarif n'a pu être sauvegardé dans le PNR.",
  134: "Veuillez remplir tous les détails obligatioires reliés au passeport	",
  135: "Les détails du passeport sont obligatoires pour ce transporteur",
  136: `Le champ "Sexe" ne peut pas être vide`,
  137: "Veuillez sélectionner une date d'expiration valide.",
  138: "Veuillez sélectionner une date de naissance valide",
  139: "L'âge de l'adulte doit être plus haut que 11 ans",
  140: "L'âge du bébé doit être plus bas que 2 ans",
  141: "Le paiement des sièges payants ne fut pas effectué puisque les billets ne sont pas encore émis",
  142: "La sélection des sièges payants est retirée",
  143: "Le serveur a pris plus de temps que prévu pour répondre.",
  144:"Le paiement pour les sièges payants n'a pas été effectué.",
  145: "Nous sommes désolés, certains vols ne sont plus disponibles dans les classes de service requises. Veuillez réessayer ou sélectionner d'autres vols.",
  146:"Ce transporteur exige que l’itinéraire soit réservé directement avec Intair. Veuillez utiliser Gigi pour effectuer votre recherche et réserver.",
  147:"Le nombre total d'adultes devrait correspondre à celui des nourrissons.",
  148:"Un enfant ne peut pas voyager seul et doit être accompagné d'un adulte.",
  149:"Sabre éprouve des problèmes à confirmer and enregistrer les tarifs. Veuillez recommencer.",
  150:"Les options tarifaires ne peuvent être affichées puisqu’aucune option tarifaire n’est disponible pour ce tarif.",
  151: "Une erreur technique est survenue. Veuillez nous contacter pour effectuer cette réservation",
  152: "Nous sommes désolés, mais l'annulation en ligne pour ce dossier n'est pas permise. Veuillez nous contacter.",
  153: "Une erreur technique est survenue.",
  154: "Veuillez saisir une date de naissance valide au format DDMMMYY, par exemple 12AUG90",
  155: "Dates invalides : la date de naissance est postérieure à la date de départ",
  // Success Messages  //
  //501: Votre itinéraire est sauvegardé			
  501:"Le nouveau tarif est sauvegardé",
  502: "Courriel envoyé",
  503: "Tarif sauvegardé avec succès",

  //  Inside Layout Messages  //
  1001: "Nous recherchons les meilleurs tarifs disponibles pour vous, y compris les tarifs Web exclusifs de certaines compagnies aériennes! S'il vous plaît soyez patient.",
  1002: "Aucun résulat trouvé pour les filtres sélectionnés",
  1003: "Aucun résulat trouvé",
  1004: "Veuillez modifier vos critères de recherche",
  1005: "En recherche des règles tarifaires… ",
  1006: "Impossible d'afficher les détails - Aucune donnée de vol n'est disponible dans le réservation",
  1007: "Votre date d'option est expirée - le paiement en ligne n'est pas permis.",
  1008: "Veuillez patienter pendant que nous sauvegardons vos changements.",
  1009: "Une erreur technique s'est produite, veuillez essayer de nouveau",
  1010: "Le paiement est requis aujourd'hui (jusqu'à 23:59) pour confirmer les sièges payants.",
  1011: "*Les champs des noms n'acceptent que les lettres",
  1012: "Les champs des numéros de passeport n'acceptent que les chiffres et les lettres",
  1014: "Échec de la mise à jour de la date d’option; veuillez réessayer.",
  1015: "Date de l'option mise à jour avec succès",
  1016: "Le paiement n'a été ajouté que pour certains voyageurs. Veuillez contacter le service client.",

  1017: "Veuillez patienter pendant qu’une nouvelle recherche est effectuée pour confirmer ce tarif et la disponibilité des vols. Les tarifs avec d’autres transporteurs seront également retournés à des fins de comparaison.",
  1018:"L'e-mail de ticket électronique n'a pas été envoyé car les numéros de ticket ne sont pas présents."
};
