import React from 'react';
import { useHistory } from "react-router-dom";
import { BookingHead } from '../../utils/constant';
import Dialog from "@material-ui/core/Dialog";
import { useDispatch,useSelector } from 'react-redux';
import {setSearchStatus, setSsrSaveEditFlag} from "../../appStore/actionCreators/flightBooking"
import Button from "react-bootstrap/Button";
import { useTranslation } from 'react-i18next';

function BookingSubHead(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const handleClick = (id,value) => {
        console.log("id.target.text",id.target.text,BookingHead.NEW_SEARCH,BookingHead.SEARCH_RESULTS)
        if(id.target.text === "Edit Markup" || value === "Edit Markup"){
            return props.editMarkup()
        }
        if(id.target.text === "Add/Edit Markup"){
            return props.editMarkup()
        }
        if (id.target.text === BookingHead.NEW_SEARCH)
            history.push("/");
        if (id.target.text === BookingHead.SEARCH_RESULTS)
        dispatch(setSearchStatus("searchTab"))
            history.push("/SearchResults");
    }

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
       let phoneNoValidation = props.phoneValCheck;
       let emailValidation = props.emailCheck;
       if(emailValidation || phoneNoValidation) return 
       setOpen(true);
    };

    const handleClose = (status) => {
        dispatch(setSsrSaveEditFlag(true))
        setOpen(false);
        props.saveData(status)
        
    };

    const handleEquivalentClick = () => {
        props.equivalentFare()
        
    }

    const { t } = useTranslation();

    return (
        <>
            <div className="bd-sab-head">
                <h3>
                    {props.imgSrc ? <img src={props.imgSrc} alt="icon" /> : ""}
                    {props.title}
                    {props.status ? <span className="bd-sab-head-stauts">{props.status}</span> : ""}
                </h3>
              
                <span style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                {props.equivalentFare ? <a className="bd-sub-head-link" style={{paddingRight:"25px"}} id={props.saveLink} onClick={()=>handleEquivalentClick()}>
                        {t("Equivalent Published Fare")}
                        <span className="bdp-u-Text">
                        <Button className="bf-popover-btn">
                              <i className="fas fa-info-circle bd-equivalent"></i>
                        </Button>
                        </span>
               </a> : ""}
                {/* {props.isEdit  ? <a className="bd-sub-head-link mr-3"  id={props.saveLink} onClick={()=>handleClose(false)}>{t("Cancel Details")}</a> : ""} */}
                    {props.addLink ? <a className="bd-sub-head-link" id={props.addLink} onClick={(id) => handleClick(id,"Edit Markup")}>{props.addLink}</a> : ""}
                    {props.editLink ? <a className="bd-sub-head-link" id={props.editLink} >{props.editLink}</a> : ""}
                    {/* {props.saveLink ? <a className="bd-sub-head-link" id={props.saveLink} onClick={handleClickOpen}>{props.saveLink}</a> : ""} */}
       
                    <div className="pInfo-btn-passenger confirmationbut mt-3" style={{paddingBottom:"0",marginBottom:"0"}}>
                    {props.isEdit && props.dataCheck === true &&
                    <button
                    className="btn with-payment"
                    id={props.saveLink}
                    onClick={()=>handleClose(false)}
                    >
                        {t("Cancel")}
                    </button>
                    }
                    {props.saveDataPassenger &&
                    <button
                    className="btn without-payment"
                    onClick={handleClickOpen}
                    >
                    {t("Save")}
                    </button>
                    }
                    </div>
                </span>
            </div>

            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className="bd-pi-modal"
            >
                <div className="bd-pi-modal-body">
                    <h4 className="bd-pi-modal-title">
                    {t("Are you sure?")}<span>{t("Do you want to save records?")}</span>
                    </h4>
                    <div className="bd-pi-modal-btn">
                        <button onClick={()=>handleClose(true)}>{t("Yes")}</button>
                        <button className="btn-no" onClick={()=>handleClose(false)}>{t("No")}</button>
                    </div>
                    
                </div>
            </Dialog>
        </>)
}

export default BookingSubHead;