import * as actionTypes from "../../actionCreators/RoundTrip/roundtrip_actionTypes";

const initialState = {
  roundtripFrom: "",
  roundtripTo: "",
  roundtripFromDate: null,
  roundtripToDate: null,
  roundtripFromTime: "",
  roundtripToTime: "",
  adult_val: 1,
  child_val: 0,
  infant_val: 0,
  cabin_val: "",
  searchnearbyAir: false,
  flexiDate: false,
  air: true,
  land: false,
  cruise: false,
  freeBag: false,
  exBasicEco: false,
  exMultiTicket: false,
  free_R1: false,
  fee_R2: false,
  anyallin_R1: false,
  skyteam_R2: false,
  staralli_R3: false,
  oneworld_R4: false,
  maximum_conn: "Two",
  roundtrip_token: "",

  // validation
  flying_from_err: "",
  flying_to_err: "",
  flying_date_from_err: "",
  flying_date_to_err: "",
  flying_adult_err: "",

  uuid_val: "",
  roundtpData: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ROUNDTRIP_FROM:
      return {
        ...state,
        roundtripFrom: action.val,
      };

    case actionTypes.SET_ROUNDTRIP_TO:
      return {
        ...state,
        roundtripTo: action.val,
      };

    case actionTypes.SET_ROUNDTRIP_DATE_FROM:
      return {
        ...state,
        roundtripFromDate: action.val,
      };

    case actionTypes.SET_ROUNDTRIP_DATE_TO:
      return {
        ...state,
        roundtripToDate: action.val,
      };

    case actionTypes.SET_ROUNDTRIP_TIME_FROM:
      return {
        ...state,
        roundtripFromTime: action.val,
      };

    case actionTypes.SET_ROUNDTRIP_TIME_TO:
      return {
        ...state,
        roundtripToTime: action.val,
      };

    case actionTypes.ADULT_VALUE:
      return {
        ...state,
        adult_val: action.val,
      };

    case actionTypes.CHILD_VALUE:
      return {
        ...state,
        child_val: action.val,
      };

    case actionTypes.INFANT_VALUE:
      return {
        ...state,
        infant_val: action.val,
      };

    case actionTypes.SET_CABIN:
      return {
        ...state,
        cabin_val: action.val,
      };

    case actionTypes.SEARCH_NEARBY_AIRPORTS:
      return {
        ...state,
        searchnearbyAir: action.val,
      };

    case actionTypes.FLEXI_DATE:
      return {
        ...state,
        flexiDate: action.val,
      };

    case actionTypes.AIR:
      return {
        ...state,
        air: action.val,
      };

    case actionTypes.LAND:
      return {
        ...state,
        land: action.val,
      };

    case actionTypes.CRUISE:
      return {
        ...state,
        cruise: action.val,
      };

    case actionTypes.AT_LEAST_FREE_BAG:
      return {
        ...state,
        freeBag: action.val,
      };

    case actionTypes.EXCLUDE_BASIC_ECONOMY_FARES:
      return {
        ...state,
        exBasicEco: action.val,
      };

    case actionTypes.EXCLUDE_MULTI_TICKET_PRICING:
      return {
        ...state,
        exMultiTicket: action.val,
      };

    case actionTypes.FREE:
      return {
        ...state,
        free_R1: action.val,
      };

    case actionTypes.FEE:
      return {
        ...state,
        fee_R2: action.val,
      };

    case actionTypes.ANY_ALLIANCE:
      return {
        ...state,
        anyallin_R1: action.val,
      };

    case actionTypes.SKY_TEAM:
      return {
        ...state,
        skyteam_R2: action.val,
      };

    case actionTypes.STAR_ALLIANCE:
      return {
        ...state,
        staralli_R3: action.val,
      };

    case actionTypes.ONEWORLD:
      return {
        ...state,
        oneworld_R4: action.val,
      };

    case actionTypes.MAXIMUM_CONN:
      return {
        ...state,
        maximum_conn: action.val,
      };

    case actionTypes.REST_ROUNDTRIP:
      return {
        ...initialState,
      };

    case actionTypes.SET_ROUNDTRIP_TOKEN:
      return {
        ...state,
        roundtrip_token: action.val,
      };

    case actionTypes.SET_FLYING_FROM_ERROR:
      return {
        ...state,
        flying_from_err: action.val,
      };

    case actionTypes.FLYING_TO_ERROR:
      return {
        ...state,
        flying_to_err: action.val,
      };

    case actionTypes.FLYING_DATE_FROM_ERROR:
      return {
        ...state,
        flying_date_from_err: action.val,
      };

    case actionTypes.FLYING_DATE_TO_ERROR:
      return {
        ...state,
        flying_date_to_err: action.val,
      };

    case actionTypes.SET_ADULT_ERROR:
      return {
        ...state,
        flying_adult_err: action.val,
      };

    case actionTypes.SET_UUID:
      return {
        ...state,
        uuid_val: action.val,
      };

    case actionTypes.SET_ROUNDTRIP_DATA:
      return {
        ...state,
        roundtpData: action.val,
      };

    default:
      return state;
  }
};

export default reducer;
