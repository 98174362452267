import { SET_MYFILES } from '../storeConstants';

const initialState = {
    myFilesData: []
};


const MyFilesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MYFILES:
            return {
                ...state,
                myFilesData: action.payload
            }

        default:
            return state;
    }
}

export default MyFilesReducer;
