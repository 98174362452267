import { setFareOption } from '../actionCreators/fareOption';
import { env } from '../../../src/appConfig'
import getApiResponse from '../../services/getApiResponse';
import { t } from "i18next";
import { toast } from 'react-toastify';

export const getFareOptions = (fareId) => {
        return async (dispatch, getState)  => {

        // let group = [];

        const corrId = getState().searchForm.uuid;
        const tokenData = getState().initToken;
        const token = tokenData && tokenData.token;
        const flightType = getState().searchForm.flightType;
        const isInternalUserAutheticated = getState().internalUser.isInternalUserAutheticated;
        const bookingChannel = isInternalUserAutheticated ? "internal" : "external";
        // const activeFareId = getState().fareOptions.activeFareId;
        // const fareOptions = getState().fareOptions.fareOptions;
        // fareOptions && group.push(...fareOptions);        

        // axios payload
        const config = {
            method: "POST",
            url: env.REACT_APP_API_BASE_URL +`/getBrandedFares/?token=${token}`,
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                
                "Access-Control-Allow-Credentials" : true,
                correlationId: corrId,
                flightType:flightType,
                bookingChannel:bookingChannel 

            },
            data: {fareId}                  
                    
        }
         

        const fareOptionsResult =  await getApiResponse(config);
           console.log("fareOptionsResult",fareOptionsResult)
        if(fareOptionsResult.results){
          
            dispatch(setFareOption(fareOptionsResult.results));
            
        }
        if((fareOptionsResult?.error?.message)?.includes('Token Expired') || (fareOptionsResult?.error?.message)?.includes("Token is expired")){
            toast.error(t('Your session has expired due to inactivity or a token expiration. Please log in again to continue.'), { position: toast.POSITION.TOP_CENTER, autoClose:true, progressStyle:{"transform-origin": "right"}});
          }
        return fareOptionsResult

        
        // if(fareOptionsResult) {
        //     const result = {
        //       [fareId]: fareOptionsResult
        //     }
        //     group.push(result);
        //     dispatch(setFareOption(group));
        //   }

      
       
    }
}