import React, { useEffect, useState, createRef } from 'react';

import TextField from '@material-ui/core/TextField';
import { useSelector, useDispatch } from "react-redux";
import { setApplyMarkupButton, setInitialPaymentRules, setInitialPaymentRulesChq, setItenaryData, setItinaryFaresType, setMarkupApplied, setMarkupButtonCheck, setMarkupLoader, setMarkupPopUp, setPaymentRules, setPaymentRulesForCheque, setUnSavedChangesInMarkupFlag} from "../../appStore/actionCreators/flightBooking"
import { getPaymentRules } from "../../appStore/thunks/getPaymentRules"
import { fareTypeConstant, PassengerType } from '../../utils/constant';
import SpinnerLoader from '../common/spinner';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import i18n from '../../LangConfig';
import BookingSubHead from './BookingSubHead';
import MarkupIcon from '../../Assets/markup-icon.svg';
import { OverlayTrigger } from "react-bootstrap";
import Popover from "react-bootstrap/Popover";
import { getSaveIntairFare } from '../../appStore/thunks/getSaveIntairFare';
import { setAdultMarkupValue, setBookingItinenary, setChildMarkupValue, setInfantMarkupValue } from '../../appStore/actionCreators/searchForm';
import { Dialog } from '@material-ui/core';
import tick from '../../Assets/tickIcon.png'

toast.configure()
function DefaultMarkup(props) {

    const { t } = useTranslation();
    const lang = useSelector((state) => state.lang.lang)
    const dispatch = useDispatch();
    const scrollDiv = createRef();
    const [adultMarkupError, setAdultErrorMarup] = useState("")
    const [childMarkUpValueError, setChildErrorMarup] = useState("")
    const [infantMarkUpValueError, setInfantErrorMarup] = useState("")
    const [serviceFeeError, setServiceFeeError] = useState("")
    const [ticketFeeError, setTicketFeeError] = useState("")
    const [adultMarkup, setMarkUpValue] = useState(0)
    const [childMarkUpValue, setChildMarkUpValue] = useState(0)
    const [infantMarkUpValue, setInfantMarkUpValue] = useState(0)
    const [serviceFee, setServiceFee] = useState(0)
    const [ticketFee, setTicketFee] = useState(0)
    const [open, setOpen] = React.useState(false);
    const [isLoader, setLoder] = useState(false);
    const [saveMarkupErrorMsg, setMarkupErrorMsg] = useState("");
    const [editIntairFare,setEditInatirFare] = useState(false)
    const [intairFareLoader,setIntairFareLoader] = useState(false)
    const [markupSaved, setMarkupSaved] = useState(false)
    let priceValue = useSelector((state) => state.flightBooking.bookingItenaries);
    const agentProfile = useSelector((state) => state.agentProfile);
    const { orgId, agencyId, agencyGroupId, agentName, agentDetails } = agentProfile
    const searchForm = useSelector((state) => state.searchForm);
    const { bookingFareId} = searchForm

    const currency = priceValue && priceValue.baseAmount && priceValue.baseAmount.currency
    const contractdetails = priceValue && priceValue.applicableContract && priceValue.applicableContract.passBackCommission
    const inBuildMarkup = priceValue && priceValue.applicableContract && priceValue?.applicableContract?.markup;

    const tFeeFilterData = priceValue && priceValue.applicableContract && priceValue?.otherRateComponents?.filter(fee => fee.type === "TICKETINGFEE")
    const tFee = tFeeFilterData && tFeeFilterData.length > 0 ? tFeeFilterData[0]?.amountIncludingTax?.amount : 0;
    const bFeeFilterData = priceValue && priceValue.applicableContract && priceValue?.otherRateComponents?.filter(fee => fee.type === "BOOKINGFEE")
    const bFee = bFeeFilterData && bFeeFilterData.length > 0 ? bFeeFilterData[0]?.amountIncludingTax?.amount : 0;
    let pergentageAdultMarkup = priceValue?.applicableContract?.passBackCommission?.commissionUsedForCalculation?.adult?.amount;
    let pergentageChildMarkup = priceValue?.applicableContract?.passBackCommission?.commissionUsedForCalculation?.child?.amount;
    let pergentageInfantMarkup = priceValue?.applicableContract?.passBackCommission?.commissionUsedForCalculation?.infant?.amount;
    let amtAdultMarkup = inBuildMarkup?.amountPerAdult?.amount;
    let amtChildMarkup = inBuildMarkup?.amountPerChild?.amount;
    let amtInfantMarkup = inBuildMarkup?.amountPerInfantInLap?.amount;
    let fareType = priceValue && priceValue.type
    let defaultAdultCommisionVal = fareType === fareTypeConstant.NET ? amtAdultMarkup : pergentageAdultMarkup;
    let defaultChildCommisionVal = fareType === fareTypeConstant.NET ? amtChildMarkup : pergentageChildMarkup;
    let defaultInfantCommisionVal = fareType === fareTypeConstant.NET ? amtInfantMarkup : pergentageInfantMarkup;
    

    useEffect(()=>{
        updateCommissionValues()
    },[bFee,tFee,defaultAdultCommisionVal,defaultInfantCommisionVal,defaultChildCommisionVal])
    

    useEffect(()=>{
        if(tFee>0){
            setTicketFee((priceValue?.otherRateComponents?.filter(fee => fee.type === "TICKETINGFEE"))[0]?.amountIncludingTax?.amount)
        }
        if(bFee>0){
            setServiceFee((priceValue?.otherRateComponents?.filter(fee => fee.type === "BOOKINGFEE"))[0]?.amountIncludingTax?.amount)
        }
    },[tFee,bFee,priceValue])

    useEffect(() => {       
          setMarkUpValue(defaultAdultCommisionVal > 0 ? defaultAdultCommisionVal : 0)
          setChildMarkUpValue(defaultChildCommisionVal > 0 ? defaultChildCommisionVal : 0)
          setInfantMarkUpValue(defaultInfantCommisionVal > 0 ? defaultInfantCommisionVal : 0)  
          setServiceFee(bFee >0 ? bFee : 0) 
          setTicketFee(tFee > 0 ? tFee : 0)
    }, [contractdetails,editIntairFare])

    const bookingWithQuote = useSelector((state) => state.savedQuotes.bookingWithQuote);
    let adultsMarkup = useSelector((state) => state.searchForm.adultMarkupValue);
    let childMarup = useSelector((state) => state.searchForm.childMarkupValue);
    let infantMarkup = useSelector((state) => state.searchForm.infantMarkupValue);
    const bookingData = useSelector((state) => state.flightBooking.bookingDetails);
    const flightDetail = useSelector((state) => state.flightBooking);
    const markupLoader = useSelector((state) => state.flightBooking.markupLoader);
    let adultsCount
    let childCount
    let infantCount
    let servicesFee = true;
    let ticketingFee = true;

    const searchAdultCOunt = useSelector((state) => state.searchForm.passengerAdult);
    const searchChildCount = useSelector((state) => state.searchForm.passengerChildren);
    const searchInfantCount = useSelector((state) => state.searchForm.passengerInfant);
    const pnrAdultsCount = useSelector((state) => state.flightBooking.pnrAdultCount)
    const pnrChildCount = useSelector((state) => state.flightBooking.pnrChildCount)
    const pnrInfantCount = useSelector((state) => state.flightBooking.pnrInfantCount)
    const pnrFlag = useSelector((state) => state.flightBooking.pnrFlag)
    const equivalentFareValue = useSelector((state) => state.flightBooking.equivalentFareValue);
    const providerId = useSelector((state) => state.searchForm.providerId);
    const bookingCode = flightDetail && flightDetail.bookingStatus && flightDetail.bookingStatus.code
    //const [disableSaveButton, setDisableSaveButton] = useState(false);
    const priceFareData = useSelector((state) => state.flightBooking.priceFareData);
    const markupStatus = useSelector((state) => state.flightBooking.markupStatus);
    const applyMarkupButton = useSelector((state) => state.flightBooking.applyMarkupButton);
    const isInternalUserAutheticated = useSelector((state) => state.internalUser.isInternalUserAutheticated);
    const markupPopUp = useSelector((state)=>state.flightBooking.markupPopUp)
    const initialItineraryFare = useSelector((state) => state.flightBooking.initialItineraryFare);
    const initialPaymentRules = useSelector((state) => state.flightBooking.initialPaymentRules);
    const initialPaymentRulesChq = useSelector((state) => state.flightBooking.initialPaymentRulesChq);
    const unSavedChangesInMarkupFlag = useSelector((state) => state.flightBooking.unSavedChangesInMarkupFlag);
    const markupBtnCheck = useSelector((state) => state.flightBooking.marKupButtonCheck)
    // logic for language selection
    useEffect(() => { i18n.changeLanguage(lang) }, [lang])
    useEffect(() => {
        dispatch(setMarkupPopUp(false))
        dispatch(setUnSavedChangesInMarkupFlag(false))
        setMarkupSaved(false)
    },[])
    useEffect(()=>{
        if(markupBtnCheck){
            setMarkupSaved(false)
        }
    },[markupBtnCheck])

    useEffect(()=>{
        setEditInatirFare(!markupStatus)
    },[markupStatus])

    
    let repriceQuote = useSelector((state) => state.savedQuotes.repriceQuote);
    let fareDetails = repriceQuote && repriceQuote.fareDetails
    let quoteDetail = fareDetails && fareDetails.itineraryFare
    if (bookingWithQuote) {
        priceValue = quoteDetail
        fareType = quoteDetail && quoteDetail.type
    }
    const faresPerTravellers = priceValue && priceValue.paxFares;
    let paxFareData = equivalentFareValue && equivalentFareValue.paxFares;
    let paxTypeCodeArray = []

    faresPerTravellers && faresPerTravellers.map(data => {
        let paxTypeCode = data && data.passengerDetails && data.passengerDetails.code

        if (!paxTypeCodeArray.includes(paxTypeCode)) {
            paxTypeCodeArray.push(paxTypeCode)
        }
    })

    let amountInfo = []
    let paxTypeAmount = paxFareData

    if (pnrFlag) {
        adultsCount = pnrAdultsCount
        childCount = pnrChildCount
        infantCount = pnrInfantCount
    } else {
        adultsCount = searchAdultCOunt
        childCount = searchChildCount
        infantCount = searchInfantCount
    }

    paxTypeAmount && paxTypeAmount.map((paxType, index) => {
        let passengerDetails = paxType.passengerDetails;
        let type = passengerDetails && passengerDetails.type;
        let quantity = passengerDetails && passengerDetails.quantity
        let totalAmount = paxType.totalAmount
        let baseAmount = paxType.baseAmount
        let supplierBaseAmount = paxType.supplierBaseAmount
        let totalTaxes = paxType.totalTaxes
        let taxes = paxType.taxes
     
        let ispreset = amountInfo.filter(obj => obj.type === type)

        if (ispreset.length === 0) {
            let passenger = {
                type: type,
                quantity: quantity,
                totalAmount: totalAmount.amount,
                baseAmount: baseAmount.amount,
                totalTaxes: totalTaxes.amount,
                taxes: taxes,
                supplierBaseAmount: supplierBaseAmount && supplierBaseAmount.amount
            }
            amountInfo.push(passenger)
        }
    })


    if (pnrFlag) {
        adultsCount = pnrAdultsCount
        childCount = pnrChildCount
        infantCount = pnrInfantCount
    } else {
        adultsCount = searchAdultCOunt
        childCount = searchChildCount
        infantCount = searchInfantCount
    }

    let passengetCount = adultsCount + childCount + infantCount;
    
    
    const recordLocator = flightDetail && flightDetail.pnr;
    let errorMsg = t('114')

    let arr = []
    let passengerRef = []


    

    for (let i = 1; i <= passengetCount; i++) {
        if (i <= adultsCount) {
            arr = [
                ...arr,
                {
                    id: `${i}`,
                    paxType: PassengerType.ADULT
                },
            ];
            passengerRef.push(`${i}`)
        } else if (i > adultsCount && i <= adultsCount + childCount) {
            let j = i - adultsCount;
            arr = [
                ...arr,
                {
                    id: `${i}`,
                    paxType: PassengerType.CHILD
                },
            ];
            passengerRef.push(`${i}`)
        } else {
            let j = i - (adultsCount + childCount);
            arr = [
                ...arr,
                {
                    id: `${i}`,
                    paxType: PassengerType.INFANTINLAP,
                },
            ];
            passengerRef.push(`${i}`)
        }
    }

    const updateCommissionValues = () => {
        if (bFee > 0) {
            setServiceFee(bFee)
        }
        if (tFee > 0) {
            setTicketFee(tFee)
        }
        if (defaultAdultCommisionVal > 0) {
            setMarkUpValue(defaultAdultCommisionVal)
        }
        if (defaultChildCommisionVal > 0) {
            setChildMarkUpValue(defaultChildCommisionVal)
        }
        if (defaultInfantCommisionVal > 0) {
            setInfantMarkUpValue(defaultInfantCommisionVal)
        }
    }

    const addMarkUpValueInfo = (e) => {
        let input = parseInt(String(e.target.value).trim(),10)
        let thresholdValue = props?.from == 'booking' ? Number(priceFareData?.itineraryFare?.applicableContract?.commissionDetails?.commissionUsedForCalculation?.adult?.amount) : Number(bookingData?.itineraryFare?.applicableContract?.commissionDetails?.commissionUsedForCalculation?.adult?.amount)
        if(fareType === fareTypeConstant.PUB){
            if (!String(e.target.value).trim().length || input < 0) {
                setMarkUpValue(input)
                setAdultErrorMarup(errorMsg)
            }
            else {
                if (input <= thresholdValue) {
                    // setChildMarkUpValue(childMarkUpValue)
                    setMarkUpValue(String(input))
                    setAdultErrorMarup("")
                    dispatch(setApplyMarkupButton(false))
                    //setDisableSaveButton(false)
                } else {
                    setAdultErrorMarup(t("Value must be")+String(thresholdValue)+t('or lower'))
                    //setDisableSaveButton(true)
                    setMarkUpValue(String(input))
                }
                // let value = e.target.value
                // dispatch(setChildMarkupValue(value.trim().length>0?value:0))
            }
        }
        else{
            if (String(e.target.value).trim().length && 0 <= input){
                setMarkUpValue(String(input))
                setAdultErrorMarup("")
                dispatch(setApplyMarkupButton(false))
                //setDisableSaveButton(false)
            }
            else {
                setMarkUpValue(input)
                setAdultErrorMarup(errorMsg)
            }

        }

            

            // let value = e.target.value

            // dispatch(setAdultMarkupValue(value.trim().length>0?value:0))
            // if (!value.trim().length > 0) {
            //     setAdultErrorMarup(errorMsg)
            // }
        

    }

    const addChildMarkUpValueInfo = (e) => {
        let input = parseInt(String(e.target.value).trim(),10)
        let thresholdValue = props?.from == 'booking' ? Number(priceFareData?.itineraryFare?.applicableContract?.commissionDetails?.commissionUsedForCalculation?.child?.amount) : Number(bookingData?.itineraryFare?.applicableContract?.commissionDetails?.commissionUsedForCalculation?.child?.amount)
        if(fareType === fareTypeConstant.PUB){
            if (!String(e.target.value).trim().length || input < 0) {
                setChildMarkUpValue(input)
                setChildErrorMarup(errorMsg)
            }
            else {
                if (input <= thresholdValue) {
                    // setChildMarkUpValue(childMarkUpValue)
                    setChildMarkUpValue(String(input))
                    setChildErrorMarup("")
                    dispatch(setApplyMarkupButton(false))
                    //setDisableSaveButton(false)
                } else {
                    setChildErrorMarup(t("Value must be")+String(thresholdValue)+t('or lower'))
                    //setDisableSaveButton(true)
                    setChildMarkUpValue(String(input))
                }
                // let value = e.target.value
                // dispatch(setChildMarkupValue(value.trim().length>0?value:0))
            }
        }
        else{
            if (String(e.target.value).trim().length && 0 <= input){
                setChildMarkUpValue(String(input))
                setChildErrorMarup("")
                dispatch(setApplyMarkupButton(false))
                //setDisableSaveButton(false)
            }
            else{
                setChildMarkUpValue(input)
                setChildErrorMarup(errorMsg)
            }
        }

        

    }

    const addInfantMarkUpValueInfo = (e) => {
        let input = parseInt(String(e.target.value).trim(),10)
        let thresholdValue = props?.from == 'booking' ? Number(priceFareData?.itineraryFare?.applicableContract?.commissionDetails?.commissionUsedForCalculation?.infant?.amount) : Number(bookingData?.itineraryFare?.applicableContract?.commissionDetails?.commissionUsedForCalculation?.infant?.amount)
        if(fareType === fareTypeConstant.PUB){
            if (!String(e.target.value).trim().length || input < 0) {
                setInfantMarkUpValue(input)
                setInfantErrorMarup(errorMsg)
            }
            else {
                if (input <= thresholdValue) {
                    setInfantMarkUpValue(String(input))
                    setInfantErrorMarup("")
                    dispatch(setApplyMarkupButton(false))
                    //setDisableSaveButton(false)
                } else {
                    setInfantErrorMarup(t("Value must be")+String(thresholdValue)+t('or lower'))
                    //setDisableSaveButton(true)
                    setInfantMarkUpValue(String(input))
                }
            }
        }
        else{
            if (String(e.target.value).trim().length && 0 <= input){
                setInfantMarkUpValue(String(input))
                setInfantErrorMarup("")
                dispatch(setApplyMarkupButton(false))
                //setDisableSaveButton(false)
            }
            else{
                setInfantMarkUpValue(input)
                setInfantErrorMarup(errorMsg)
            }
        }
        

    }

    const addServiceFeeValueInfo = (e) => {
        let input = parseInt(String(e.target.value).trim(),10)
        if (input < 0) {
            setServiceFee("")
        }
        else {
            if (input?.length > 3) {
                // setServiceFee(infantMarkUpValue)
                setServiceFee(String(input))
            } else {
                setServiceFee(String(input))
            }
            let value = e.target.value
            
           // dispatch(setAdultMarkupValue(value.trim().length>0?value:0))
            if(value.trim().length > 0){
                setServiceFeeError("")
                dispatch(setApplyMarkupButton(false))
            } else {
                setServiceFeeError(errorMsg)
            }
        }

    }

    const addTicketFeeValueInfo = (e) => {
        let input = parseInt(String(e.target.value).trim(),10)
        if (input < 0) {
            setTicketFee("")
        }
        else {
            if (input?.length > 3) {
                // setTicketFee(infantMarkUpValue)
                setTicketFee(input)
            } else {
                setTicketFee(input)
            }
            let value = e.target.value
            
           // dispatch(setAdultMarkupValue(value.trim().length>0?value:0))
            if(value.trim().length > 0){
                setTicketFeeError("")
                dispatch(setApplyMarkupButton(false))
            } else {
                setTicketFeeError(errorMsg)
            }
        }
    }

    const handleMarkupSave = () => {
        handleIntairFareSubmit()
        dispatch(setMarkupPopUp(false))
        dispatch(setMarkupButtonCheck(false))
        dispatch(setApplyMarkupButton(true))
    };


    let agencyArr = []
    if (adultsCount > 0) {
        let obj = {
            paxType: PassengerType.ADULT,
            quantity: adultsCount,
            perPaxAmount: {
                amount: adultsMarkup ? adultsMarkup : 0,
                currency: currency
            }
        }
        agencyArr.push(obj)
    }
    if (childCount > 0) {
        let obj = {
            paxType: PassengerType.CHILD,
            quantity: childCount,
            perPaxAmount: {
                amount: childMarup ? childMarup : 0,
                currency: currency
            }
        }
        agencyArr.push(obj)
    }

    if (infantCount > 0) {
        let obj = {
            paxType: PassengerType.INFANTINLAP,
            quantity: infantCount,
            perPaxAmount: {
                amount: infantMarkup ? infantMarkup : 0,
                currency: currency
            }
        }
        agencyArr.push(obj)
    }
    let agencyDefaultMarkup;
    if(fareType === fareTypeConstant.NET){
            let obj = {
                "adult": {
                    "amount": Number(adultMarkup),
                    "amountType": "Amount"
                },
                "child": {
                    "amount": Number(childMarkUpValue),
                    "amountType": "Amount"
                },
                "infant": {
                    "amount": Number(infantMarkUpValue),
                    "amountType": "Amount"
                }
            }
            if(childCount == 0){
              delete obj.child
            }
            if(infantCount == 0){
              delete obj.infant
            }
           agencyDefaultMarkup = obj;
    }
    else{ 

        let obj = {
            "adult": {
                "amount": Number(adultMarkup),
                "amountType": "Percentage"
            },
            "child": {
                "amount": Number(childMarkUpValue),
                "amountType": "Percentage"
            },
            "infant": {
                "amount": Number(infantMarkUpValue),
                "amountType": "Percentage"
            }
        }
            
            if(childCount == 0){
            delete obj.child
            }
            if(infantCount == 0){
            delete obj.infant
            }
            agencyDefaultMarkup = obj;      
    }

    const handleIntairFareCancel = async() => {
        dispatch(setMarkupPopUp(false))
        dispatch(setMarkupButtonCheck(false))
        setInfantErrorMarup("")
        setChildErrorMarup("")
        setAdultErrorMarup("")
        dispatch(setApplyMarkupButton(true))
        dispatch(setPaymentRules(initialPaymentRules))
        dispatch(setPaymentRulesForCheque(initialPaymentRulesChq))
        dispatch(setItenaryData(initialPaymentRules?.itineraryFare))
        
        // if(props.from=="booking"){
            let agencyMarkups = initialPaymentRules.agencyMarkups;
            let adultData = agencyMarkups && agencyMarkups.filter(obj=>obj.paxType===PassengerType.ADULT)
            let childData = agencyMarkups && agencyMarkups.filter(obj=>obj.paxType===PassengerType.CHILD)
            let infantdata = agencyMarkups && agencyMarkups.filter(obj=>obj.paxType===PassengerType.INFANTINLAP)
            let adultAmount = adultData && adultData[0] ? adultData[0].perPaxAmount &&adultData[0].perPaxAmount.amount :0
            let childAmount = childData && childData[0] ? childData[0].perPaxAmount &&childData[0].perPaxAmount.amount :0
            let infantAmount =infantdata && infantdata[0] ? infantdata[0].perPaxAmount &&infantdata[0].perPaxAmount.amount :0



            dispatch(setAdultMarkupValue(adultAmount))
            dispatch(setChildMarkupValue(childAmount))
            dispatch(setInfantMarkupValue(infantAmount))
        // }else{
        //     let agencyMarkups = bookingData.agencyMarkups;
        //     let adultData = agencyMarkups && agencyMarkups.filter(obj=>obj.paxType===PassengerType.ADULT)
        //     let childData = agencyMarkups && agencyMarkups.filter(obj=>obj.paxType===PassengerType.CHILD)
        //     let infantdata = agencyMarkups && agencyMarkups.filter(obj=>obj.paxType===PassengerType.INFANTINLAP)
        //     let adultAmount = adultData && adultData[0] ? adultData[0].perPaxAmount &&adultData[0].perPaxAmount.amount :0
        //     let childAmount = childData && childData[0] ? childData[0].perPaxAmount &&childData[0].perPaxAmount.amount :0
        //     let infantAmount =infantdata && infantdata[0] ? infantdata[0].perPaxAmount &&infantdata[0].perPaxAmount.amount :0


        //     dispatch(setAdultMarkupValue(adultAmount))
        //     dispatch(setChildMarkupValue(childAmount))
        //     dispatch(setInfantMarkupValue(infantAmount))
        // }
        
        
    }

    const handleApplyMarkup = async() => {
        setEditInatirFare(true)
        dispatch(setMarkupLoader(true))
        let requestPayload={}
        if(props.from=="booking"){
            requestPayload={
                "providerId": providerId,
                "organizationId": orgId,
                "agencyId": agencyId,
                "fareId": bookingFareId,
                "serviceFee": {
                  "amount": Number(serviceFee),
                  "currency": "CAD"
                },
                "ticketingFee": {
                  "amount": Number(ticketFee),
                  "currency": "CAD"
                },
                "commissionUsedForCalculation": agencyDefaultMarkup,
                "markupUsedForCalculation": agencyDefaultMarkup,
        }

        }else{
            requestPayload={
                "providerId": providerId,
                "organizationId": orgId,
                "agencyId": agencyId,
                "recordLocator": recordLocator,
                "serviceFee": {
                  "amount": Number(serviceFee),
                  "currency": "CAD"
                },
                "ticketingFee": {
                  "amount": Number(ticketFee),
                  "currency": "CAD"
                },
                "commissionUsedForCalculation": agencyDefaultMarkup,
                "markupUsedForCalculation": agencyDefaultMarkup,
        }
        
        }
        if (!isInternalUserAutheticated) {
            delete requestPayload.commissionUsedForCalculation
            delete requestPayload.markupUsedForCalculation
            delete requestPayload.serviceFee
            delete requestPayload.ticketingFee
        }
        else if(fareType === fareTypeConstant.NET){
            delete requestPayload.commissionUsedForCalculation
         }else{
            delete requestPayload.markupUsedForCalculation
         }
        let result = await props?.applyMarkup(requestPayload)
        // dispatch(setMarkupLoader(false))
        if(result?.error){

        }
        else{
            dispatch(setMarkupPopUp(true))
        }
    }

    const handleIntairFareSubmit = async () => {
        setIntairFareLoader(true)
        dispatch(setMarkupLoader(true))
        const markUpPayload = props.handleSaveMarup();
        let requestPayload={}
        if(props.from=="booking"){
            requestPayload={
                ...markUpPayload,
                "providerId": providerId,
                "fareId": bookingFareId,
                "serviceFee": {
                  "amount": Number(serviceFee),
                  "currency": "CAD"
                },
                "ticketingFee": {
                  "amount": Number(ticketFee),
                  "currency": "CAD"
                },
                "commissionUsedForCalculation": agencyDefaultMarkup,
                "markupUsedForCalculation": agencyDefaultMarkup,
                "userName": agentDetails.username,
                "agentName": agentName  
        }

        }else{
            requestPayload={
                ...markUpPayload,
                "providerId": providerId,
                "recordLocator": recordLocator,
                "serviceFee": {
                  "amount": Number(serviceFee),
                  "currency": "CAD"
                },
                "ticketingFee": {
                  "amount": Number(ticketFee),
                  "currency": "CAD"
                },
                "commissionUsedForCalculation": agencyDefaultMarkup,
                "markupUsedForCalculation": agencyDefaultMarkup,
                "userName": agentDetails.username,
                "agentName": agentName  
        }

        }
       
       let agencyDefaultAdultMarkup = defaultAdultCommisionVal > 0 ? defaultAdultCommisionVal : 0;
       let agencyDefaultChildMarkup = defaultChildCommisionVal > 0 ? defaultChildCommisionVal : 0;
       let agencyDefaultInfantMarkup = defaultInfantCommisionVal > 0 ? defaultInfantCommisionVal : 0;
        
    //    if(agencyDefaultAdultMarkup === adultMarkup && agencyDefaultChildMarkup === childMarkUpValue && agencyDefaultInfantMarkup === infantMarkUpValue){
    //         delete requestPayload.commissionUsedForCalculation
    //         delete requestPayload.markupUsedForCalculation
    //     }
    //     if(Number(serviceFee) ===  Number(bFee)){
    //         delete requestPayload.serviceFee
    //     }
    //     if(Number(ticketFee) ===  Number(tFee)){
    //         delete requestPayload.ticketingFee
    //     }
        if (!isInternalUserAutheticated) {
            delete requestPayload.commissionUsedForCalculation
            delete requestPayload.markupUsedForCalculation
            delete requestPayload.serviceFee
            delete requestPayload.ticketingFee
        }
        else if (fareType === fareTypeConstant.NET) {
            delete requestPayload.commissionUsedForCalculation
        } else {
            delete requestPayload.markupUsedForCalculation
        }
        let intairFareResult = "";
        if (props.from == "booking") {
            intairFareResult = await dispatch(getSaveIntairFare(requestPayload, "booking"))

        } else {
            intairFareResult = await dispatch(getSaveIntairFare(requestPayload, "confirmation"))
        }  
        let resultKeys = Object.keys(intairFareResult)
        let failVariable = false;
        for(let index = 0; index < resultKeys.length; index++){
            let key = resultKeys[index]
            if(intairFareResult[String(key)] == "Failed"){
                failVariable = true;
                break;
            }
        }
        let reqPayload;
        if(intairFareResult == "error" || intairFareResult === undefined){
            toast.error(t("1009"), { position: toast.POSITION.TOP_CENTER, autoClose:true, progressStyle:{"transform-origin": "right"}});
        }
        else if(intairFareResult?.error){
            toast.error(String(intairFareResult?.error?.message), { position: toast.POSITION.TOP_CENTER, autoClose:true, progressStyle:{"transform-origin": "right"}});
        }else{
            dispatch(setMarkupApplied(true))
            dispatch(setApplyMarkupButton(true))
            setMarkupSaved(true)
            let result = intairFareResult
            dispatch(setItenaryData(result?.itineraryFare))
            dispatch(setItinaryFaresType(result?.itineraryFare?.type))
            let defaultTransaction = result?.defaultTransactionDetails;
            for (let index = 0; index < defaultTransaction.length; index++) {
                defaultTransaction[index]["agencyMarkups"] = result?.agencyMarkups
                defaultTransaction[index]["itineraryFare"] = result?.itineraryFare  
                if (defaultTransaction[index]?.type == "CreditCard") {
                    dispatch(setPaymentRules(defaultTransaction[index]))
                    dispatch(setInitialPaymentRules(defaultTransaction[index]))
                }
                else if (defaultTransaction[index]?.type == "Cheque") {
                    dispatch(setPaymentRulesForCheque(defaultTransaction[index]))
                    dispatch(setInitialPaymentRulesChq(defaultTransaction[index]))
                }
                else {
                    dispatch(setPaymentRules(defaultTransaction[index]))
                    dispatch(setInitialPaymentRules(defaultTransaction[index]))
                }
            }
            // toast.success(t("Markup Successfully Updated"), { position: toast.POSITION.TOP_CENTER, autoClose:true, progressStyle:{"transform-origin": "right"}}); 

            // if (props.from == "booking") {
            //     reqPayload = {
            //         fareId: bookingFareId,
            //         fareType: fareType,
            //         passengers: arr,
            //         agencyMarkups: agencyArr,
            //         payments: [{
            //             passengerRefs: passengerRef,
            //             type: "CreditCard"
            //         }]
            //     }

            // } else {
            //     reqPayload = {
            //         recordLocator: recordLocator,
            //         fareType: fareType,
            //         passengers: arr,
            //         agencyMarkups: agencyArr,
            //         payments: [{
            //             passengerRefs: passengerRef,
            //             type: "CreditCard"
            //         }]
            //     }
            // }

    //    let resultOfPaymentRulesByCard =  await dispatch(getPaymentRules(reqPayload,"CreditCard"))
    //    let resultOfPaymentRulesByCheque;
    //    if(resultOfPaymentRulesByCard){
    //     if(props.from == "booking"){
    //         reqPayload = {
    //             fareId: bookingFareId,
    //             fareType: fareType,
    //             passengers: arr,
    //             agencyMarkups: agencyArr,
    //             payments: [{
    //                 passengerRefs: passengerRef,
    //                 type: "Cheque"
    //             }]
    //         }

    //     }else{
    //         reqPayload = {
    //             recordLocator: recordLocator,
    //             fareType: fareType,
    //             passengers: arr,
    //             agencyMarkups: agencyArr,
    //             payments: [{
    //                 passengerRefs: passengerRef,
    //                 type: "Cheque"
    //             }]
    //         }
    //     }

    //     resultOfPaymentRulesByCheque = await dispatch(getPaymentRules(reqPayload,"Cheque"))
    //    }
        }
       setIntairFareLoader(false)
       setEditInatirFare(false)
       dispatch(setMarkupLoader(false))
    }

    const editMarkup = () => {
        setEditInatirFare(true)
    }

    const blockInvalidChar = e => {
        ["e", "E", "+", "-", "@"].includes(e.key) && e.preventDefault()
        if (e.key === '.') {
            e.preventDefault();
        }
    }

    const applyButtonGreyOut = () => {
        return (adultMarkupError?.length || childMarkUpValueError?.length || infantMarkUpValueError?.length) ? "configProfit-btn-greyedOut" : "configProfit-btn"
    }

    return (
        <>
            {props.show ?
                <div ref={scrollDiv} >
                    <div>
                    <div>
                        {<>
                            {(isInternalUserAutheticated) &&
                            <div>
                                {
                                    <span>
                                            <BookingSubHead title={t("Please use below option to edit Intair Fares")} link={false} imgSrc={MarkupIcon} />
                                    </span>
                                }

                                {saveMarkupErrorMsg !== "" && saveMarkupErrorMsg !== undefined &&
                                    <div className='payment-error-msg-wrapper'>
                                        <div className="seat-udate-status">
                                            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                                            <span>{saveMarkupErrorMsg}</span>
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    {adultsCount !== 0 && (((contractdetails?.amountPerAdult?.amount >= 0) || (amtAdultMarkup >= 0))) &&
                                        <div className="configProfit-col" >
                                            <div className="title">{t("Per Adult")}  ( X {adultsCount} )</div>
                                            <div className="configProfit-input pt-3">
                                                <div className={lang == "en" ? "bd-pi-input" : "bd-pi-input-fr"}>
                                                    <TextField
                                                        id="currentMarkup"
                                                        label={fareType === fareTypeConstant.NET ? t("Default Markup") : t("Current Commission")}
                                                        variant="outlined"
                                                        value={adultMarkup}
                                                        onChange={(e) => addMarkUpValueInfo(e)}
                                                        onKeyDown={blockInvalidChar}
                                                        type="number"
                                                        disabled={markupPopUp || !editIntairFare}
                                                        error={adultMarkupError !== ""}
                                                        helperText={adultMarkupError !== "" && adultMarkupError}
                                                    />
                                                    <span className="configProfit-dollar">{fareType === fareTypeConstant.NET ?  window.selectedDollarValue : "%"}</span>
                             
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {childCount !== 0 &&  ((contractdetails?.amountPerChild?.amount >= 0) || (amtChildMarkup >= 0)) &&
                                        <div className="configProfit-col">
                                            <div className="title">{t("Per child")} ( x {childCount} )</div>
                                            <div className="configProfit-input pt-3">
                                                <div className={lang == "en" ? "bd-pi-input" : "bd-pi-input-fr"}>
                                                    <TextField
                                                        id="currentMarkup"
                                                        label={fareType === fareTypeConstant.NET ? t("Default Markup") : t("Current Commission")}
                                                        variant="outlined"
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        value={childMarkUpValue}
                                                        disabled={markupPopUp || !editIntairFare}
                                                        error={childMarkUpValueError !== ""}
                                                        helperText={childMarkUpValueError !== "" && childMarkUpValueError}
                                                        onChange={(e) => addChildMarkUpValueInfo(e)}
                                                        onKeyDown={blockInvalidChar}
                                                    />
                                                    <span className="configProfit-dollar">{fareType === fareTypeConstant.NET ?  window.selectedDollarValue : "%"}</span>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    }


                                    {infantCount !== 0 && ((contractdetails?.amountPerInfantInLap?.amount >= 0) || (amtInfantMarkup >= 0)) &&
                                        <div className="configProfit-col ">
                                            <div className="title">{t("Per Infant")} ( x {infantCount} )</div>
                                            <div className="configProfit-input pt-3">
                                                <div className={lang == "en" ? "bd-pi-input" : "bd-pi-input-fr"}>
                                                    <TextField
                                                        id="currentMarkup"
                                                        label={fareType === fareTypeConstant.NET ? t("Default Markup") : t("Current Commission")}
                                                        variant="outlined"
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        value={infantMarkUpValue}
                                                        disabled={markupPopUp || !editIntairFare}
                                                        error={infantMarkUpValueError !== ""}
                                                        helperText={infantMarkUpValueError !== "" && infantMarkUpValueError}
                                                        onChange={(e) => addInfantMarkUpValueInfo(e)}
                                                        onKeyDown={blockInvalidChar}
                                                    />
                                                    <span className="configProfit-dollar">{fareType === fareTypeConstant.NET ?  window.selectedDollarValue : "%"}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="row">
                                    {servicesFee &&
                                        <div className="configProfit-col ">
                                            
                                            <div className="configProfit-input pt-3">

                                                <div className={lang == "en" ? "bd-pi-input" : "bd-pi-input-fr"}>
                                                    <TextField
                                                        id="serviceFee"
                                                        label={t("Service fee")}
                                                        variant="outlined"
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        value={serviceFee}
                                                        disabled={markupPopUp || !editIntairFare}
                                                        error={serviceFeeError !== ""}
                                                        helperText={serviceFeeError !== "" && serviceFeeError}
                                                        onChange={(e) => addServiceFeeValueInfo(e)}
                                                        onKeyDown={blockInvalidChar}
                                                    />
                                                    <span className="configProfit-dollar">{ window.selectedDollarValue}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {ticketingFee &&
                                        <div className="configProfit-col ">
                                            
                                            <div className="configProfit-input pt-3">

                                                <div className={lang == "en" ? "bd-pi-input" : "bd-pi-input-fr"}>
                                                    <TextField
                                                        id="ticketFee"
                                                        label={t("Ticketing fee")}
                                                        variant="outlined"
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                        value={ticketFee}
                                                        disabled={markupPopUp || !editIntairFare}
                                                        error={ticketFeeError !== ""}
                                                        helperText={ticketFeeError !== "" && ticketFeeError}
                                                        onChange={(e) => addTicketFeeValueInfo(e)}
                                                        onKeyDown={blockInvalidChar}
                                                    />
                                                    <span className="configProfit-dollar">{ window.selectedDollarValue}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    </div>


                            </div>}
                            </>
                        }
                        <div className="cancelBook-box" >
                        { 
                        markupLoader?
                        <div className="col-md-12 text-center mt-3">
                            <div style={{"padding":"1.5rem"}}><h3>{t("Please wait while we are updating the markup.")}</h3></div>
                        <SpinnerLoader/>
                        </div>
                        :
                                        markupStatus ? markupSaved ? 
                                            <>
                                                <div className="configProfit-col" style={{ "maxWidth": "43%", "display": "flex", "flexDirection": "column", "marginLeft": "0.2rem" }}>
                                                    <div className="bd-alertBox" style={{ "border": "0.177rem solid rgb(57, 181, 74)", "marginBottom": "0", "display": "flex", "padding":"0","borderRadius":"14px", "maxHeight":"3rem"}}>
                                                        <img style={{"width":"16%","height":"auto"}} src={tick}></img>
                                                        <div style={{"display":"flex","flexDirection":"column", "padding":".2rem", "paddingLeft":"1rem"}}><span> <h3 style={{"color":"rgb(57, 181, 74)","fontWeight":"600"}}>{t("Success")}</h3> </span><span style={{"fontSize":".7rem"}}>{t("Markup Successfully Updated")}</span></div>
                                                    </div>
                                                </div>
                                            </> 
                                                :
                                                <div />
                           : !markupPopUp?<>
                           <div className="configProfit-col" style={{"maxWidth":"95%","display":"flex","flexDirection":"column","marginLeft":"0.2rem"}}>
                                    {markupStatus ? <div />
                                        : <>
                                                    <div className="bd-alertBox">
                                                        {t("fare will be recalculated with your markup and price summary updated accordingly. If you agree with new fare, you need to click on Save Markup button in order to save the fare in your file.")}
                                                    </div>
                                        <button style={{"transition": "0.3s ease","alignSelf":"end"}} className="configProfit-btn" disabled={(adultMarkupError?.length || childMarkUpValueError?.length || infantMarkUpValueError?.length || props?.disableButton ||serviceFeeError?.length ||ticketFeeError?.length)}  onClick={handleApplyMarkup}>{t("Apply")}</button>
                                        </>
                                        }
                                    </div>
                           </>
                           :
                           <>
                            <div className="configProfit-col" style={{"maxWidth":"95%","display":"flex","flexDirection":"column","marginLeft":"0.2rem"}}>
                                    {markupStatus ? <div />
                                        : <>
                                                    <div className="bd-alertBox">
                                                        {t("Do you want to save the changes made to")}{isInternalUserAutheticated?t("Add/Modify Agency Markup and Modify Intair markup and fees"):t("Add/Modify Agency Markup")}
                                                    </div>
                                                        <div style={{"display":"flex", "gap":".5rem","alignSelf":"flex-end"}}>
                                                            <button style={{ "transition": "0.3s ease", "backgroundColor": "rgb(31, 163, 70)" }} className="configProfit-btn" onClick={handleMarkupSave}>{t("Save Markup")}</button>
                                                            <button style={{ "transition": "0.3s ease" }} className="configProfit-btn" onClick={handleIntairFareCancel}>{t("Cancel")}</button>
                                                        </div>
                                        </>
                                        }
                                    </div>
                           
                           </>
                           }
                                <Dialog
                                    fullWidth
                                    maxWidth="sm"
                                    open={unSavedChangesInMarkupFlag}
                                    keepMounted
                                    // onClose={handleEquivalentFareClose}
                                    aria-labelledby="alert-dialog-slide-title"
                                    aria-describedby="alert-dialog-slide-description"
                                    className="fareType-dialog"
                                >
                                    <div style={{ "margin": "1.5rem" }}>
                                        <span style={{ "justifyItems": "center" }}>
                                            {t("You have unsaved changes in the")}{isInternalUserAutheticated ? t("Add/Modify Agency Markup and Modify Intair markup and fees") : t("Add/Modify Agency Markup")}
                                        </span>
                                        <div style={{ "display": "flex", "justifyContent": "center", "marginTop": "2rem" }}>
                                            <span style={{ "marginLeft": "1rem" }}>
                                                <button style={{ "backgroundColor": "#0055B8", "font-family": "Montserrat", "color": "#fff", "border": "0", "width": "7rem", "padding": "0.438rem 0.313rem" }} onClick={()=>dispatch(setUnSavedChangesInMarkupFlag(false))}>{t("Close")}</button>
                                            </span>
                                        </div>
                                    </div>

                                </Dialog>
                           

                    </div>
                    </div>
                    </div>                   
                </div> : <div />}
         
        </>
    );
}

export default DefaultMarkup;