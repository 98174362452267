import axios from "axios";
export default async function getApiResponse(config) {     
  let type=typeof config;
  let timeOut=59000

  let response 
  try {
    response = type == "string" ? await axios(config, { timeout: timeOut }) : await axios({ ...config, timeout: timeOut });
    
      if (response.status !== 200) {
        return null;        

      } else {         
        const data = await response.data;
        return data;
      }
    
  }catch(err){
    console.log(err);
    if (err?.code == "ECONNABORTED" || err?.message?.includes("timeout of 59000ms exceeded") || (err?.response?.status == 504) || err?.message?.includes("timeout of 57000ms exceeded")) {
      return "error";
    }    
  }     
}