
import { useDispatch } from 'react-redux';
import { setPnr, setSegment, setItenaryData, setPassengerData, setBookingDetails, setBookingPaymentPaymentStatus,
    setItinaryFaresType, setBookingDate, setBookingStatus, setSuccessPrice, setPnrCancellationStatus, setCancellationPopUp,
    setPassengerDetailsArray, setBookWithPaymentFlag, setBrandedItenaryData, setSeatMapHeader, setPnrPrice, setBrandedStatus,
    setSearchStatus, setPaymentRules, setMarkupApplied, setPassengerFraudList, setFraudUserExists, setFraudUserExistsWithPayment, 
    setFraudUserFlag, setPaymentRulesForCheque, setAdultCount,setChildrenCount, setInfantCount, setMyfilesFlag, setCreateBookingData, setPricingFareData, setSsrLoaderFlag, setSsrSaveEditFlag, setApplyButtonClickFlag, setLandCruiseBookingNumber, setAgentEmergencyNumber, setValidationOnLandCruise, setEquivalentFareLoader, setEquivalentFareValue, setRepriceFare, setRepriceFareLoader, setGetPnrResult, setBookingStatusCode, setEmailLanguage, setMarkupLoader, setFlowFrom, setCalcErrors, setTripTypeFlowFrom, setRepricePnrRadioButton, setMarkupButtonCount, setMarkupButtonCheck, setLandCruiseBookingNumberCheck, setDisableSaveButton, setCancellationConfirmation, setCancellationStatus, setCancellationBookingCode } from '../actionCreators/flightBooking';
import { setPrePaidSeatStatus } from '../actionCreators/itinenary';

export function ResetFlightBooking() {
    const dispatch = useDispatch();

    dispatch(setPnr(""));
    dispatch(setSegment([]));
    dispatch(setItenaryData({}));
    dispatch(setPassengerData({}));
    dispatch(setBookingDetails({}));
    dispatch(setBookingPaymentPaymentStatus(""));
    dispatch(setItinaryFaresType(""));
    dispatch(setBookingDate(""));
    dispatch(setBookingStatus(""));
    dispatch(setSuccessPrice(false));
    dispatch(setPnrCancellationStatus(""));
    dispatch(setCancellationPopUp(false));
    dispatch(setPassengerDetailsArray(""));
    dispatch(setBookWithPaymentFlag(false));
    dispatch(setBrandedItenaryData([]));
    dispatch(setSeatMapHeader({}));
    dispatch(setPnrPrice({}));
    dispatch(setBrandedStatus(""));
    dispatch(setSearchStatus(""));
    dispatch(setPaymentRules({}));
    dispatch(setPaymentRulesForCheque({}));
    dispatch(setMarkupApplied(false));
    dispatch(setPassengerFraudList([]));
    dispatch(setFraudUserExists(false));
    dispatch(setFraudUserExistsWithPayment(false));
    dispatch(setFraudUserFlag(false));
    dispatch(setAdultCount(0));
    dispatch(setChildrenCount(0));
    dispatch(setInfantCount(0));
    dispatch(setMyfilesFlag(false));
    dispatch(setPrePaidSeatStatus(false))
    dispatch(setCreateBookingData({}))
    dispatch(setPricingFareData(""))
    dispatch(setSsrLoaderFlag(false))
    dispatch(setSsrSaveEditFlag(true));
    dispatch(setApplyButtonClickFlag(false));
    dispatch(setLandCruiseBookingNumber(""));
    dispatch(setAgentEmergencyNumber(""));
    dispatch(setValidationOnLandCruise(true));
    dispatch(setEquivalentFareLoader(false));
    dispatch(setEquivalentFareValue({}));
    dispatch(setRepriceFare(""));
    dispatch(setRepriceFareLoader(false));
    dispatch(setGetPnrResult(""));
    dispatch(setBookingStatusCode(""));
    dispatch(setEmailLanguage("English"));
    dispatch(setMarkupLoader(false));
    dispatch(setFlowFrom(""))
    dispatch(setCalcErrors(""))
    dispatch(setTripTypeFlowFrom("MyFiles"))
    dispatch(setRepricePnrRadioButton("LowestFare"))
    dispatch(setMarkupButtonCount(0))
    dispatch(setMarkupButtonCheck(false))
    dispatch(setLandCruiseBookingNumberCheck(false))
    dispatch(setDisableSaveButton(false))
    dispatch(setCancellationConfirmation(false))
    dispatch(setCancellationStatus(""))
    dispatch(setCancellationBookingCode(""))

}