import { Dialog } from '@material-ui/core'
import React from 'react'
import { useSelector } from "react-redux";

const FlightTypeErrorHandling = ({flightTypeOptionCheck,handleBackToLogin}) => {

  const isInternalUserAutheticated = useSelector((state) => state.internalUser.isInternalUserAutheticated);
  return (
    <Dialog
        open={flightTypeOptionCheck}
        keepMounted
        onClose={handleBackToLogin}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        >
        <div className="bd-pi-contact-modal bd-alertBox bd-paxType-Validation">
            {isInternalUserAutheticated ?
            <span> A connectivity error occured.Please logout and try again.</span>
             :
            <span>A connectivity error occured.Please select Gigi again from the Flights drop down menu and try again.</span>
            }
            <div className="btn-wrapper">
            <button className="yes" onClick={handleBackToLogin}>Ok</button>
            </div>
        </div>
   </Dialog> 
  )
}

export default FlightTypeErrorHandling