import * as actionTypes from "../../actionCreators/OneWay/oneway_actionTypes";

const initialState = {
  oneway_From: "",
  onwWay_To: "",
  oneWay_Date: "",
  oneWay_Time: "",
  adult_count: 1,
  child_count: 0,
  infant_count: 0,
  one_way_air: true,
  one_way_land: false,
  one_way_cruise: false,
  Least: false,
  Basic: false,
  Multi: false,
  Near: false,
  Flexible: false,
  cabin_one_value: "",
  maximum_one_conn: "Two",
  free_one_R1: false,
  fee_one_R2: false,
  anyallin_one_R1: false,
  skyteam_one_R2: false,
  staralli_one_R3: false,
  oneworld_one_R4: false,
  oneWay_token: "",

  // validation
  flying_from_one_err: "",
  flying_to_one_err: "",
  flying_date_one_err: "",
  flying_adult_one_err: "",
};

const OneWayReducer = (state = initialState, action) => {
  switch (action.type) {
    // search

    case actionTypes.ONEWAY_FROM:
      return {
        ...state,
        oneway_From: action.val,
      };

    case actionTypes.ONEWAY_TO:
      return {
        ...state,
        onwWay_To: action.val,
      };

    // date

    case actionTypes.ONEWAY_DATE:
      return {
        ...state,
        oneWay_Date: action.val,
      };

    // time

    case actionTypes.ONEWAY_TIME:
      return {
        ...state,
        oneWay_Time: action.val,
      };

    // counter
    case actionTypes.ONE_WAY_ADULT_VALUE:
      return {
        ...state,
        adult_count: action.val,
      };

    case actionTypes.ONE_WAY_CHILD_VALUE:
      return {
        ...state,
        child_count: action.val,
      };

    case actionTypes.ONE_WAY_INFANT_VALUE:
      return {
        ...state,
        infant_count: action.val,
      };

    // checkbox

    case actionTypes.ONE_WAY_AIR:
      return {
        ...state,
        one_way_air: action.val,
      };

    case actionTypes.ONE_WAY_LAND:
      return {
        ...state,
        one_way_land: action.val,
      };

    case actionTypes.ONE_WAY_CRUISE:
      return {
        ...state,
        one_way_cruise: action.val,
      };

    case actionTypes.ONE_WAY_LEAST:
      return {
        ...state,
        Least: action.val,
      };

    case actionTypes.ONE_WAY_BASIC:
      return {
        ...state,
        Basic: action.val,
      };

    case actionTypes.ONE_WAY_MULTI:
      return {
        ...state,
        Multi: action.val,
      };

    case actionTypes.ONE_WAY_NEAR:
      return {
        ...state,
        Near: action.val,
      };

    case actionTypes.ONE_WAY_FLEXIBLE:
      return {
        ...state,
        Flexible: action.val,
      };

    // Dropdown
    case actionTypes.ONE_WAY_CABIN:
      return {
        ...state,
        cabin_one_value: action.val,
      };

    case actionTypes.ONE_WAY_MAXIMUM_CONN:
      return {
        ...state,
        maximum_one_conn: action.val,
      };

    // radio-button

    case actionTypes.ONE_WAY_FREE:
      return {
        ...state,
        free_one_R1: action.val,
      };

    case actionTypes.ONE_WAY_FEE:
      return {
        ...state,
        fee_one_R2: action.val,
      };

    case actionTypes.ONE_ANY_ALLIANCE:
      return {
        ...state,
        anyallin_one_R1: action.val,
      };

    case actionTypes.ONE_SKY_TEAM:
      return {
        ...state,
        skyteam_one_R2: action.val,
      };

    case actionTypes.ONE_STAR_ALLIANCE:
      return {
        ...state,
        staralli_one_R3: action.val,
      };

    case actionTypes.ONE_WAY_WORLD:
      return {
        ...state,
        oneworld_one_R4: action.val,
      };

    // rest

    case actionTypes.REST_ONEWAY:
      return {
        ...initialState,
      };

    case actionTypes.SET_ONEWAY_TOKEN:
      return {
        ...state,
        oneWay_token: action.val,
      };

    case actionTypes.FLYING_FROM_ONE_ERROR:
      return {
        ...state,
        flying_from_one_err: action.val,
      };

    case actionTypes.FLYING_TO_ONE_ERROR:
      return {
        ...state,
        flying_to_one_err: action.val,
      };

    case actionTypes.FLYING_DATE_ONE_ERROR:
      return {
        ...state,
        flying_date_one_err: action.val,
      };

    case actionTypes.SET_ADULT_ONE_ERROR:
      return {
        ...state,
        flying_adult_one_err: action.val,
      };

    default:
      return state;
  }
};

export default OneWayReducer;
