import { setfreeTextFareRules,setstructuredFareRules } from '../../appStore/actionCreators/penaltiesTab';
import { Penalties, TripType } from '../../utils/constant';
import { env } from '../../../src/appConfig'
import {setFareRulesLoader} from '../actionCreators/searchForm'
// Api Function for Text Fare Rules
import getApiResponse from '../../services/getApiResponse';

// Api Function for Structured Fare Rules

async function getApiResponsestrtextFareRules(configStructured,flag) {
    // CURRENTLY THIS API NOT IN SCOPE OF APPLICATION, PASSING FALSE VALUE FOR NOT CALLING API
     if(!flag) return 
    const response = await getApiResponse(configStructured);

    if (!response) {
        return null;
    } else {
        const data = await response
        return data;
    }
 
}




export const getPenaltiesData = (fareId,flowFrom) => {

      
    return async (dispatch, getState) => {
        dispatch(setFareRulesLoader(true))
        let group = [];
        let textFareRule=[];
        const tokenData = getState().initToken;
        let token = tokenData && tokenData.token;
        const quoteTokenData = getState().savedQuotes;
        const quoteToken = quoteTokenData && quoteTokenData.repriceQuote && quoteTokenData.repriceQuote.token;
        const bookingWithQuote = getState().savedQuotes.bookingWithQuote;
        const corrId = getState().searchForm.uuid;
        const tripTypeValue = getState().searchForm.tripType;
        const flightType = getState().searchForm.flightType;
        const isInternalUserAutheticated = getState().internalUser.isInternalUserAutheticated;
        const bookingChannel = isInternalUserAutheticated ? "internal" : "external";
        const penaltiesStructureData = getState().Penalaties.structuredFareRules;
        penaltiesStructureData && group.push(...penaltiesStructureData);
        const penaltiesTextFareData = getState().Penalaties.freeTextFareRules;
        penaltiesTextFareData && textFareRule.push(...penaltiesTextFareData);
        //  const token = "a119cdf6-7aea-47dc-b4df-fe740e467857";
        
        // Api For FareRules       
       
        const config = {
            method: "POST",
            url: env.REACT_APP_API_BASE_URL+`/revisedFareRules?token=${token}`,
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",               
                "Access-Control-Allow-Credentials" : true,
                correlationId: corrId,
                flightType:flightType,
                bookingChannel:bookingChannel               

            },
            data: { fareId: fareId }
        }
         

        let penaltiesResultFareRules = await getApiResponse(config);
        // dispatch(setFareRulesLoader(false))
        
        let key = fareId
        if (penaltiesResultFareRules.error) {           
            const result = {
                [key]: Penalties.STR_FARE_RULES_ERROR,
            };
            textFareRule.push(result);
            dispatch(setfreeTextFareRules(textFareRule));
        } else {
            const result = {
                [key]: penaltiesResultFareRules.fareRules,
            };
            textFareRule.push(result);
            dispatch(setfreeTextFareRules(textFareRule));
        }

    }
}