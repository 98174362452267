import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { env } from '../../../src/appConfig'
import { config } from "@fortawesome/fontawesome-svg-core";
import getApiResponse from '../../services/getApiResponse'

toast.configure();



export const getSaveIntairFare = (requestbody,from) => {
    
    return async (dispatch, getState) => {
        
        const flightType = getState().searchForm.flightType;
        const corrId = getState().searchForm.uuid;
        const isInternalUserAutheticated = getState().internalUser.isInternalUserAutheticated;
        const bookingChannel = isInternalUserAutheticated ? "internal" : "external";
        const tokenData = getState().initToken;
        const token = tokenData && tokenData.token;
        
        //Axios payload
       
        let config = {};
        if(from =="booking"){
            config={
                method: "POST",
                url : env.REACT_APP_API_BASE_URL + `/configureBookingAmount/updateConfiguredBookingAmount?token=${token}`,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": true,
                    "cache-control": "no-cache",
                    correlationId: corrId,
                    flightType:flightType,
                    bookingChannel:bookingChannel
                },
                data: requestbody 
            }
        }else{
            config={
                method: "POST",
                url : env.REACT_APP_API_BASE_URL + `/configureBookingAmount/updateConfiguredBookingAmount`,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": true,
                    "cache-control": "no-cache",
                    correlationId: corrId,
                    flightType:flightType,
                    bookingChannel:bookingChannel
                },
                data: requestbody 
            }
        }   
       
        const intairSaveValue = await getApiResponse(config);
       console.log("intairSaveValue1234",intairSaveValue)
        return intairSaveValue
        
    }
}