
import { useDispatch } from 'react-redux';
import { setItinenaryData, setLoadingData, setActiveItinenaryResultKey, setGroupItinenaryData, setSeatMapData, setTimeTaken,
    setItinenaryRowsKeys, setDetailedItinenaryRows, setCount, setSeatMapDetail, setSeat, setFlightData, setseatMapLoading,
    setPrevSeat, setEditSeat, setSaveSeat, setCarrierPaidSeatData, setRoundTripItinenaryData} from '../actionCreators/itinenary';

export function ResetItinenary() {
    const dispatch = useDispatch();

    dispatch(setItinenaryData([]));
    dispatch(setLoadingData(false));
    dispatch(setActiveItinenaryResultKey(""));
    dispatch(setGroupItinenaryData(""));
    dispatch(setRoundTripItinenaryData([]));
    dispatch(setSeatMapData([]));
    dispatch(setTimeTaken(""));
    dispatch(setItinenaryRowsKeys([]));
    dispatch(setDetailedItinenaryRows([]));
    dispatch(setCount(0));
    dispatch(setSeatMapDetail([]));
    dispatch(setSeat([]));
    dispatch(setFlightData({}));
    dispatch(setseatMapLoading(false));
    dispatch(setPrevSeat([]));
    dispatch(setEditSeat([]));
    dispatch(setSaveSeat([]));
    dispatch(setCarrierPaidSeatData(""));   
}