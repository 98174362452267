import {
    setfreeTextFareRules,
    setstructuredFareRules,
} from "../../appStore/actionCreators/penaltiesTab";
import { Penalties, TripType } from "../../utils/constant";
import { env } from "../../../src/appConfig";
import getApiResponse from "../../services/getApiResponse";
import { setFareRulesError } from "../actionCreators/searchForm";
import { toast } from "react-toastify";
import { t } from "i18next";


export const getFareRulesForPnr = (requestpayload) => {
    return async (dispatch, getState) => {

        let textFareRule = [];
        const tokenData = getState().pnrToken;
        const token = tokenData && tokenData.token;
        const corrId = getState().searchForm.uuid;
        const flightType = getState().searchForm.flightType;
        const isInternalUserAutheticated = getState().internalUser.isInternalUserAutheticated;
        const bookingChannel = isInternalUserAutheticated ? "internal" : "external";
        const tripTypeValue = getState().searchForm.tripType;
        const penaltiesTextFareData = getState().Penalaties.freeTextFareRules;
        penaltiesTextFareData && textFareRule.push(...penaltiesTextFareData);

        let url = env.REACT_APP_API_BASE_URL + `/pnrFareRules/freeText?token=${token}`;

        const configOne = {
            method: "POST",
            url: url,
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",

                "Access-Control-Allow-Credentials": true,
                correlationId: corrId,
                flightType: flightType,
                bookingChannel:bookingChannel
            },
            data: requestpayload
        };

        let penaltiesResultFareRules = await getApiResponse(configOne);
        
        let key = requestpayload.recordLocator
        if(requestpayload.fareId){
            key  = `${requestpayload.recordLocator}${requestpayload.fareId}`
        }
       
        if (penaltiesResultFareRules.error) {
            
            // const result = {
            //     [key]: Penalties.STR_FARE_RULES_ERROR,
            // };
            // textFareRule.push(result);
            toast.error(t('Your session has expired due to inactivity or a token expiration. Please log in again to continue.'), { position: toast.POSITION.TOP_CENTER, autoClose:true, progressStyle:{"transform-origin": "right"}});
            dispatch(setFareRulesError(true));
        } else {
            dispatch(setFareRulesError(false));
            const result = {
                [key]: penaltiesResultFareRules.fareRules,
            };
            textFareRule.push(result);
            dispatch(setfreeTextFareRules(textFareRule));
        }

    };
};
