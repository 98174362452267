import React from 'react'
import { useSelector } from "react-redux";
// import { monthNames } from "./../../utils/constant";
import moment from "moment";
import { DateZoneConvertion, TimeZoneConvertion } from '../../utils/utility/commenFunctions';
import { useTranslation } from 'react-i18next';

function BookingQuoteNote() {
const { t } =  useTranslation();
    const flightDetail = useSelector((state) => state.flightBooking);
  // const bookingPaymentStatus = useSelector(
  //   (state) => state.flightBooking.bookingPaymentStatus
  // );

  let d = 
    flightDetail.bookingItenaries &&
      flightDetail.bookingItenaries.optionDateToDisplay
  
  
    return (
        <div className=" quotenotehold"><div className="col-lg-12 quotenotedivhold"><div><span><i className="fa fa-exclamation-circle" aria-hidden="true"></i></span> </div><div>
            <p className="quotepara">{t("Your file will be on hold until")} {" "}
                               { moment.utc(d).format(
                                  "DD MMM  YYYY" 
                                )}                                
                               {" "} | {" "}
                                {moment.utc(d).format(
                                  "HH:mm" 
                                )} {t("Eastern Time.Only a payment made")}</p></div></div></div>
    )
}
export default BookingQuoteNote;