
import * as actionTypes from './filter_actionTypes';

export const setAirlines = (value) => {
    return {
        type: actionTypes.SET_AIRLINES,
        val: value
    };
};

export const setFilterStateCarrierbd = (value) => {
    return {
        type: actionTypes.SET_FILTER_STATE_CARRIERBD,
        val: value
    };
};

export const setFilterStateShowPopFilter = (value) => {
    return {
        type: actionTypes.SET_FILTER_STATE_SHOW_POP_FILTER,
        val: value
    };
};

export const setAirports = (value) => {
    return {
        type: actionTypes.SET_AIRPORTS,
        val: value
    };
};

export const setNoOfConnections = (value) => {
    return {
        type: actionTypes.SET_NO_OF_CONNECTIONS,
        val: value
    };
};

export const setFilterBody = (value) => {
    return {
        type: actionTypes.SET_FILTER_BODY,
        val: value
    }
}
export const setAppliedFilterFlag = (value) => {
    return {
        type: actionTypes.SET_APPLIED_FILTER_FLAG,
        val: value
    }
}
export const setAppliedFilterRecord = (value) => {
    return {
        type: actionTypes.SET_APPLIED_FILTER_RECORD,
        val: value
    }
}

export const setPriceRange = (value) => {
    return {
        type: actionTypes.SET_PRICE_RANGE,
        val: value
    };
};

export const setConnectionAirports = (value) => {
    return {
        type: actionTypes.SET_CONNECTION_AIRPORTS,
        val: value
    };
};

export const setTripDurations = (value) => {
    return {
        type: actionTypes.SET_TRIP_DURATIONS,
        val: value
    };
};

export const setConnectionDurations = (value) => {
    return {
        type: actionTypes.SET_CONNECTION_DURATIONS,
        val: value
    };
};


export const setDepartureTimes = (value) => {
    return {
        type: actionTypes.SET_DEPARTURE_TIMES,
        val: value
    };
};

export const setArrivalTimes = (value) => {
    return {
        type: actionTypes.SET_ARRIVAL_TIMES,
        val: value
    };
};

export const setAlliances = (value) => {
    return {
        type: actionTypes.SET_ALLIANCES,
        val: value
    };
};

export const setBooleanFilters = (value) => {
    return {
        type: actionTypes.SET_BOOLEANFILTERS,
        val: value
    };
};

export const setfareTypes = (value) => {
    return {
        type: actionTypes.SET_FARETYPES,
        val: value
    };
};


export const sethideChagesofAirport = (value) => {
    return {
        type: actionTypes.SET_HIDE_CHANGES_OF_AIRPORT,
        val: value
    }
}

export const sethideNearbyAirport = (value) => {
    return {
        type: actionTypes.SET_HIDE_NEAR_BY_AIRPORT,
        val: value
    }
}

export const setFilterSearchResult = (value) => {
    return {
        type: actionTypes.SET_FILTER_SEARCH_RESULT,
        val: value
    }
}

export const setFilterLoading = (value) => {
    return {
        type: actionTypes.SET_FILTER_ISLOADING,
        val: value
    }
}

export const setFilterResetItinenaryData = (value) => {
    return {
        type: actionTypes.SET_FILTER_RESET_ITINENARY_DATA,
        val: value
    }
}

export const setFilterResetItinenaryRowsKeys = (value) => {
    return {
        type: actionTypes.SET_FILTER_RESET_ITINENARY_ROWS_KEYS,
        val: value
    }
}

export const setFilterResetDetailedItinenaryRows = (value) => {
    return {
        type: actionTypes.SET_FILTER_RESET_DETAILED_ITINENARY_ROWS,
        val: value
    }
}

export const setFilterResetInitialData = (value) => {
    return {
        type: actionTypes.SET_FILTER_RESET_INITIAL_DATA,
        val: value
    }
}

export const setFilterActiveData = (value) => {
    return {
        type: actionTypes.SET_FILTER_ACTIVE_DATA,
        val: value
    }
}

export const setConnectionsActiveStatus = (value) => {
    return {
        type: actionTypes.SET_CONNECTIONS_ACTIVE_STATUS,
        val: value
    }
}

export const setConnectionAirportActiveStatus = (value) => {
    return {
        type: actionTypes.SET_CONNECTION_AIRPORT_ACTIVE_STATUS,
        val: value
    }
}

export const setTripDurationActiveStatus = (value) => {
    return {
        type: actionTypes.SET_TRIP_DURATION_ACTIVE_STATUS,
        val: value
    }
}
export const setDepartureTimeActiveStatus = (value) => {
    return {
        type: actionTypes.SET_DEPARTURE_TIME_ACTIVE_STATUS,
        val: value
    }
}

export const setArrivalTimeActiveStatus = (value) => {
    return {
        type: actionTypes.SET_ARRIVAL_TIME_ACTIVE_STATUS,
        val: value
    }
}

export const setAirlinesActiveStatus = (value) => {
    return {
        type: actionTypes.SET_AIRLINES_ACTIVE_STATUS,
        val: value
    }
}
export const setAlliancesActiveStatus = (value) => {
    return {
        type: actionTypes.SET_ALLIANCES_ACTIVE_STATUS,
        val: value
    }
}
export const setPriceRangeActiveStatus = (value) => {
    return {
        type: actionTypes.SET_PRICE_RANGE_ACTIVE_STATUS,
        val: value
    }
}
export const setFreeBaggageActiveStatus = (value) => {
    return {
        type: actionTypes.SET_FREE_BAGGAGE_ACTIVE_STATUS,
        val: value
    }
}
export const setConnectionDurationActiveStatus = (value) => {
    return {
        type: actionTypes.SET_CONNECTION_DURATION_ACTIVE_STATUS,
        val: value
    }
}
export const setHideChangesActiveStatus = (value) => {
    return {
        type: actionTypes.SET_HIDE_CHANGES_ACTIVE_STATUS,
        val: value
    }
}

export const setFilterNoResultsStatus = (value) => {

    return {
        type: actionTypes.SET_FILTER_N0_RESULTS_STATUS,
        val: value
    }

}

// SAVE FILTER DATA AVINASH
export const setAirlinesCarrierData = (value) => {
    return {
        type: actionTypes.SET_AIRLINES_CARRIER_DATA,
        val: value
    }
}
export const setairAlliancesCarrierData = (value) => {
    return {
        type: actionTypes.SET_AIR_ALLIINCE_DATA,
        val: value
    }
}
export const setPriceRangeData = (value) => {
    return {
        type: actionTypes.SET_PRICE_RANGE_FILTER,
        val: value
    }
}
export const setConnectionFilterData = (value) => {
    return {
        type: actionTypes.SET_CONNECTION_FILTER_DATA,
        val: value
    }
}
export const setConnectionAirportData = (value) => {
    return {
        type: actionTypes.SET_CONNECTION_AIRPORT_FILTER,
        val: value
    }
}
export const setConnectionDurationData = (value) => {
    return {
        type: actionTypes.SET_CONNECTION_DURATION_FILTER,
        val: value
    }
}
export const setTripDurationData = (value) => {
    return {
        type: actionTypes.SET_TRIP_DURATION_FILTER,
        val: value
    }
}
export const setFreeBaggageFilter = (value) => {
    return {
        type: actionTypes.SET_FREE_BAGGAGE_FILTER,
        val: value
    }
}

export const setDepartureTimesFilterData = (value) => {
    return {
        type: actionTypes.SET_DEPARTURE_TIMES_FILTER,
        val: value
    };
};

export const setArrivalTimesFilterData = (value) => {
    return {
        type: actionTypes.SET_ARRIVAL_TIMES_FILTER,
        val: value
    };
};


export const setHideChangesfilter = (value) => {
    return {
        type: actionTypes.HIDE_CHANGE_FILTER,
        val: value
    };
};

export const setFlightNumbersInStore = (value) => {
    return {
        type: actionTypes.SET_FLIGHT_NUMBERS_FILTER,
        val: value
    }
}

export const setFlightNumbersStatus = (value) => {
    return {
        type: actionTypes.SET_FLIGHT_NUMBER_STATUS,
        val: value
    }
}