
import { useDispatch } from 'react-redux';
import { setTodaysQuotes, setYesterdaysQuotes, setLastWeeksQuotes, setArcheivedQuotes, setQuoteDetails,
    setRepriceQuote, setClientName, setQuoteToken, setBookingWithQuote, setQuotesTabType, setShowEmailQuote } from '../actionCreators/myQuotes';

export function ResetMyQuotes() {
    const dispatch = useDispatch();

    dispatch(setTodaysQuotes(undefined));
    dispatch(setYesterdaysQuotes(undefined));
    dispatch(setLastWeeksQuotes(undefined));
    dispatch(setArcheivedQuotes(undefined));
    dispatch(setQuoteDetails(undefined));
    dispatch(setRepriceQuote(undefined));
    dispatch(setClientName(""));
    dispatch(setQuoteToken(""));
    dispatch(setBookingWithQuote(false));
    dispatch(setQuotesTabType("Today"));
    dispatch(setShowEmailQuote(false));
}