
import { useDispatch } from 'react-redux';
import { setAgentAuthetication, setAgentProfile, setOrgId, setAgencyDetails, setAgentDetails,
    setAgencyId, setAgencyGroupId, setUserId, setAgencyLocation, setAgentId, setAgentName,
    setAgentToken, setAgentCode, setAgencyName, setAgentEmailId, setProfileFee, setIternalAgentId, setAgentOldUserId, setInternalUserP6 } from '../actionCreators/agent';

export function ResetAgent() {
    const dispatch = useDispatch();

    dispatch(setAgentAuthetication(undefined));
    dispatch(setAgentProfile({}));
    dispatch(setOrgId(""));
    dispatch(setAgencyDetails({}));
    dispatch(setAgentDetails({}));
    dispatch(setAgencyId(""));
    dispatch(setAgencyGroupId(""));
    dispatch(setUserId(""));
    dispatch(setAgencyLocation({}));
    dispatch(setAgentId(""));
    dispatch(setAgentName(""));
    dispatch(setAgentToken(""));
    dispatch(setAgentCode(""));
    dispatch(setAgencyName(""));
    dispatch(setAgentEmailId(""));
    dispatch(setProfileFee({}));
    dispatch(setIternalAgentId(""));
    dispatch(setAgentOldUserId(""));
    dispatch(setInternalUserP6(""));
}