export const SET_AIRLINES = "SET_AIRLINES";  

export const SET_AIRPORTS = "SET_AIRPORTS";

export const SET_NO_OF_CONNECTIONS = "SET_NO_OF_CONNECTIONS";

export const SET_PRICE_RANGE = "SET_PRICE_RANGE";

export const SET_CONNECTION_AIRPORTS = "SET_CONNECTION_AIRPORTS";

export const SET_TRIP_DURATIONS = "SET_TRIP_DURATIONS";

export const SET_CONNECTION_DURATIONS = "SET_CONNECTION_DURATIONS";

export const SET_DEPARTURE_TIMES = "SET_DEPARTURE_TIMES";

export const SET_ARRIVAL_TIMES = "SET_ARRIVAL_TIMES";

export const SET_ALLIANCES = "SET_ALLIANCES";

export const SET_BOOLEANFILTERS =  "SET_BOOLEANFILTERS";

export const SET_FARETYPES =  "SET_FARETYPES";

export const SET_FARE_WITH_FREE_BAG_ALLOWANCE = "SET_FARE_WITH_FREE_BAG_ALLOWANCE";

export const SET_FARE_WITH_FREE_SEAT_SELECTION = "SET_FARE_WITH_FREE_SEAT_SELECTION";

export const SET_HIDE_CHANGES_OF_AIRPORT = "SET_HIDE_CHANGES_OF_AIRPORT";

export const SET_HIDE_NEAR_BY_AIRPORT = "SET_HIDE_NEAR_BY_AIRPORT";

export const SET_FILTER_SEARCH_RESULT = "setFilterSearchResult";

export const SET_FILTER_ISLOADING = "SET_FILTER_ISLOADING";

export const SET_FILTER_BODY = "setFilterBody";
export const SET_APPLIED_FILTER_FLAG = "setAppliedFilterFlag"
export const SET_APPLIED_FILTER_RECORD = "setAppliedFilterRecord"

// Filter Reset

export const SET_FILTER_RESET_ITINENARY_DATA = "setFilterResetItinenaryData"
export const SET_FILTER_RESET_ITINENARY_ROWS_KEYS= "setFilterResetItinenaryRowsKeys"
export const SET_FILTER_RESET_DETAILED_ITINENARY_ROWS= "setFilterResetDetailedItinenaryRows"
export const SET_FILTER_RESET_INITIAL_DATA = "setFilterResetInitialData"

// filter active Data Value And Count
export const SET_FILTER_ACTIVE_DATA = "setFilterActiveData"
export const SET_CONNECTIONS_ACTIVE_STATUS = "setConnectionsActiveStatus"
export const SET_CONNECTION_AIRPORT_ACTIVE_STATUS = "setConnectionAirportActiveStatus"
export const SET_TRIP_DURATION_ACTIVE_STATUS = "setTripDurationActiveStatus"
export const SET_DEPARTURE_TIME_ACTIVE_STATUS = "setDepartureTimeActiveStatus"
export const SET_ARRIVAL_TIME_ACTIVE_STATUS = "setArrivalTimeActiveStatus"
export const SET_AIRLINES_ACTIVE_STATUS = "setAirlinesActiveStatus"
export const SET_ALLIANCES_ACTIVE_STATUS = "setAlliancesActiveStatus"
export const SET_PRICE_RANGE_ACTIVE_STATUS = "setPriceRangeActiveStatus"
export const SET_FREE_BAGGAGE_ACTIVE_STATUS = "setFreeBaggageActiveStatus"
export const SET_CONNECTION_DURATION_ACTIVE_STATUS = "setConnectionDurationActiveStatus"
export const SET_HIDE_CHANGES_ACTIVE_STATUS = "setHideChangesActiveStatus"
export const SET_FILTER_N0_RESULTS_STATUS = "setFilterNoResultsStatus"

// FILTER VALUE FOR PREPOPULETED AVINASH
export const SET_AIRLINES_CARRIER_DATA = "setAirlinesCarrierData"
export const SET_AIR_ALLIINCE_DATA = "setairAlliancesCarrierData"
export const SET_PRICE_RANGE_FILTER = "setPriceRangeData"
export const SET_CONNECTION_FILTER_DATA = "setConnectionFilterData"
export const SET_CONNECTION_AIRPORT_FILTER = "setConnectionAirportData"
export const SET_CONNECTION_DURATION_FILTER = "setConnectionDurationData"
export const SET_TRIP_DURATION_FILTER = "setTripDurationData"

export const SET_FREE_BAGGAGE_FILTER = "setFreeBaggageFilter"

//filter actions for fare matrix 
export const SET_FILTER_STATE_CARRIERBD =  "setFilterStateCarrierbd"
export const SET_FILTER_STATE_SHOW_POP_FILTER = "setFilterStateShowPopFilter"

export const SET_DEPARTURE_TIMES_FILTER ='setDepartureTimesFiletr'
export const SET_ARRIVAL_TIMES_FILTER ="setArrivaliTimesFilter"
export const HIDE_CHANGE_FILTER ="setHideChangeFilter"
export const SET_FLIGHT_NUMBERS_FILTER = "setFlightNumbersInStore"
export const SET_FLIGHT_NUMBER_STATUS = "setFlightNumbersStatus"














