import { env } from '../../../src/appConfig'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setSeatMapDetail,setseatMapLoading,setSeatMapData} from '../../appStore/actionCreators/itinenary'
import getApiResponse from "../../services/getApiResponse";
toast.configure()


export const getSeatMapData = (flightId,fareId) => {
  
    console.log("call to seatmap",flightId,fareId)
  
    return async (dispatch, getState) => {
       dispatch(setseatMapLoading(false));
        let group = [];
        const seatMapArray = getState().itinenaries.seatMap;
        seatMapArray && group.push(...seatMapArray);
        const corrId = getState().searchForm.uuid;
        const flightType = getState().searchForm.flightType;
        const isInternalUserAutheticated = getState().internalUser.isInternalUserAutheticated;
        const bookingChannel = isInternalUserAutheticated ? "internal" : "external";
        const tokenData = getState().initToken;       
    
        let token =  tokenData && tokenData.token;
        
        // Axios Payload 

        const config = {
            method: "POST",
            url: env.REACT_APP_API_BASE_URL + `/seatMapping/seatMap?token=${token}`,
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                
               correlationId: corrId,
               flightType:flightType,
               bookingChannel:bookingChannel              
            },
            data: {
                "fareId": fareId,
                "flightId": flightId
              }
        }

        const seatMapData = await getApiResponse(config);
      
        if(seatMapData.results && seatMapData.results.error){
            dispatch(setSeatMapDetail(seatMapData.results.error));
            dispatch(setseatMapLoading(true));
            let key = flightId+`_`+fareId
                const result = {
                    [key] : seatMapData.results
                }
            group.push(result);
            dispatch(setSeatMapData(group));
        } else 

        if(seatMapData) {
            dispatch(setSeatMapDetail(seatMapData.results));
            dispatch(setseatMapLoading(true));
            let key = flightId+`_`+fareId
                const result = {
                    [key] : (seatMapData.results &&seatMapData.results.cabins) ?seatMapData.results:[]
                }
            group.push(result);
            dispatch(setSeatMapData(group));
        }
        return seatMapData
            
    }
}