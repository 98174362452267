import {
  SET_FARE_OPTIONS,
  SET_ACTIVE_FARE_ID,
  SET_FARE_ID_FOR_BRANDED_FARE,
  SET_ACTIVE_INDEX_FOR_BRANDED_FARE,
  SET_KEY_FOR_BRANDED_FARE_ID,
  SET_RADIO_CLICKED_STATUS,
  SET_BRANDED_ITINENARY_ID,
  SET_BRAND_CODE,
  SET_INBOUND_BRAND_DATA,
  SET_GRP_ITINENARY_FARE_ID,
} from "../storeConstants";

const initialState = {
  fareOptions: [],
  activeFareId: "",
  brandedActiveFareId: [],
  brandedActiveIndex: 0,
  keyForBrandedFareId: "",
  radioClickedStatus: [],
  brandedItinenaryId: "",
  brandCode: "",
  inboundBrandData:"",
  grpFareId:""
};

const FareOptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FARE_OPTIONS:
      return {
        ...state,
        fareOptions: action.payload,
      };

    case SET_ACTIVE_FARE_ID:
      return {
        ...state,
        activeFareId: action.payload,
      };

    case SET_FARE_ID_FOR_BRANDED_FARE:
      return {
        ...state,
        brandedActiveFareId: action.payload,
      };

    case SET_ACTIVE_INDEX_FOR_BRANDED_FARE:
      return {
        ...state,
        brandedActiveIndex: action.payload,
      };

    case SET_KEY_FOR_BRANDED_FARE_ID:
      return {
        ...state,
        keyForBrandedFareId: action.payload,
      };

    case SET_RADIO_CLICKED_STATUS:
      return {
        ...state,
        radioClickedStatus: action.payload,
      };
    case SET_BRANDED_ITINENARY_ID:
      return {
        ...state,
        brandedItinenaryId: action.payload,
      };
    case SET_BRAND_CODE:
      return {
        ...state,
        brandCode: action.payload,
      };
      case SET_INBOUND_BRAND_DATA:
        return {
          ...state,
          inboundBrandData: action.payload,
        };
        case SET_GRP_ITINENARY_FARE_ID:
          return {
            ...state,
            grpFareId: action.payload,
          };    

    default:
      return state;
  }
};

export default FareOptionsReducer;
