import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage'; // storage = localStorage or sessionStorage = sessionStorage
import { persistReducer } from 'redux-persist';

import roundTripReducer from './reducers/RoundTrip_Reducers/roundTrip-reducer';
import oneWayReducer from './reducers/OneWay_Reducers/oneWay-reducer';
import multiCityReducer from './reducers/MultiCity_Reducers/multiCity_reducers';
import OpenJawReducer from "./reducers/OpenJaw_Reducers/openJaw_reducers";
import AgentReducer from "./reducers/Agent_Reducers/agent_reducers";
import TabReducer from "./reducers/Tab_Reducers/tab-reducer";
import ItinenaryReducer  from '../appStore/reducers/itinenary';
import InitTokenReducer from '../appStore/reducers/initToken';
import PenalatiesReducer from '../appStore/reducers/penaltiesTab';
import searchFormReducer from '../appStore/reducers/searchForm';
import filterReducer from './reducers/Filter_Reducers/filter_reducers';
import FareOptionsReducer from '../appStore/reducers/fareOptions';
import FareMatrixReducer from '../appStore/reducers/fareMatrix';
import SavedQuotesReducer from '../appStore/reducers/myQuotes';
import FlightBookingReducer from './reducers/flightBooking';
import MyFilesReducer from './reducers/myFiles';
import MyFilesErrorReducer from './reducers/myFileError';

import AgentProfileReducer from './reducers/agent';
import PnrTokenReducer from './reducers/AftrePnrTokenReducer';
import InternalUserReducer from './reducers/user';
import Language_Reducers from './reducers/Language_Reducers/language_reducers';
import BlockSpaceReducer from '../appStore/reducers/Blockspace_Reducers/blockSpaceReducer';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['ctr','oneWay','mtr','otr','agt','tab','itinenaries','initToken','Penalaties','searchForm','filter','fareOptions',
              'fareMatrixResult', 'flightBooking','savedQuotes','myFile', 'agentProfile','pnrToken', 'internalUser','lang','blockSpace']
}

const rootReducer = combineReducers({
  ctr:roundTripReducer,
  oneWay:oneWayReducer,
  mtr:multiCityReducer,
  otr:OpenJawReducer,
  agt: AgentReducer,
  tab: TabReducer,
  itinenaries: ItinenaryReducer,
  initToken: InitTokenReducer,
  Penalaties: PenalatiesReducer,
  searchForm: searchFormReducer,
  filter:filterReducer,
  fareOptions:FareOptionsReducer,
  fareMatrixResult:FareMatrixReducer,
  savedQuotes: SavedQuotesReducer,
  flightBooking:FlightBookingReducer,
  myFile:MyFilesReducer,
  myFileError:MyFilesErrorReducer,
  agentProfile: AgentProfileReducer,
  pnrToken: PnrTokenReducer,
  internalUser: InternalUserReducer,
  lang:Language_Reducers,
  blockSpace:BlockSpaceReducer
});

export const appReducer = (state, action) => {
  if (action.type === 'RESET_EVERYTHING') {
      localStorage.removeItem('persist:root')
      return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

export default persistReducer(persistConfig,appReducer);
