import { SET_TOKEN, SET_TOKEN_REQUEST_DATA,SET_PNR_TOKEN ,SET_INIT_REQUEST} from '../storeConstants';

export const setInitToken = (token) => {
    return {
        type: SET_TOKEN,
        payload: token
    }
}

export const setTokenInitRequestData = (data) => {
    return {
        type: SET_TOKEN_REQUEST_DATA,
        payload: data
    }
}

export const setPnrTokenData = (token) => {
    return {
        type: SET_PNR_TOKEN,
        payload: token
    }
}