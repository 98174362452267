import React, { useState } from "react";
import bags from "../../Assets/bag-icon.svg";
import seats from "../../Assets/seat-icon.svg";
import carryOnBag from "../../Assets/carry-on-icon.svg";
import BookingSubHead from "./BookingSubHead";
import BookingItinerayInfo from "./BookingItinerayInfo";
import { useSelector } from "react-redux";
import moment from "moment";
import { getPenaltiesData } from "../../appStore/thunks/getPenaltiesData";
import { getPenaltiesDataForPNR } from '../../appStore/thunks/getPenaltiesDataForPNR';
import { useDispatch } from "react-redux";
import DetailsTab from "../searchResult/DetailsTab";
import { timeConvert } from "../../utils/utility/convertmintohours";
import { BookingFrom, BookingHead, BookingStatusCode, FlightLogo, TripType } from "../../utils/constant";
import { getFlightLogo,splitFromCapital } from "../../utils/utility/commenFunctions";
import { getPenaltiesDataForQuote } from "../../appStore/thunks/getPenaltiesDataForQuote";
import { OverlayTrigger } from "react-bootstrap";
import Popover from "react-bootstrap/Popover";
import { getRevisedFareRules } from "../../appStore/thunks/getRevisedFareRules";
import { useTranslation } from "react-i18next";
import DuffleClassOfServicePopUp from "../common/duffleClassOfServicePopUp";

function BookingItinenaryMulticity(props) {
  const [duffleClassOfServicePopUp, setDuffleClassOfServicePopUp] = useState(false);
  const [duffleCarrier,setDuffleCarrier] = useState('AC');
  
  const bookingHeader = useSelector((state) => state.searchForm.bookingHeader);
  const tripTypeValue = useSelector((state) => state.searchForm.tripType);
  const cabinValue = useSelector((state) => state.searchForm.cabin);
  const lang = useSelector( (state) => state.lang.lang)
  let adultsCount 
  let childCount 
  let infantCount

  const searchAdultCOunt = useSelector((state) => state.searchForm.passengerAdult);
  const searchChildCount = useSelector((state) => state.searchForm.passengerChildren);
  const searchInfantCount = useSelector((state) => state.searchForm.passengerInfant);
  const pnrAdultsCount = useSelector((state) => state.flightBooking.pnrAdultCount)
  const pnrChildCount = useSelector((state) => state.flightBooking.pnrChildCount)
  const pnrInfantCount = useSelector((state) => state.flightBooking.pnrInfantCount)
  const pnrFlag = useSelector((state) => state.flightBooking.pnrFlag)
  const bookingItineriesData = useSelector((state) => state.flightBooking.bookingItenaries);
  const bookingWithQuote = useSelector((state) => state.savedQuotes.bookingWithQuote);
  const bookingDetails = useSelector((state) => state.flightBooking.pnrCancellationStatus);
  const isDuffleFlow = useSelector((state)=> state.itinenaries.providerType);
  const bookingItinenariesInitial = useSelector((state) => state.searchForm.bookingItinenary);
  let bookingItinenaries;
  if(props.from === BookingFrom.CONFIRMATION){
    bookingItinenaries = bookingItinenariesInitial?.segments;
  }else{
    bookingItinenaries = bookingItinenariesInitial;
  }
  let pnrStatus = bookingDetails && bookingDetails.bookingStatusDetail && bookingDetails.bookingStatusDetail.code
  const quoteDetails = useSelector((state) => state.savedQuotes.quoteDetails);
  const saveQuotesPaxFares = quoteDetails && quoteDetails.fareDetails && quoteDetails.fareDetails.itineraryFare && quoteDetails.fareDetails.itineraryFare.paxFares;
  const paxFares = bookingWithQuote ? saveQuotesPaxFares : bookingItineriesData && bookingItineriesData.paxFares
  const fareBasisDetails =  paxFares && paxFares[0] && paxFares[0].fareBasisDetails

  if (pnrFlag) {
    adultsCount = pnrAdultsCount
    childCount = pnrChildCount
    infantCount = pnrInfantCount
  } else {
    adultsCount = searchAdultCOunt
    childCount = searchChildCount
    infantCount = searchInfantCount
  }
  
  const brandCode = useSelector((state)=>state.fareOptions.brandCode);
  const newPaxFares = brandCode?.paxFares;
  const newFareBasisDetails =  newPaxFares && newPaxFares[0] && newPaxFares[0].fareBasisDetails
  var flyingFromArray = [];
  if (props.itinenary) {
    props.itinenary.hasOwnProperty("segments") ?
      props.itinenary.segments.map((name) => {
        Object.values(name).map((data) => {

          let flightDetail = data && data.flights;
          let flyingFromCity =
            data && data.flights && data.flights[0].departureAirportCity;
          let flyingFromCityCode =
            data && data.flights && data.flights[0].departureAirport;
          let flyingToCity =
            data &&
            data.flights &&
            data.flights[flightDetail.length - 1].arrivalAirportCity;
          let flyingToCityCode =
            data &&
            data.flights &&
            data.flights[flightDetail.length - 1].arrivalAirport;

          flyingFromArray.push({
            flyingFrom: flyingFromCity + " " + "(" + flyingFromCityCode + ")",
            flyingTo: flyingToCity + " " + "(" + flyingToCityCode + ")",
          });
        });
      }) : props.itinenary.map((name) => {
        Object.values(name).map((data) => {

          let flightDetail = data && data.flights;
          let flyingFromCity =
            data && data.flights && data.flights[0].departureAirportCity;
          let flyingFromCityCode =
            data && data.flights && data.flights[0].departureAirport;
          let flyingToCity =
            data &&
            data.flights &&
            data.flights[flightDetail.length - 1].arrivalAirportCity;
          let flyingToCityCode =
            data &&
            data.flights &&
            data.flights[flightDetail.length - 1].arrivalAirport;

          flyingFromArray.push({
            flyingFrom: flyingFromCity + " " + "(" + flyingFromCityCode + ")",
            flyingTo: flyingToCity + " " + "(" + flyingToCityCode + ")",
          });
        });
      })
  }


  const dispatch = useDispatch();
  const [flightDIndex, setFlightDIndex] = useState(null);

  const details = props.itinenary && props.itinenary.hasOwnProperty("segments")?props.itinenary &&Object.values(props.itinenary.segments):props.itinenary && Object.values(props.itinenary);

  var connections = [];
  var flightNames = [];
  var flightIds = [];
  var cabinType;
  var baggage;
  var baggageInWeight;
  var seatsRemaining;
  let date = bookingHeader.ticketingTimeLimit;
  var carryOnWeight;
  var carryOnQuantity;


  details && details.map((name) => {
    Object.values(name).map((data) => {
      connections.push(data && data.flights && data.flights.length - 1);
      data && data.flights.map((value) => {
        var airPortName = value.departureAirport + "-" + value.arrivalAirport;
        flightNames.push(airPortName);
      });
    });
  });

  const baggageData = [];

  const handleFlightDToggle = (i) => {

    if (flightDIndex == i) {
      return setFlightDIndex(null);
    }
    setFlightDIndex(i);

    if(props.fareId) {
      if(bookingWithQuote){
        dispatch(getPenaltiesDataForQuote(props.fareId));
      }else{
        dispatch(getRevisedFareRules(props.fareId));
        // dispatch(getPenaltiesData(props.fareId));
      }
    }
    else if(props.recordLocator) {
      dispatch(getPenaltiesDataForPNR(props.recordLocator))
    }
  };
  let cancelledFlightCode = ["HX","UC","UN","NO","WK"]

  let totalDuration;
  var localeData = moment.updateLocale('fr', {
    monthsShort: [
      "Jan", "Fév", "Mars", "Avr", "Mai",
      "Juin", "Juil", "Août", "Sept", "Oct", "Nov", "Déc"
    ],
    weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
  });
  var Frmonts = localeData.monthsShort()
  var Frdays = localeData.weekdaysShort();
  if (lang == "en") {
    moment.locale('en')
  }
  if (lang == "fr") {
    moment.locale('fr', Frmonts);
    moment.locale('fr', Frdays);
  }

  function findBaggages(flightData, paxFares) {
    const [{ fareBasisDetails = [] }] = paxFares;
    const findFlightBaggage = fareBasisDetails.find(flight => flight.flightId === flightData.id)
    return findFlightBaggage;
  }

  const { t } = useTranslation();
  return (
    <>
      {(props.fromBookingConfirmation || props.from === BookingFrom.CONFIRMATION) ? (
        <div className="bd-itinerary-box">
          {/* {!props.isCalcultor && <BookingItinerayInfo bookingHeader={bookingHeader} from={props.from}/>} */}

          <div className="bd-itinery-detail">
            <div className="col-12 bd-itinery-box-wrapper">
              <div className="bd-itinery-box">
                {details && details.map((item, detailsIndex) => {
                  return (
                    <>
                      {Object.values(item).map((flight) => {
                        let layover = flight?.layovers;
                        let flights = flight.flights;
                        let layoverTime = 0;
                        cabinType = flight.cabin;
                        seatsRemaining = flight.seatsRemaining;
                        const baggageInfo = findBaggages(Object.values(item)[0].flights?.[0], paxFares)
                        baggageInWeight = baggageInfo?.freeBaggage?.weight;
                        baggage = baggageInfo?.freeBaggage && baggageInfo.freeBaggage?.quantity;
                        carryOnWeight = baggageInfo?.carryOnBaggage?.[0]?.weight;
                        carryOnQuantity = baggageInfo?.carryOnBaggage?.[0] && baggageInfo?.carryOnBaggage[0]?.quantity;
                        

                        return (
                          <>
                            <div className="bd-itinery-box-head">
                              <div>
                                {/* <strong>Outbound</strong> */}
                                {
                                  flyingFromArray[detailsIndex].flyingFrom
                                } - {flyingFromArray[detailsIndex].flyingTo}|{cabinType}

                              </div>
                              <div className="bd-ib-total">
                                {layover && layover.map((value) => {
                                  layoverTime += value.durationMinutes;
                                })}
                                {t("Total Duration:")}
                                {timeConvert(
                                  Object.values(item)[0].durationMinutes

                                )}
                              </div>
                            </div>

                            <div className="bd-itinery-multicity-list">
                              {flights.map((flight, index) => {

                                let intermediateBaggageData = []
                                paxFares.forEach(element => {
                                  let fareBasisDetails = element.fareBasisDetails;
                                  fareBasisDetails.forEach((elem) => {
                                    if (elem.flightId == flight.id) {
                                      let obj = {
                                        chargedBaggageDetails: elem.chargedBaggage,
                                        freeBaggageDetails: [elem.freeBaggage],
                                        paxType: element.passengerDetails.type,
                                        carryOnBaggageDetails: elem?.carryOnBaggage
                                      }
                                      intermediateBaggageData.push(obj)
                                    }
                                  })

                                });
                                baggageData.push(intermediateBaggageData);
                                // baggageData.push(flight.baggageDetails);
                                flightIds.push(flight.id);
                             
                                let carrierCode = flight && flight.carrier
                                let flightLogo = getFlightLogo(carrierCode)
                
                                layoverTime = layoverTime + 1;

                                let flightClassOfservice = fareBasisDetails && fareBasisDetails.filter(obj=>obj.flightId===flight.id)
                                let classOfService = flightClassOfservice && flightClassOfservice?.[0] && flightClassOfservice?.[0].classOfService
                                let flightStatusCode = flight?.statusDetail?.code;
                                return (
                                  <>
                                    <div className="bd-itinery-item">
                                    {flight && flight.statusDetail && flight.statusDetail.code === "UN" ?
                                      <div className="title">
                                        <img src={flightLogo} alt="flightLogo" />
                                        <OverlayTrigger
                                            trigger="hover"
                                            placement="top"
                                            delay={{ show: 100 }}
                                            overlay={
                                              <Popover className="seatView-popover">
                                                <div className="seatView-popover-row seatView-popover-amount">

                                                  <span className="airPort_name">{flight?.carrierName ? flight?.carrierName : flight?.carrierName}</span>
                                                </div>
                                              </Popover>
                                            }
                                          >
                                            <span className="bd-carrier-Name">{flight.carrier} </span>
                                          </OverlayTrigger>  
                                        {flight.number +" "}{props.from == 'confirmation' && isDuffleFlow ? <i className="fas fa-info-circle" style={{"color" : "#0055B8"}} onClick={()=>(setDuffleClassOfServicePopUp(true),setDuffleCarrier(flight.carrier)) }></i> : classOfService?classOfService:""}
                                        </div>
                                          :
                                       <div className="title">
                                           <img src={flightLogo} alt="flightLogo" />
                                           <OverlayTrigger
                                            trigger="hover"
                                            placement="top"
                                            delay={{ show: 100 }}
                                            overlay={
                                              <Popover className="seatView-popover">
                                                <div className="seatView-popover-row seatView-popover-amount">

                                                  <span className="airPort_name">{flight?.carrierName ? flight?.carrierName : flight?.carrierName}</span>
                                                </div>
                                              </Popover>
                                            }
                                          >
                                            <span className="bd-carrier-Name">{flight.carrier} </span>
                                          </OverlayTrigger>  
                                            {flight.number +" "}{props.from == 'confirmation' && isDuffleFlow ? <i className="fas fa-info-circle" style={{"color" : "#0055B8"}} onClick={()=>(setDuffleClassOfServicePopUp(true),setDuffleCarrier(flight.carrier))}></i> : classOfService?classOfService:""}
                                       </div>
                    
                                    }

                                      <div className="flight-from-to">
                                        <div className="flight-from-to-city">
                                          <div className="city">
                                            <strong>
                                              {flight.departureAirport}
                                            </strong>{" "}
                                            {moment.utc(
                                              flight.departureDateTime
                                            ).format("HH:mm")}
                                          </div>
                                          <span className="date">
                                            {moment.utc(
                                              flight.departureDateTime
                                            ).format("ddd MMM DD, YYYY")}
                                          </span>
                                        </div>
                                        <div className="flight-from-to-divider"></div>
                                        <div className="flight-from-to-city">
                                          <div className="city">
                                            <strong>
                                              {flight.arrivalAirport}
                                            </strong>
                                            {moment.utc(
                                              flight.arrivalDateTime
                                            ).format("HH:mm")}
                                          </div>
                                          <span className="date">
                                            {moment.utc(
                                              flight.arrivalDateTime
                                            ).format("ddd MMM DD, YYYY")}
                                          </span>
                                        </div>
                                      </div>
                                      {(pnrStatus === BookingStatusCode.NTC || pnrStatus === BookingStatusCode.TC || pnrStatus === BookingStatusCode.PNTC || cancelledFlightCode.includes(flightStatusCode))? 

                                        <div className="time-from-to">
                                          <span className="tktonholdFailed">{t("CANCELLED")}</span>
                                        </div>
                                        :
                                      <div className="time-from-to">
                                        {timeConvert(flight.durationMin)}
                                      </div>
                                      }
                                    </div>
                                    {layover && layover[index] ? (
                                      <div className="bd-itinery-layover">
                                        <span>{flight.arrivalAirport} </span>
                                        <span>
                                          {timeConvert(
                                            layover[index].durationMinutes
                                          ) + " Layover"}
                                        </span>
                                      </div>
                                    ) : null}
                                  </>
                                );
                              })}

                              <div className="bd-itinery-btns">
                                <div className="seatBag">
                                  {seatsRemaining && <span>
                                    <img src={seats} alt="seat" />
                                    {seatsRemaining}
                                  </span>}

                                  {(baggageInWeight || baggage) &&
                                    <span>
                                      <img src={bags} alt="seat" />
                                      {baggageInWeight > baggage
                                        ? " " + baggageInWeight + " Kg"
                                        : baggage}
                                    </span>}
                                  {(carryOnWeight || carryOnQuantity) &&
                                    <span>
                                      <img src={carryOnBag} className="carryOnSeatsBooking" alt="carryOnBag" />
                                       {(carryOnQuantity == undefined || carryOnWeight > carryOnQuantity) ? " " + carryOnWeight + " Kg" : carryOnQuantity}
                                    </span>
                                  }
                                </div>
                                {pnrStatus !== BookingStatusCode.NTC && pnrStatus !== BookingStatusCode.TC && pnrStatus !== BookingStatusCode.PNTC &&
                                <>
                                {detailsIndex === details.length - 1 ? (
                                  <div>
                                    <button
                                      onClick={() => {
                                        handleFlightDToggle(1);
                                      }}
                                    >
                                      {t("Flight Details")}
                                    </button>
                                  </div>
                                ) : null}
                               </> 
                               }
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </>
                  );
                })}

                <div
                  className={
                    flightDIndex === 1
                      ? "flight_tabs_show bg-white"
                      : "flight_tabs_none"
                  }
                >
                  <DetailsTab
                    flightdata={details}
                    baggagefares={baggageData}
                    flightName={flightNames}
                    fareId={props.fareId}
                    recordLocator={props.recordLocator}
                    paxFare={paxFares}
                    isDuffleFlow={isDuffleFlow}
                    flowFrom={props.from}
                    baggage={Object.values(bookingItinenaries[0])[0]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bd-itinerary-sec">
          <BookingSubHead
            title={t(BookingHead.ITINENARY)}
          /><div className="one_line">
          <ul className="bd-trip-info">
            
            <li>{tripTypeValue === TripType.MULTICITY && t("MultiCity")}</li>
            <li>{cabinValue && t(splitFromCapital(cabinValue))} {t("Class")}</li>
            <li>
              {adultsCount} {t("Adult")} {childCount} {t("Child")} {infantCount} {t("Infant")}
            </li>
           
          </ul>
           <p>{brandCode.brandName} </p>
          </div>
          <div className="bd-itinerary-box">
            {/* <BookingItinerayInfo bookingHeader={bookingHeader} from={props.from}/> */}

            <div className="bd-itinery-detail">
              <div className="col-12 bd-itinery-box-wrapper">
                <div className="bd-itinery-box">
                  {details.map((item, detailsIndex) => {
                    return (
                      <>
                        {Object.values(item).map((flight) => {
                          let layover = flight?.layovers;
                          let flights = flight.flights;
                          let layoverTime = 0;
                          cabinType = flight.cabin;
                          seatsRemaining = flight.seatsRemaining;
                          let finalPaxFares = newPaxFares ? newPaxFares : paxFares;
                          const baggageInfo = findBaggages(Object.values(item)[0].flights?.[0], finalPaxFares)
                          baggageInWeight = baggageInfo?.freeBaggage?.[0]?.weight;
                          baggage = baggageInfo?.freeBaggage && baggageInfo.freeBaggage?.[0]?.quantity;
                          let classOfService1 = flight.classOfService
                          let cabin = flight && flight?.cabin
                          if(bookingWithQuote){
                            classOfService1 = fareBasisDetails
                          }
                          carryOnWeight = baggageInfo?.carryOnBaggage?.[0]?.weight;
                          carryOnQuantity = baggageInfo?.carryOnBaggage?.[0] && baggageInfo?.carryOnBaggage[0]?.quantity;

                          return (
                            <>
                              <div className="bd-itinery-box-head">
                                <div>
                                  {/* <strong>Outbound</strong> */}
                                  {
                                    flyingFromArray && flyingFromArray[detailsIndex].flyingFrom
                                  } - {flyingFromArray && flyingFromArray[detailsIndex].flyingTo}| {cabin}

                                </div>
                                <div className="bd-ib-total">
                                  {layover && layover.map((value) => {
                                    layoverTime += value.durationMinutes;
                                  })}
                                  {t("Total Duration:")}
                                  {timeConvert(
                                    Object.values(item)[0].durationMinutes

                                  )}
                                </div>
                              </div>

                              <div className="bd-itinery-multicity-list">
                                {flights.map((flight, index) => {
                                  let intermediateBaggageData = []
                                  finalPaxFares.forEach(element => {
                                    let fareBasisDetails = element.fareBasisDetails;
                                    fareBasisDetails.forEach((elem) => {
                                      if (elem.flightId == flight.id) {
                                        let obj = {
                                          chargedBaggageDetails: elem.chargedBaggage,
                                          freeBaggageDetails: elem.freeBaggage,
                                          paxType: element.passengerDetails.type,
                                          carryOnBaggageDetails: elem?.carryOnBaggage
                                        }
                                        intermediateBaggageData.push(obj)
                                      }
                                    })
  
                                  });
                                  baggageData.push(intermediateBaggageData);
                                  flightIds.push(flight.id);
                                  
                                  let carrierCode = flight && flight.carrier
                                  let flightLogo = getFlightLogo(carrierCode)
                  
                                  layoverTime = layoverTime + 1;

                                  let flightClassOfservice = newFareBasisDetails && newFareBasisDetails.filter(obj=>obj.flightId===flight.id)
                                  let classOfService =  flightClassOfservice && flightClassOfservice[0] && flightClassOfservice[0].classOfService

                                  return (
                                    <>
                                      <div className="bd-itinery-item">
                                        <div className="title">
                                          <img
                                            src={flightLogo}
                                            alt="flightLogo"
                                          />
                                          <OverlayTrigger
                                            trigger="hover"
                                            placement="top"
                                            delay={{ show: 100 }}
                                            overlay={
                                              <Popover className="seatView-popover">
                                                <div className="seatView-popover-row seatView-popover-amount">

                                                  <span className="airPort_name">{flight?.carrierName ? flight?.carrierName : flight?.carrierName}</span>
                                                </div>
                                              </Popover>
                                            }
                                          >
                                            <span className="bd-carrier-Name">{flight.carrier} </span>
                                          </OverlayTrigger>    
                                          { flight.number+" "}{props.from == 'confirmation' && isDuffleFlow ? <i className="fas fa-info-circle" style={{"color" : "#0055B8"}} onClick={()=>(setDuffleClassOfServicePopUp(true),setDuffleCarrier(flight.carrier))}></i> : classOfService?classOfService:""}
                                        </div>

                                        <div className="flight-from-to">
                                          <div className="flight-from-to-city">
                                            <div className="city">
                                              <strong>
                                                {flight.departureAirport}
                                              </strong>{" "}
                                              {moment.utc(
                                                flight.departureDateTime
                                              ).format("HH:mm")}
                                            </div>
                                            <span className="date">
                                              {moment.utc(
                                                flight.departureDateTime
                                              ).format("ddd MMM DD, YYYY")}
                                            </span>
                                          </div>
                                          <div className="flight-from-to-divider"></div>
                                          <div className="flight-from-to-city">
                                            <div className="city">
                                              <strong>
                                                {flight.arrivalAirport}
                                              </strong>
                                              {moment.utc(
                                                flight.arrivalDateTime
                                              ).format("HH:mm")}
                                            </div>
                                            <span className="date">
                                              {moment.utc(
                                                flight.arrivalDateTime
                                              ).format("ddd MMM DD, YYYY")}
                                            </span>
                                          </div>
                                        </div>

                                        <div className="time-from-to">
                                          {timeConvert(flight.durationMin)}
                                        </div>
                                      </div>
                                      {layover && layover[index] ? (
                                        <div className="bd-itinery-layover">
                                          <span>{flight.arrivalAirport} </span>
                                          <span>
                                            {timeConvert(
                                              layover[index].durationMinutes
                                            ) + " Layover"}
                                          </span>
                                        </div>
                                      ) : null}
                                    </>
                                  );
                                })}

                                <div className="bd-itinery-btns">
                                  <div className="seatBag">
                                    {seatsRemaining && <span>
                                      <img src={seats} alt="seat" />
                                      {seatsRemaining}
                                    </span>}

                                    {(baggageInWeight || baggage) &&
                                      <span>
                                        <img src={bags} alt="seat" />
                                        {baggageInWeight > baggage
                                          ? " " + baggageInWeight + " Kg"
                                          : baggage}
                                      </span>}
                                    {(carryOnWeight || carryOnQuantity) &&
                                      <span>
                                        <img src={carryOnBag} className="carryOnSeatsBooking" alt="carryOnBag" />
                                        {(carryOnQuantity == undefined || carryOnWeight > carryOnQuantity) ? " " + carryOnWeight + " Kg" : carryOnQuantity}
                                      </span>
                                    }
                                  </div>
                                  {pnrStatus !== BookingStatusCode.NTC && pnrStatus !== BookingStatusCode.TC && pnrStatus !== BookingStatusCode.PNTC &&                               
                                  <>
                                  {detailsIndex === details.length - 1 ? (
                                    <div>
                                      <button
                                        onClick={() => {
                                          handleFlightDToggle(1);
                                        }}
                                      >
                                        {t("Flight Details")}
                                      </button>
                                    </div>
                                  ) : null}
                                  </>
                                  }
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </>
                    );
                  })}

                  <div
                    className={
                      flightDIndex === 1
                        ? "flight_tabs_show bg-white"
                        : "flight_tabs_none"
                    }
                  >
                    <DetailsTab
                      flightdata={details}
                      baggagefares={baggageData}
                      flightName={flightNames}
                      fareId={props.fareId}
                      recordLocator={props.recordLocator}
                      paxFare={paxFares}
                      isDuffleFlow={isDuffleFlow}
                      flowFrom={props.from}
                      baggage={Object.values(bookingItinenaries[0])[0]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <DuffleClassOfServicePopUp open={duffleClassOfServicePopUp} handleClose={()=>setDuffleClassOfServicePopUp(false)} carrier={duffleCarrier}/>
    </>
  );
}

export default BookingItinenaryMulticity;
