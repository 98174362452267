import { React ,useState} from 'react'
import { useSelector } from "react-redux";
import { TripType } from '../../utils/constant';

import FlightDetailsTab from './FlightDetailsTab';
import BaggageTab from './BaggageTab';
import PenaltiesTab from './PenaltiesTab'

import {
  Tabs,
  Tab,
} from "react-bootstrap";
import FlightDetailsTabMultiCity from './FlightDetailsTabMultiCity';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

function Details(props) {
  const[tabKey,setTabKey]= useState("FlightDetails")
  
  const fareId = props.fareId
  let flowFrom = props.flowFrom
  
  const tripTypeValue = useSelector((state) => state.searchForm.tripType);
  const tripTypeFromApi = useSelector((state) => state.searchForm.tripTypeFromApi);
  let isDuffleFlow = useSelector((state)=> state.itinenaries.providerType);
const handleSelect = (k) => {
  setTabKey(k)
}

const { t } = useTranslation();
  return (
    <>

      <Tabs defaultActiveKey="FlightDetails" id="flightdetails" className='flight_tabs' onSelect={(k) =>handleSelect(k)}>
        <Tab eventKey="FlightDetails" title={t("Flight Details")} className="tabs  b-0">

          <Tabs defaultActiveKey="inner-tabs-0" id="inner-flightdetail" className='inner-flight_tabs'>
            <Tab eventKey="inner-tabs-0" className="tabs  b-0">



              {tripTypeValue === TripType.MULTICITY ? <FlightDetailsTabMultiCity data={props.flightdata} paxFare={props.paxFare}/> : <FlightDetailsTab flowFrom={props.flowFrom} data={props.flightdata} paxFare={props.paxFare} finalCabinName={props?.finalCabinName}/>}


            </Tab>
          </Tabs>
        </Tab>

        {(flowFrom ==="search" ? props.providerName !=="NDC" : !isDuffleFlow) && <Tab eventKey="Baggage" title={t("Baggage")} className="tabs b-0 baggage-tabs px-0">

          <Tabs defaultActiveKey="inner-tabs-0" id="inner-Baggage" className='inner-flight_tabs'>
            <Tab eventKey="inner-tabs-0" className="tabs  b-0">
              {tabKey === "Baggage" &&
              <BaggageTab uiClass='col-md-12 p-0' baggagefares={props.baggagefares} flightName={props.flightName} flightDetails={props.flightdata} flowFrom={props.flowFrom} baggage={props.baggage}/>
               }
            </Tab>
            <Tab eventKey="inner-tabs-0-1" className="tabs  b-0">
            </Tab>
            <Tab eventKey="inner-tabs-0-2" className="tabs  b-0">
            </Tab>
          </Tabs>

        </Tab>}
        {((props.fareId && flowFrom ==="search" && props.providerName !=="NDC") || (flowFrom ==="booking" && !props.isDuffleFlow)) && <Tab eventKey="Penalties" title={t("Penalties")} className="tabs flight_tabsPenalties  b-0 px-0">
          <Tabs defaultActiveKey="inner-tabs-0" id="inner-seat" className='inner-flight_tabs'>
            <Tab eventKey="inner-tabs-0" className="tabs  b-0">
            {tabKey === "Penalties" &&
              <PenaltiesTab uiClass='col-md-12 p-0' flightName={props.flightName} flightDetails={props.flightdata} fareId={fareId} from={props.from} flowFrom ={flowFrom}/>
              }
              </Tab>
            <Tab eventKey="inner-tabs-0-1" className="tabs  b-0">
            </Tab>
            <Tab eventKey="inner-tabs-0-2" className="tabs  b-0">
            </Tab>
          </Tabs>
        </Tab>}
        {props.recordLocator &&  !isDuffleFlow &&  <Tab eventKey="Penalties" title={t("Penalties")} className="tabs flight_tabsPenalties  b-0 px-0">
          <Tabs defaultActiveKey="inner-tabs-0" id="inner-seat" className='inner-flight_tabs'>
            <Tab eventKey="inner-tabs-0" className="tabs  b-0">
            {tabKey === "Penalties" &&
              <PenaltiesTab uiClass='col-md-12 p-0' flightName={props.flightName} flightDetails={props.flightdata} recordLocator={props.recordLocator} from={props.from} flowFrom ={flowFrom}/>
              }
              </Tab>
            <Tab eventKey="inner-tabs-0-1" className="tabs  b-0">
            </Tab>
            <Tab eventKey="inner-tabs-0-2" className="tabs  b-0">
            </Tab>
          </Tabs>
        </Tab>}



      </Tabs>
    </>
  )
}

export default Details;
