export const SET_ROUNDTRIP_FROM = 'SET_ROUNDTRIP_FROM'; 
export const SET_ROUNDTRIP_TO = 'SET_ROUNDTRIP_TO'; 

export const SET_ROUNDTRIP_DATE_FROM = "SET_ROUNDTRIP_DATE_FROM";
export const SET_ROUNDTRIP_DATE_TO = "SET_ROUNDTRIP_DATE_TO";

export const SET_ROUNDTRIP_TIME_FROM = "SET_ROUNDTRIP_TIME_FROM";
export const SET_ROUNDTRIP_TIME_TO = "SET_ROUNDTRIP_TIME_TO";

export const ADULT_VALUE  = "ADULT_VALUE";
export const CHILD_VALUE  = "CHILD_VALUE";
export const INFANT_VALUE = "INFANT_VALUE";

export const SET_CABIN = "SET_CABIN";

export const SEARCH_NEARBY_AIRPORTS = "SEARCH_NEARBY_AIRPORTS";
export const FLEXI_DATE = "FLEXI_DATE";
export const AIR = "AIR";
export const LAND = "LAND";
export const CRUISE = "CRUISE";
export const AT_LEAST_FREE_BAG = "AT_LEAST_FREE_BAG";
export const EXCLUDE_BASIC_ECONOMY_FARES = "EXCLUDE_BASIC_ECONOMY_FARES";
export const EXCLUDE_MULTI_TICKET_PRICING = "EXCLUDE_MULTI_TICKET_PRICING";

export const FREE = "FREE";
export const FEE = "FEE";

export const ANY_ALLIANCE = "ANY_ALLIANCE";
export const SKY_TEAM = "SKY_TEAM";
export const STAR_ALLIANCE = "STAR_ALLIANCE";
export const ONEWORLD = "ONEWORLD";

export const MAXIMUM_CONN = "MAXIMUM_CONN";

export const REST_ROUNDTRIP = "REST_ROUNDTRIP"

// Set Roundtrip Token

export const SET_ROUNDTRIP_TOKEN = "SET_ROUNDTRIP_TOKEN" 

// validation 
export const SET_FLYING_FROM_ERROR = "SET_FLYING_FROM_ERROR"
export const FLYING_TO_ERROR = "FLYING_TO_ERROR"
export const FLYING_DATE_FROM_ERROR = "FLYING_DATE_FROM_ERROR"
export const FLYING_DATE_TO_ERROR = "FLYING_DATE_TO_ERROR"
export const SET_ADULT_ERROR = "SET_ADULT_ERROR"

// uuid

export const SET_UUID = "SET_UUID"

// Set roundTripData

export const SET_ROUNDTRIP_DATA = "SET_ROUNDTRIP_DATA" 