import * as actionTypes from '../../actionCreators/Agent/agent_actionTypes';

const initialState = {
	agent_profile:"",
	agent_profile_input:"",
	agent_id:"",
	agent_enable:false,
	agencyProfile:{}
}


const AgentReducer = (state = initialState, action) => {
	switch (action.type) {

		case actionTypes.AGENT_PROFILE_CHANGE:
		    
		   return {
            ...state,
            agent_profile:action.val,
		

		   }

		   case actionTypes.AGENT_PROFILE_INPUT_CHANGE:
		    
		   return {
            ...state,
            agent_profile_input:action.val,
		

		   }

		   case actionTypes.AGENT_PROFILE_ID:
		    
			return {
			 ...state,
			 agent_id:action.val,
		 
 
			}

		   case actionTypes.AGENT_PROFILE_ENABLE:
		    
			return {
			 ...state,
			 agent_enable:action.val,
		 
 
			}


			case actionTypes.AGENT_PROFILE_CHANGE_ONE:
		    
		   return {
            ...state,
            agent_profile:action.val,
		

		   }

		   case actionTypes.AGENT_PROFILE_INPUT_CHANGE_ONE:
		    
		   return {
            ...state,
            agent_profile_input:action.val,
		

		   }

		   case actionTypes.AGENT_PROFILE_ID_ONE:
		    
			return {
			 ...state,
			 agent_id:action.val,
		 
 
			}

		   case actionTypes.AGENT_PROFILE_ENABLE_ONE:
		    
			return {
			 ...state,
			 agent_enable:action.val,
		 
 
			}
			case actionTypes.AGENT_PROFILE_DETAIL:
		    
				return {
				 ...state,
				 agencyProfile:action.val,
			 
	 
				}

			
		  
		  
         
		default:

			return state;

	}
};



export default AgentReducer;