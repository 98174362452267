import { SET_MYFILESERROR } from '../storeConstants';

const initialState = {
    myFilesDataError: false
};


const MyFilesErrorReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MYFILESERROR:
            return {
                ...state,
                myFilesDataError: action.payload
            }

        default:
            return state;
    }
}

export default MyFilesErrorReducer;
