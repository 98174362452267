import * as actionTypes from './blockSpace_actionTypes';

export const setBlockSpaceList = (value) => {
    return {
        type: actionTypes.SET_BLOCK_SPACE_LIST,
        val: value
    };
};

export const setSelectedBlock = (value) =>{
    return {
        type: actionTypes.SET_SELECTED_BLOCK,
        val:value
    }
}

export const setSelectedNoOfPassenger = (value) =>{
    return {
        type: actionTypes.SET_SELECTED_NO_OF_PASSENGER,
        val: value
    }
}

export const setMarkupCheckFlag = (value) =>{
    return {
        type: actionTypes.SET_MARKUP_CHECK_FLAG,
        val: value
    }
}

export const setMarkupValue = (value) =>{
    return {
        type: actionTypes.SET_MARKUP_VALUE,
        val: value
    }
}

export const setTravelerInfo = (value) =>{
    return {
        type: actionTypes.SET_TRAVELER_INFO,
        val: value
    }
}

export const setBookingId = (value) =>{
    return {
        type: actionTypes.SET_BOOKING_ID,
        val: value
    }
}

export const setTotalCostPrice = (value)=>{
    return{
        type: actionTypes.SET_TOTAL_COST_PRICE,
        val: value
    }
}

export const setTotalSalesPrice = (value)=>{
    return{
        type: actionTypes.SET_TOTAL_SALES_PRICE,
        val: value
    }
}

export const setPaymentStatus = (value)=>{
    return{
        type: actionTypes.SET_PAYMENT_STATUS,
        val: value
    }
}

export const setAllBookings = (value)=>{
    return{
        type: actionTypes.SET_ALL_BOOKINGS,
        val: value
    }
}

export const setPayment = (value)=>{
    return{
        type: actionTypes.SET_PAYMENT,
        val: value
    }
}

export const setAutoEmail = (value)=>{
    return {
        type: actionTypes.SET_AUTO_EMAIL,
        val: value
    }
}

export const setFOP = (value)=>{
    return {
        type: actionTypes.SET_FOP,
        val: value
    }
}

export const setEmailDropdown = (value)=>{
    return { 
        type: actionTypes.SET_EMAIL_DROPDOWN,
        val: value
    }
}

export const setEmailType = (value)=>{
    return { 
        type: actionTypes.SET_EMAIL_TYPE,
        val: value
    }
}

export const setEmailTypeValue = (value)=>{
    return { 
        type: actionTypes.SET_EMAIL_TYPE_VALUE,
        val: value
    }
}