import { setBookingDetails, setPassengerData, setItenaryData, setBookingDate, setBookingStatus, 
  setPnrCancellationStatus,setMarkupApplied,setAdultCount,setInfantCount,setChildrenCount,setMyfilesFlag, setSsrLoaderFlag, setPricingFareData, setLandCruiseBookingNumber, setAgentEmergencyNumber, setGetPnrResult, setEmailLanguage,setDisableSaveButton, setAllowSeatPayment, setRegularRefundCancellationDetails, setProviderType, setInitialItineraryFare, setNonGigiPNR } from "../../appStore/actionCreators/flightBooking"
import {
  setBookingHeader, setFlyingFrom, setFlyingTo, setFlyingFromCity, setFlyingToCity, setTripType, setBookingItinenary, setProvideId,
 setAgencyAgentFieldFlag, setPromotionData, setPaymentDetailsAddons, setUUID, setOpenJawFlyingFrom_B, setOpenJawFlyingFromCity_B, setOpenJawFlyingTo_B, setOpenJawFlyingToCity_B, setMultiCityFlyingFromM, setMultiCityFlyingToM, setFlightType
} from "../actionCreators/searchForm";
import { setIsEmailMandatory, setIsPassportMandatory, setIsMobileMandatory} from "../actionCreators/itinenary"
import { setBrandCode } from "../actionCreators/fareOption"
import { env } from '../../../src/appConfig'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fareTypeConstant, PassengerType } from '../../utils/constant';
import { getEquivalentPublishFareForPnr } from "./getEquivalentPublishFareForPnr";
import getApiResponse from "../../services/getApiResponse";
import { v4 as uuidv4 } from "uuid";
import { setfreeTextFareRules } from "../actionCreators/penaltiesTab";
import { TripType } from "../../utils/constant";
toast.configure()



export const getPnrDetails = (requestPayload, isEdit) => {


  return async (dispatch, getState) => {
    const corrId = getState().searchForm.uuid;
    const flightType = getState().searchForm.flightType;
    const isInternalUserAutheticated = getState().internalUser.isInternalUserAutheticated;
    const bookingChannel = isInternalUserAutheticated ? "internal" : "external";
    const flowFrom = getState().flightBooking.flowFrom;
    const tripType = getState().searchForm.tripType;
    const tripTypeFlowFrom = getState().flightBooking.tripTypeFlowFrom;
    //Axios payload

    const config = {
      method: "POST",
      url : env.REACT_APP_API_BASE_URL + `/booking/process/getBookingDetails`,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",

        "Access-Control-Allow-Credentials": true,
        "cache-control": "no-cache",
        correlationId: corrId,
        flightType:flightType,
        bookingChannel:bookingChannel       

      },
      data:
        requestPayload
    }
    config.data.IsStateful=true;

    dispatch(setRegularRefundCancellationDetails({}))
    const bookingResult = await getApiResponse(config);
    if(bookingResult=="error"){
      return "error";
    }

    if (bookingResult?.error) {
      if(flowFrom=='Calculator'){

        return {...bookingResult.error,isError:true};
      }else{
        toast.error(bookingResult.error && bookingResult.error.message, { position: toast.POSITION.TOP_CENTER, autoClose: true, progressStyle: { "transform-origin": "right" } });
        dispatch(setAgencyAgentFieldFlag(true));
      }
    } else if (bookingResult) {

      if(bookingResult.regularRefundCancellationDetails){
        dispatch(setRegularRefundCancellationDetails(bookingResult.regularRefundCancellationDetails))
      }

      if (isEdit) {


        dispatch(setPassengerData(bookingResult.bookingDetails && bookingResult.bookingDetails.passengers && bookingResult.bookingDetails.passengers));
        //dispatch(setBookingDetails(bookingResult.bookingDetails))
        dispatch(setSsrLoaderFlag(false))
      } else {

        if(flowFrom=='Calculator' && bookingResult && bookingResult.bookingCorrelationId== undefined){
          dispatch(setNonGigiPNR(true))
          let passengerData=bookingResult.bookingDetails && bookingResult.bookingDetails.passengers;

          for(let i=0;i<passengerData.length;i++){
            if(passengerData[i].paxType == "Child"){
              bookingResult.bookingDetails.passengers[i].paxType = "Adult"
            }
          }

        let passengers = bookingResult.bookingDetails && bookingResult.bookingDetails.passengers;
        let adultData = passengers.filter(obj => obj.paxType === PassengerType.ADULT)
      let childData = passengers.filter(obj => obj.paxType === PassengerType.CHILD)
      let infantData = passengers.filter(obj => obj.paxType === PassengerType.INFANTINLAP)
        let adultCount = adultData.length
        let childCount = childData.length
        let infantCount = infantData.length
        let segments= bookingResult.bookingDetails &&bookingResult.bookingDetails.segments && bookingResult.bookingDetails.segments[0]
        let flight = segments && Object.values(segments)
        let fareBasisDetails  = flight && flight[0] && flight[0].flights && flight[0].flights[0] && flight[0].flights[0].fareBasisDetails
        let brandData = fareBasisDetails && fareBasisDetails[0] && fareBasisDetails[0].brand
          let emailLanguage =
            bookingResult &&
              bookingResult.travelAgent &&
              bookingResult.travelAgent.edocumentInLanguage !== undefined
              ? (bookingResult.travelAgent.edocumentInLanguage === 'An' ||
                bookingResult.travelAgent.edocumentInLanguage === 'En')
                ? "English"
                : bookingResult.travelAgent.edocumentInLanguage === 'Fr'
                  ? "French"
                  : "English"
              : "English";
        let corrId = bookingResult && bookingResult.bookingCorrelationId;
        if(corrId === undefined || corrId === "undefined" || corrId === ""){
          corrId = uuidv4();
        }
        dispatch(setGetPnrResult(bookingResult))
        dispatch(setLandCruiseBookingNumber(bookingResult && bookingResult?.bookingDetails?.nonAirConfirmationNumber));
        dispatch(setPricingFareData(""))
        dispatch(setMyfilesFlag(true))
        dispatch(setAdultCount(adultCount))
        dispatch(setChildrenCount(childCount))
        dispatch(setInfantCount(infantCount))
        dispatch(setBookingStatus(bookingResult.bookingStatusDetail))
        dispatch(setBookingDetails(bookingResult.bookingDetails))
        dispatch(setBookingDate(bookingResult.bookingDate))
        dispatch(setItenaryData(bookingResult.bookingDetails.itineraryFare))
        // dispatch(setInitialItineraryFare(bookingResult.bookingDetails.itineraryFare))
        dispatch(setPnrCancellationStatus(bookingResult))
        dispatch(setfreeTextFareRules([]));
        if (flowFrom =='Calculator'){
          if(bookingResult?.tripType == TripType.MULTICITY){
            dispatch(setTripType(TripType.MULTICITY))
          } else if(bookingResult?.bookingDetails?.segments?.length == 1){
            dispatch(setTripType(TripType.ONEWAY))
          }else if(bookingResult?.bookingDetails?.segments?.length == 2){
            if(bookingResult?.tripType==TripType.OPENJAW){
              dispatch(setTripType(TripType.OPENJAW))
            }else{
              dispatch(setTripType(TripType.ROUNDTRIP))
            }
          } else{
            dispatch(setTripType(bookingResult?.tripType))
          }
        }else if(tripTypeFlowFrom == "MyFiles"){
          dispatch(setTripType(bookingResult.tripType))
        }else{
          dispatch(setTripType(tripType))
        }        
        dispatch(setBookingItinenary(bookingResult.bookingDetails))
        dispatch(setProvideId(bookingResult && bookingResult.providerId))
        dispatch(setPromotionData(bookingResult && bookingResult.promotion));
        dispatch(setPaymentDetailsAddons(bookingResult && bookingResult.paymentsForAddOns))
        dispatch(setAgentEmergencyNumber(bookingResult && bookingResult.travelAgent && bookingResult?.travelAgent?.emergencyPhone?.number));
        dispatch(setEmailLanguage(emailLanguage))
        dispatch(
          setBrandCode({
            brandCode: brandData && brandData.code,
            brandName: brandData && brandData.brandName,
          })
        );
        dispatch(setUUID(corrId))
        // dispatch(setDisableSaveButton(!(bookingResult?.bookingDetails?.isSavePQAllowedForCalculator)))
        }else{

        let passengers = bookingResult?.bookingDetails && bookingResult.bookingDetails?.passengers;
        let adultData = passengers?.filter(obj => obj.paxType === PassengerType.ADULT)
      let childData = passengers?.filter(obj => obj.paxType === PassengerType.CHILD)
      let infantData = passengers?.filter(obj => obj.paxType === PassengerType.INFANTINLAP)
        let adultCount = adultData?.length
        let childCount = childData?.length
        let infantCount = infantData?.length
        let segments= bookingResult.bookingDetails &&bookingResult.bookingDetails.segments && bookingResult.bookingDetails.segments[0]
        let flight = segments && Object.values(segments)
        let fareBasisDetails  = flight && flight[0] && flight[0].flights && flight[0].flights[0] && flight[0].flights[0].fareBasisDetails
        let brandData = fareBasisDetails && fareBasisDetails[0] && fareBasisDetails[0].brand
          let emailLanguage =
            bookingResult &&
              bookingResult.travelAgent &&
              bookingResult.travelAgent.edocumentInLanguage !== undefined
              ? (bookingResult.travelAgent.edocumentInLanguage === 'An' ||
                bookingResult.travelAgent.edocumentInLanguage === 'En')
                ? "English"
                : bookingResult.travelAgent.edocumentInLanguage === 'Fr'
                  ? "French"
                  : "English"
              : "English";

        let corrId = bookingResult && bookingResult.bookingCorrelationId;
        if(corrId === undefined || corrId === "undefined" || corrId === ""){
          corrId = uuidv4();
        }
        let flightTypeRes = bookingResult?.flightType;
        if(flightTypeRes){
          dispatch(setFlightType(flightTypeRes))
        }
        dispatch(setGetPnrResult(bookingResult))
        dispatch(setLandCruiseBookingNumber(bookingResult && bookingResult?.bookingDetails?.nonAirConfirmationNumber));
        dispatch(setPricingFareData(""))
        dispatch(setMyfilesFlag(true))
        dispatch(setAdultCount(adultCount))
        dispatch(setChildrenCount(childCount))
        dispatch(setInfantCount(infantCount))
        dispatch(setBookingStatus(bookingResult.bookingStatusDetail))
        dispatch(setBookingDetails(bookingResult.bookingDetails))
        dispatch(setBookingDate(bookingResult.bookingDate))
        dispatch(setItenaryData(bookingResult.bookingDetails.itineraryFare))
        // dispatch(setInitialItineraryFare(bookingResult.bookingDetails.itineraryFare))
        dispatch(setPnrCancellationStatus(bookingResult))
        dispatch(setfreeTextFareRules([]));
        if (flowFrom =='Calculator'){
          if(bookingResult?.tripType == TripType.MULTICITY){
            dispatch(setTripType(TripType.MULTICITY))
          } else if(bookingResult?.bookingDetails?.segments?.length == 1){
            dispatch(setTripType(TripType.ONEWAY))
          }else if(bookingResult?.bookingDetails?.segments?.length == 2){
            if(bookingResult?.tripType==TripType.OPENJAW){
              dispatch(setTripType(TripType.OPENJAW))
            }else{
              dispatch(setTripType(TripType.ROUNDTRIP))
            }
          } else{
            dispatch(setTripType(bookingResult?.tripType))
          }
        }else if(tripTypeFlowFrom == "MyFiles"){
          dispatch(setTripType(bookingResult.tripType))
        }else{
          dispatch(setTripType(tripType))
        }        
        dispatch(setBookingItinenary(bookingResult.bookingDetails))
        dispatch(setProvideId(bookingResult && bookingResult.providerId))
        dispatch(setPromotionData(bookingResult && bookingResult.promotion));
        dispatch(setPaymentDetailsAddons(bookingResult && bookingResult.paymentsForAddOns))
        dispatch(setAgentEmergencyNumber(bookingResult && bookingResult.travelAgent && bookingResult?.travelAgent?.emergencyPhone?.number));
        dispatch(setEmailLanguage(emailLanguage))
        dispatch(
          setBrandCode({
            brandCode: brandData && brandData.code,
            brandName: brandData && brandData.brandName,
          })
        );
        dispatch(setUUID(corrId))
        }
      }

      const fareType =
      bookingResult &&
      bookingResult.bookingDetails &&
      bookingResult.bookingDetails.itineraryFare &&
      bookingResult.bookingDetails.itineraryFare &&
      bookingResult.bookingDetails.itineraryFare.type;

      const providerName = bookingResult && bookingResult.providerName;
      let isDuffle = providerName == "NDC" ? true : false;
      dispatch(setProviderType(isDuffle))


      const contractType = 
        bookingResult &&
        bookingResult.bookingDetails &&
        bookingResult.bookingDetails.itineraryFare &&
        bookingResult.bookingDetails.itineraryFare &&
        bookingResult.bookingDetails.itineraryFare.contractType;
        
      const amount =
        bookingResult.bookingDetails &&
        bookingResult.bookingDetails.itineraryFare &&
        bookingResult.bookingDetails.itineraryFare &&
        bookingResult.bookingDetails.itineraryFare.totalAmount &&
        bookingResult.bookingDetails.itineraryFare.totalAmount.amount;;

      const cabin =
        bookingResult.bookingDetails &&
        bookingResult.bookingDetails.itineraryFare &&
        bookingResult.bookingDetails.itineraryFare &&
        bookingResult.bookingDetails.itineraryFare.paxFares &&
        bookingResult.bookingDetails.itineraryFare.paxFares[0] &&
        bookingResult.bookingDetails.itineraryFare.paxFares[0].fareBasisDetails[0] &&
        bookingResult.bookingDetails.itineraryFare.paxFares[0].fareBasisDetails[0].brand &&
        bookingResult.bookingDetails.itineraryFare.paxFares[0].fareBasisDetails[0].brand.brandName;

      const lastTicketableDate =
        bookingResult.bookingDetails &&
        bookingResult.bookingDetails.itineraryFare &&
        bookingResult.bookingDetails.itineraryFare &&
        bookingResult.bookingDetails.itineraryFare.lastTicketableDate

      const validatingCarrier =
        bookingResult.bookingDetails &&
        bookingResult.bookingDetails.itineraryFare &&
        bookingResult.bookingDetails.itineraryFare.platingCarrierDetails &&
        bookingResult.bookingDetails.itineraryFare.platingCarrierDetails.carrier

      dispatch(
        setBookingHeader({
          fareType: fareType,
          brandName: cabin,
          amount: amount,
          validatingCarrier: validatingCarrier,
          lastTicketableDate
        }))

      const leftSection = bookingResult.bookingDetails.segments && bookingResult.bookingDetails.segments[0];
      const ouboundFlights = leftSection && Object.values(leftSection)[0];
      const flightDetail = ouboundFlights && ouboundFlights.flights
      const flyingFromCity = ouboundFlights && ouboundFlights.flights && ouboundFlights.flights[0].departureAirportCity
      const flyingFromCityCode = ouboundFlights && ouboundFlights.flights && ouboundFlights.flights[0].departureAirport
      const flyingToCity = ouboundFlights && ouboundFlights.flights && ouboundFlights.flights[flightDetail.length - 1].arrivalAirportCity
      const flyingToCityCode = ouboundFlights && ouboundFlights.flights && ouboundFlights.flights[flightDetail.length - 1].arrivalAirport

      const rightSection = bookingResult.bookingDetails.segments && bookingResult.bookingDetails.segments[1];
      const inboundFlights = rightSection && Object.values(rightSection)[0];
      const flightDetailOpenJaw = inboundFlights && inboundFlights.flights

      const flyingFromCityOpenJaw = inboundFlights && inboundFlights.flights && inboundFlights.flights[0].departureAirportCity
      const flyingFromCityCodeOpenJaw = inboundFlights && inboundFlights.flights && inboundFlights.flights[0].departureAirport
      const flyingToCityOpenJaw = inboundFlights && inboundFlights.flights && inboundFlights.flights[flightDetailOpenJaw.length - 1].arrivalAirportCity
      const flyingToCityCodeOpenJaw = inboundFlights && inboundFlights.flights && inboundFlights.flights[flightDetailOpenJaw.length - 1].arrivalAirport

      dispatch(setFlyingFromCity(flyingFromCity));
      dispatch(setFlyingFrom(flyingFromCityCode));
      dispatch(setFlyingToCity(flyingToCity));
      dispatch(setFlyingTo(flyingToCityCode));
      if(bookingResult?.tripType === "OpenJaw" || (flowFrom=="Calculator" && bookingResult?.tripType == "Return")){
        dispatch(setOpenJawFlyingFromCity_B(flyingFromCityOpenJaw));
        dispatch(setOpenJawFlyingFrom_B(flyingFromCityCodeOpenJaw ));
        dispatch(setOpenJawFlyingToCity_B(flyingToCityOpenJaw));
        dispatch(setOpenJawFlyingTo_B(flyingToCityCodeOpenJaw));
      }
      if(bookingResult?.tripType === "MultiCity"){
        dispatch(setMultiCityFlyingFromM(flyingFromCityCode));
        dispatch(setMultiCityFlyingToM(flyingToCityCode));
      }

      let passengerData=bookingResult?.bookingDetails && bookingResult?.bookingDetails?.passengers;
      let allowSeatPayment = true;
      passengerData && passengerData.forEach(passengerVal => {
        let addOnsData = passengerVal && passengerVal.addOns
        addOnsData && addOnsData.forEach(seatVal => {
          let status = seatVal?.seat?.status;
          if(status=="Unconfirmed"){
            allowSeatPayment = false;
          }
        })
      })
      dispatch(setAllowSeatPayment(allowSeatPayment))
      dispatch(setIsEmailMandatory(bookingResult?.isMobileMandatory))
      dispatch(setIsMobileMandatory(bookingResult?.isEmailMandatory))
      dispatch(setIsPassportMandatory(bookingResult?.isPassportMandatory))

    }
    return bookingResult
  }
}