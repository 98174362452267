
import { useDispatch } from 'react-redux';
import { setInternalUserLogin, setInternalUserAccessToken, setLoggedInUserData } from '../actionCreators/user';

export function ResetUser() {
    const dispatch = useDispatch();

    dispatch(setInternalUserLogin(undefined));
    dispatch(setInternalUserAccessToken(undefined));
    dispatch(setLoggedInUserData({}));
}