import React, { useState, useEffect } from 'react'
import { env } from '../../../../src/appConfig'
import { useDispatch, useSelector } from "react-redux";
import { setInternalUserLogin, setInternalUserAccessToken, setLoggedInUserData } from '../../../appStore/actionCreators/user'

import { setShowAgencyInfo } from '../../../appStore/actionCreators/searchForm'
import { useHistory } from "react-router-dom";
import {
    setInternalUserP6,
    setOrgId,
    setUserId
  } from '../../../appStore/actionCreators/agent'
import { setLoadingData } from '../../../appStore/actionCreators/itinenary';
import {resetAppStore} from '../../../appStore/resetAppStore';
import getApiResponse from "../../../services/getApiResponse";

function LoginContainter(getState) {
    const dispatch = useDispatch() 
    const history = useHistory();

    const [username , setUsername] = useState('') 
    const [password , setPassword] = useState('') 
    const [btnloader, setBtnloader] = useState(false);
    // const [usernameError , setUsernameError] = useState('') 
    // const [passwordError , setPasswordError] = useState('') 
    const [errorMessage, setErrorMessage] = useState('')

    const isInternalUserAutheticated = useSelector((state) => state.internalUser.isInternalUserAutheticated);
    const url = useSelector((state) => state.searchForm.flightUrl)

  useEffect(() => {
    if(isInternalUserAutheticated === true) {
      history.push(url) 
      dispatch(setShowAgencyInfo(true));
      dispatch(setLoadingData(true)); 
  }
  }, [isInternalUserAutheticated]);

  const setUsernameFunction = (e) => {
    // setUsernameError('')
    setErrorMessage("");
    setUsername(e.target.value);
  };

    const setPasswordFunction = (e)=>{
        // setPasswordError('')
        setErrorMessage("")
        setPassword(e.target.value)
    }

    const validation = ()=> {
      setBtnloader(true);
      if(username === ''){
        setErrorMessage("Please enter username.")
        setBtnloader(false);
      }else if (password === ''){
        setErrorMessage("Please enter password.")
        setBtnloader(false);
      } else {
        dispatch({ type: "RESET_EVERYTHING" });
        signIn()
      }  
    }

  // Common Axios Header 
  const headers = {
    "Content-type": "application/json; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true,
    "cache-control": "no-cache",
  }

  const signIn = async () => {
    //setIsLoading(true)   
    // Axios Payload
    const config = {
      method: "POST",
      url: env.REACT_APP_PROFILE_ORG_BASE_URL + "/auth/users/internal/signIn",
      headers: headers,
      data: {
        username: username,
        password: password,
        portal: "booking-flow",
      },
    };

    const result = await getApiResponse(config);
    if (result) {
      validatedToken(result.accessToken);
      dispatch(setUserId(result.username));
      dispatch(setInternalUserP6(result.atP6));
    } else {
      //setIsLoading(false)
      setErrorMessage("Please check username or password.");
    }
  };

  // VALIDATE TOKEN
  const validatedToken = async (value) => {    
    // Axios Payload
    const configValidatedToken = {
      method: "GET",
      url: env.REACT_APP_PROFILE_ORG_BASE_URL + `/auth/users/internal/redeem?token=${value}`,
      headers: headers,
    }

    const result = await getApiResponse(configValidatedToken);

    if (result) {
      dispatch(setInternalUserLogin(true));
      dispatch(setInternalUserAccessToken(value));

      getUser(value);
    } else {
      setErrorMessage("Failed to validate token.");
    }
    //setIsLoading(false)
  };

  // GET USER
  const getUser = async (value) => {

    // Axios Payload
    const configGetUser = {
      method: "GET",
      url: env.REACT_APP_PROFILE_ORG_BASE_URL + `/auth/users/internal?token=${value}`,
      headers: headers,
    }
    const result = await getApiResponse(configGetUser);

    if (result) {
      dispatch(setLoggedInUserData(result));
      dispatch(setOrgId("nTDopHgAED5SkaQGM6VKPG"));
      dispatch(setShowAgencyInfo(true));
      dispatch(setLoadingData(true));
    } else {
      setErrorMessage("Failed to validate token.");
    }
    //setIsLoading(false)
  };
  return (
    <>
      <div className="tb-login-wrapper">
        <div className="tb-login-form">
        
          <form>
            <div className="tb-login-input">
              <p className="text-danger">{errorMessage} </p>
              <label htmlFor="user">User Name</label>
              <input
                type="text"
                id="user"
                name="user_name"
                placeholder="Enter username or userId"
                value={username}
                onChange={(e) => setUsernameFunction(e)}
              />
            </div>
            <div className="tb-login-input">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPasswordFunction(e)}
              />
            </div>
            <button type="submit" style={{"position": "relative"}} className={btnloader ? "loading" : ""} onClick={(e)=>{e.preventDefault();!btnloader && validation();}} disabled={btnloader}>
              Sign In
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
export default LoginContainter;