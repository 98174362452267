import { setItinenaryData, setLoadingData, setItinenaryRowsKeys, setDetailedItinenaryRows } from '../actionCreators/itinenary';
import { env } from '../../../src/appConfig'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setFilterResetDetailedItinenaryRows, setFilterResetItinenaryData, setFilterResetItinenaryRowsKeys } from '../actionCreators/Filter/filter_actionCreators';
import { setInitToken } from '../../appStore/actionCreators/initToken';
import { setTimeCalculator, setTripTypeFromApi } from '../actionCreators/searchForm';
import { setFilterNoResultsStatus } from "../../appStore/actionCreators/Filter/filter_actionCreators";
import getApiResponse from '../../services/getApiResponse'
toast.configure();

export const getAllSearchResults = (init) => {

  return async (dispatch, getState) => {

    const tokenData = getState().initToken;
    const corrId = getState().searchForm.uuid;
    let token = tokenData && tokenData.token && tokenData.token.token;
    const flightType = getState().searchForm.flightType;
    const isInternalUserAutheticated = getState().internalUser.isInternalUserAutheticated;
    const agentProfile = getState().agentProfile;
    const { agencyDetails, agentDetails, internalUserP6} = agentProfile
    const bookingChannel = isInternalUserAutheticated ? "internal" : "external";
    const travelAgent = {
      firstName: isInternalUserAutheticated ? agentDetails?.firstName : agencyDetails?.firstName,
      lastName: isInternalUserAutheticated ? agentDetails?.lastName : agencyDetails?.lastName,
      userName: isInternalUserAutheticated ? agentDetails?.username : agencyDetails?.username,
      phone: {
        number: agentDetails?.contactNumber,
        type: "Unknown"
      },
      email: isInternalUserAutheticated ? agentDetails?.email : agencyDetails?.email,
      agentNumericCode: agentProfile?.agentOldUserId
    };

    const agencyData =
    {
      code: agencyDetails?.adAgencyCode,
      name: agencyDetails?.adTravelAgencyName,
      networkId: agencyDetails?.adNetworkId,
      address: {
        line1: agencyDetails?.adAddressLine1,
        line2: agencyDetails?.adAddressLine2,
        city: {
          name: agencyDetails?.adCity,
        },
        state: {
          code: agencyDetails?.provinceOrState
        },
        country: {
          code: agencyDetails?.country
        },
        postalCode: agencyDetails?.adPostalCode,
        addressType: "Unspecified"
      },
      phone: {
        number: agencyDetails?.adNetworkId,
        type: "Unknown"
      },
      scheduleChangeEmail: agencyDetails?.ciEmailAirSchedule,
      supplierProfileId:agencyDetails?.supplierProfiles?.[0]?.profileId ? agencyDetails?.supplierProfiles?.[0]?.profileId : agencyDetails?.adAgencyCode
    };


    const tripType =  getState().searchForm.tripType;
    let nextResultsKey = null;
    let resultData = [];
    if (init.data && typeof init.data == "string") {
      let jsonObject = JSON.parse(init.data);
      if (isInternalUserAutheticated) {
        jsonObject.internalUserP6 = internalUserP6;
      }
      jsonObject.travelAgent = travelAgent;
      jsonObject.agency = agencyData;
      let updatedJson = JSON.stringify(jsonObject)
      init.data = updatedJson
    } else {
      if (isInternalUserAutheticated) {
        init.data.internalUserP6 = internalUserP6;
      }
      init.data.travelAgent = travelAgent;
      init.data.agency = agencyData;
    }
      let response= await getApiResponse(init);

      resultData = [];
      if (response == "error"){
        dispatch(setLoadingData(true));
        dispatch(setItinenaryData([]));
      } else if (!response) {
        // count =0
        dispatch(setLoadingData(false));
        
      } else {
        const data = await response;
        nextResultsKey = data && data.nextResultsKey ? data.nextResultsKey : null;

        if (data.status === "Completed") {
          var startTime = new Date().getTime();
          dispatch(setInitToken(data.token))
          dispatch(setTimeCalculator(startTime))
          dispatch(setTripTypeFromApi(tripType))
          dispatch(setInitToken(data.token));
          resultData.push(...data.results);

          const sortFare = (a, b) => a.validatingCarrier.localeCompare(b.validatingCarrier) || a.totalAmount.amount - b.totalAmount.amount;
          const SortedResultsArray = resultData && resultData.length !== 0 && resultData.sort(sortFare);

          let distinctValidatingCarrier = SortedResultsArray && [...new Set(SortedResultsArray.map(itinenary => itinenary.validatingCarrier))]
          let distinctFareType = SortedResultsArray && [...new Set(SortedResultsArray.map(itinenary => itinenary.type))]
          let distinctContractType = SortedResultsArray && [...new Set(SortedResultsArray.map(itinenary => itinenary.contractType))]

          let keys = [];
          distinctValidatingCarrier && distinctFareType && distinctValidatingCarrier.map(vc => {
            distinctContractType.map(contractType => {
              distinctFareType.map(type => {
                keys.push(vc + contractType + type);
              })
            })
          })
          let sortedKeys = keys.sort();
          let itinenaryRows = [];
          sortedKeys && sortedKeys.map(key => {
            //itinenaryRows.push(key);
            itinenaryRows[key] = [];
          })
          SortedResultsArray && sortedKeys && SortedResultsArray.map(itinenary => {
            var k = itinenary.validatingCarrier + itinenary.contractType + itinenary.type;
            itinenaryRows[k].push(itinenary);
          });

          let itinenaryRowToDisplay = [];
          sortedKeys.map(key => {
            itinenaryRows && itinenaryRows[key] && itinenaryRows[key].length !== 0 && itinenaryRowToDisplay.push(itinenaryRows[key][0]);

          })


       
          sortedKeys.map(key => {
            itinenaryRows && itinenaryRows[key] && itinenaryRows[key].length !== 0 && itinenaryRows[key].sort((a, b) => a.totalAmount.amount - b.totalAmount.amount);
          })
         

     
          let sortedResponse = itinenaryRowToDisplay.sort((a, b) => a.totalAmount.amount - b.totalAmount.amount)

         
          dispatch(setFilterNoResultsStatus(false)) 

          dispatch(setItinenaryData(sortedResponse));
          // dispatch(setItinenaryRowsKeys(sortedKeys));
          // dispatch(setDetailedItinenaryRows(itinenaryRows));
        
          dispatch(setFilterResetItinenaryData(sortedResponse));
          dispatch(setFilterResetItinenaryRowsKeys(sortedKeys));
          dispatch(setFilterResetDetailedItinenaryRows(itinenaryRows));
           dispatch(setLoadingData(true));
          return data
        }else{
          if (data?.status == "InProgress" || data?.results?.length == 0 || data?.message == "No data found! Please modify your search.") {
            dispatch(setLoadingData(true));
            dispatch(setItinenaryData([]));
          }
        }
      }
      
    
  }
}
