import * as actionTypes from "../../actionCreators/MultiCity/multicity_actionTypes";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  inputField: [" "],
  multiCityFrom_A: "",
  multiCityTo_A: "",
  multiCityFrom_B: "",
  multiCityTo_B: "",
  multiCityFrom_0: "",
  multiCityTo_0: "",
  multiCityFrom_1: "",
  multiCityTo_1: "",
  multiCityFrom_2: "",
  multiCityTo_2: "",
  multiCityFrom_3: "",
  multiCityTo_3: "",

  multiCityDate_A: null,
  multiCityTime_A: "",
  multiCityDate_B: null,
  multiCityTime_B: "",
  multiCityDate_0: null,
  multiCityTime_0: "",
  multiCityDate_1: null,
  multiCityTime_1: "",
  multiCityDate_2: null,
  multiCityTime_2: "",
  multiCityDate_3: null,
  multiCityTime_3: "",

  multiCity_adult_val: 1,
  multiCity_child_val: 0,
  multiCity_infant_val: 0,

  multiCity_cabin_val: "",

  multiCity_air: true,
  multiCity_land: false,
  multiCity_cruise: false,

  multiCity_freeBag: false,
  multiCity_exBasicEco: false,
  multiCity_exMultiTicket: false,

  multiCity_maximum_conn: "Two",

  multiCity_searchnearbyAir: false,
  multiCity_flexiDate: false,

  multiCity_anyallin_R1: false,
  multiCity_skyteam_R2: false,
  multiCity_staralli_R3: false,
  multiCity_oneworld_R4: false,

  multiCity_free_R1: false,
  multiCity_fee_R2: false,

  //   validation

  flying_from_mc_errA: "",
  flying_to_mc_errA: "",

  flying_from_mc_errB: "",
  flying_to_mc_errB: "",

  flying_from_mc_err0: "",
  flying_to_mc_err0: "",

  flying_from_mc_err1: "",
  flying_to_mc_err1: "",

  flying_from_mc_err2: "",
  flying_to_mc_err2: "",

  flying_from_mc_err3: "",
  flying_to_mc_err3: "",

  adult_mc_err: "",

  mc_date_a: "",
  mc_date_b: "",
  mc_date_0: "",
  mc_date_1: "",
  mc_date_2: "",
  mc_date_3: "",

  multiCity_token: "",
  multiCityData: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MULTICITY_ADDINPUT:
      return {
        ...state,
        inputField: [...state.inputField, { id: uuidv4() }],
      };

    case actionTypes.MULTICITY_REMOVEINPUT:
      const newArray = [...state.inputField];

      var newObj = { ...state };

      for (let i = 0; i < state.inputField.length; i++) {
        let multiCityFrom = state[`multiCityFrom_${i}`];
        let multiCityTo = state[`multiCityTo_${i}`];
        let multiCityDate = state[`multiCityDate_${i}`];
        let multiCityTime = state[`multiCityTime_${i}`];

        if (i > action.val) {
          newObj[`multiCityFrom_${i - 1}`] = multiCityFrom;
          newObj[`multiCityTo_${i - 1}`] = multiCityTo;
          newObj[`multiCityDate_${i - 1}`] = multiCityDate;
          newObj[`multiCityTime_${i - 1}`] = multiCityTime;
        }
        if (i === state.inputField.length - 1) {
          newObj[`multiCityFrom_${state.inputField.length - 1}`] = "";
          newObj[`multiCityTo_${state.inputField.length - 1}`] = "";
          newObj[`multiCityDate_${state.inputField.length - 1}`] = null;
          newObj[`multiCityTime_${state.inputField.length - 1}`] = "";
        }
      }

      newArray.splice(action.val, 1);

      return {
        ...state,
        ...newObj,
        inputField: newArray,
      };
      
    case actionTypes.SET_MULTICITY_FROM_A:
      return {
        ...state,
        multiCityFrom_A: action.val,
      };

    case actionTypes.SET_MULTICITY_TO_A:
      return {
        ...state,
        multiCityTo_A: action.val,
      };

    case actionTypes.SET_MULTICITY_FROM_B:
      return {
        ...state,
        multiCityFrom_B: action.val,
      };

    case actionTypes.SET_MULTICITY_TO_B:
      return {
        ...state,
        multiCityTo_B: action.val,
      };

    case actionTypes.SET_MULTICITY_FROM_0:
      return {
        ...state,
        multiCityFrom_0: action.val,
      };

    case actionTypes.SET_MULTICITY_TO_0:
      return {
        ...state,
        multiCityTo_0: action.val,
      };

    case actionTypes.SET_MULTICITY_FROM_1:
      return {
        ...state,
        multiCityFrom_1: action.val,
      };

    case actionTypes.SET_MULTICITY_TO_1:
      return {
        ...state,
        multiCityTo_1: action.val,
      };

    case actionTypes.SET_MULTICITY_FROM_2:
      return {
        ...state,
        multiCityFrom_2: action.val,
      };

    case actionTypes.SET_MULTICITY_TO_2:
      return {
        ...state,
        multiCityTo_2: action.val,
      };

    case actionTypes.SET_MULTICITY_FROM_3:
      return {
        ...state,
        multiCityFrom_3: action.val,
      };

    case actionTypes.SET_MULTICITY_TO_3:
      return {
        ...state,
        multiCityTo_3: action.val,
      };

    case actionTypes.SET_MULTICITY_DATE_A:
      return {
        ...state,
        multiCityDate_A: action.val,
      };

    case actionTypes.SET_MULTICITY_TIME_A:
      return {
        ...state,
        multiCityTime_A: action.val,
      };

    case actionTypes.SET_MULTICITY_DATE_B:
      return {
        ...state,
        multiCityDate_B: action.val,
      };

    case actionTypes.SET_MULTICITY_TIME_B:
      return {
        ...state,
        multiCityTime_B: action.val,
      };

    case actionTypes.SET_MULTICITY_DATE_0:
      return {
        ...state,
        multiCityDate_0: action.val,
      };

    case actionTypes.SET_MULTICITY_TIME_0:
      return {
        ...state,
        multiCityTime_0: action.val,
      };

    case actionTypes.SET_MULTICITY_DATE_1:
      return {
        ...state,
        multiCityDate_1: action.val,
      };

    case actionTypes.SET_MULTICITY_TIME_1:
      return {
        ...state,
        multiCityTime_1: action.val,
      };

    case actionTypes.SET_MULTICITY_DATE_2:
      return {
        ...state,
        multiCityDate_2: action.val,
      };

    case actionTypes.SET_MULTICITY_TIME_2:
      return {
        ...state,
        multiCityTime_2: action.val,
      };

    case actionTypes.SET_MULTICITY_DATE_3:
      return {
        ...state,
        multiCityDate_3: action.val,
      };

    case actionTypes.SET_MULTICITY_TIME_3:
      return {
        ...state,
        multiCityTime_3: action.val,
      };

    case actionTypes.REST:
      return {
        ...initialState,
      };

    case actionTypes.MULTICITY_ADULT_VALUE:
      return {
        ...state,
        multiCity_adult_val: action.val,
      };

    case actionTypes.MULTICITY_CHILD_VALUE:
      return {
        ...state,
        multiCity_child_val: action.val,
      };

    case actionTypes.MULTICITY_INFANT_VALUE:
      return {
        ...state,
        multiCity_infant_val: action.val,
      };

    case actionTypes.MULTICITY_SET_CABIN:
      return {
        ...state,
        multiCity_cabin_val: action.val,
      };

    case actionTypes.MULTICITY_AIR:
      return {
        ...state,
        multiCity_air: action.val,
      };

    case actionTypes.MULTICITY_LAND:
      return {
        ...state,
        multiCity_land: action.val,
      };

    case actionTypes.MULTICITY_CRUISE:
      return {
        ...state,
        multiCity_cruise: action.val,
      };

    case actionTypes.MULTICITY_AT_LEAST_FREE_BAG:
      return {
        ...state,
        multiCity_freeBag: action.val,
      };

    case actionTypes.MULTICITY_EXCLUDE_BASIC_ECONOMY_FARES:
      return {
        ...state,
        multiCity_exBasicEco: action.val,
      };

    case actionTypes.MULTICITY_EXCLUDE_MULTI_TICKET_PRICING:
      return {
        ...state,
        multiCity_exMultiTicket: action.val,
      };

    case actionTypes.MULTICITY_MAXIMUM_CONN:
      return {
        ...state,
        multiCity_maximum_conn: action.val,
      };

    case actionTypes.MULTICITY_SEARCH_NEARBY_AIRPORTS:
      return {
        ...state,
        multiCity_searchnearbyAir: action.val,
      };

    case actionTypes.MULTICITY_FLEXI_DATE:
      return {
        ...state,
        multiCity_flexiDate: action.val,
      };

    case actionTypes.MULTICITY_ANY_ALLIANCE:
      return {
        ...state,
        multiCity_anyallin_R1: action.val,
      };

    case actionTypes.MULTICITY_SKY_TEAM:
      return {
        ...state,
        multiCity_skyteam_R2: action.val,
      };

    case actionTypes.MULTICITY_STAR_ALLIANCE:
      return {
        ...state,
        multiCity_staralli_R3: action.val,
      };

    case actionTypes.MULTICITY_ONEWORLD:
      return {
        ...state,
        multiCity_oneworld_R4: action.val,
      };

    case actionTypes.MULTICITY_FREE:
      return {
        ...state,
        multiCity_free_R1: action.val,
      };

    case actionTypes.MULTICITY_FEE:
      return {
        ...state,
        multiCity_fee_R2: action.val,
      };

    //   Validation

    case actionTypes.FLYING_FROM_MC_ERROR_A:
      return {
        ...state,
        flying_from_mc_errA: action.val,
      };

    case actionTypes.FLYING_TO_MC_ERROR_A:
      return {
        ...state,
        flying_to_mc_errA: action.val,
      };

    case actionTypes.FLYING_FROM_MC_ERROR_B:
      return {
        ...state,
        flying_from_mc_errB: action.val,
      };

    case actionTypes.FLYING_TO_MC_ERROR_B:
      return {
        ...state,
        flying_to_mc_errB: action.val,
      };

    case actionTypes.FLYING_FROM_MC_ERROR_0:
      return {
        ...state,
        flying_from_mc_err0: action.val,
      };

    case actionTypes.FLYING_TO_MC_ERROR_0:
      return {
        ...state,
        flying_to_mc_err0: action.val,
      };

    case actionTypes.FLYING_FROM_MC_ERROR_1:
      return {
        ...state,
        flying_from_mc_err1: action.val,
      };

    case actionTypes.FLYING_TO_MC_ERROR_1:
      return {
        ...state,
        flying_to_mc_err1: action.val,
      };

    case actionTypes.FLYING_FROM_MC_ERROR_2:
      return {
        ...state,
        flying_from_mc_err2: action.val,
      };

    case actionTypes.FLYING_TO_MC_ERROR_2:
      return {
        ...state,
        flying_to_mc_err2: action.val,
      };

    case actionTypes.FLYING_FROM_MC_ERROR_3:
      return {
        ...state,
        flying_from_mc_err3: action.val,
      };

    case actionTypes.FLYING_TO_MC_ERROR_3:
      return {
        ...state,
        flying_to_mc_err3: action.val,
      };

    case actionTypes.ADULT_MC_ERROR:
      return {
        ...state,
        adult_mc_err: action.val,
      };

    case actionTypes.FLYING_DATE_MC_ERROR_A:
      return {
        ...state,
        mc_date_a: action.val,
      };

    case actionTypes.FLYING_DATE_MC_ERROR_B:
      return {
        ...state,
        mc_date_b: action.val,
      };

    case actionTypes.FLYING_DATE_MC_ERROR_0:
      return {
        ...state,
        mc_date_0: action.val,
      };

    case actionTypes.FLYING_DATE_MC_ERROR_1:
      return {
        ...state,
        mc_date_1: action.val,
      };

    case actionTypes.FLYING_DATE_MC_ERROR_2:
      return {
        ...state,
        mc_date_2: action.val,
      };

    case actionTypes.FLYING_DATE_MC_ERROR_3:
      return {
        ...state,
        mc_date_3: action.val,
      };


    case actionTypes.SET_MULTICITY_TOKEN:
      return {
        ...state,
        multiCity_token: action.val,
      };

    case actionTypes.SET_MULTICITY_DATA:
      return {
        ...state,
        multiCityData: action.val,
      };


    default:
      return state;
  }
};

export default reducer;
