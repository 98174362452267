import * as actionTypes from "../../actionCreators/Filter/filter_actionTypes";
// import { SET_FILTER_RESET_ITINENARY_DATA } from "../../storeConstants";

const initialState = {
  airlines: [],
  airports: [],
  noOfConnections: [],
  priceRange: {},
  connectionAirports: [],
  tripDurations: [],
  connectionDurations: [],
  departureTimes: [],
  arrivalTimes: [],
  alliances: [],
  booleanFilters: [],
  fareTypes: [],
  filterSearchResult: [],
  hideChangesOfAirport: "",
  hideNearByAirport: "",
  isLoading: true,
  filterBody: {},
  filterResetItinenaryData: {},
  filterResetItinenaryRowsKey: [],
  filterResetDetailedItinenaryRows: [],
  filterResetInitialData: {},
  filterActiveData: [],
  connectionActiveStatus: false,
  connectionAirportActiveStatus: false,
  tripDurationActiveStatus: false,
  departureTimeActiveStatus: false,
  arrivalTimeActiveStatus: false,
  airlinesActiveStatus: false,
  allianceActiveStatus: false,
  priceRangeActiveStatus: false,
  freeBaggageActiveStatus: false,
  connectionDurationActiveStatus: false,
  hideChangesActiveStatus: false,
  filterNoResults: false,

  airlineCarrierData: [],
  airAlliancesCarrierData: [],
  connectionFilterData: {},
  connectionAirportFilterData: [],
  connectionDurationFilterData: {},
  tripDurationFilterData: {},
  PriceRangeDataFilter: {},
  freeBaggageFilter: false,
  appliedFilterFlag: false,
  appliedFilterRecord: [],
  filterStateCarrierbd: [],
  filterStateShowPopFilter: false,
  departureFilterData: {},
  arrivalTimeFilterData: {},
  hideChangeFilter:false,
  flightNumbersStore:[],
  flightNumbersStoreStatus: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_AIRLINES:
      return {
        ...state,
        airlines: action.val,
      };

    case actionTypes.SET_FILTER_STATE_CARRIERBD:
      return {
        ...state,
        filterStateCarrierbd: action.val,
      };

    case actionTypes.SET_FILTER_STATE_SHOW_POP_FILTER:
      return {
        ...state,
        filterStateShowPopFilter: action.val,
      };

    case actionTypes.SET_AIRPORTS:
      return {
        ...state,
        airports: action.val,
      };

    case actionTypes.SET_NO_OF_CONNECTIONS:
      return {
        ...state,
        noOfConnections: action.val,
      };

    case actionTypes.SET_PRICE_RANGE:
      return {
        ...state,
        priceRange: action.val,
      };

    case actionTypes.SET_CONNECTION_AIRPORTS:
      return {
        ...state,
        connectionAirports: action.val,
      };

    case actionTypes.SET_TRIP_DURATIONS:
      return {
        ...state,
        tripDurations: action.val,
      };

    case actionTypes.SET_CONNECTION_DURATIONS:
      return {
        ...state,
        connectionDurations: action.val,
      };

    case actionTypes.SET_DEPARTURE_TIMES:
      return {
        ...state,
        departureTimes: action.val,
      };

    case actionTypes.SET_ARRIVAL_TIMES:
      return {
        ...state,
        arrivalTimes: action.val,
      };

    case actionTypes.SET_ALLIANCES:
      return {
        ...state,
        alliances: action.val,
      };

    case actionTypes.SET_BOOLEANFILTERS:
      return {
        ...state,
        booleanFilters: action.val,
      };

    case actionTypes.SET_FARETYPES:
      return {
        ...state,
        fareTypes: action.val,
      };

    case actionTypes.SET_FILTER_SEARCH_RESULT:
      return {
        ...state,
        filterSearchResult: action.val,
      };

    case actionTypes.SET_HIDE_CHANGES_OF_AIRPORT:
      return {
        ...state,
        hideChangesOfAirport: action.val,
      };

    case actionTypes.SET_HIDE_NEAR_BY_AIRPORT:
      return {
        ...state,
        hideNearByAirport: action.val,
      };

    case actionTypes.SET_FILTER_ISLOADING:
      return {
        ...state,
        isLoading: action.val,
      };

    case actionTypes.SET_FILTER_BODY:
      return {
        ...state,
        filterBody: action.val,
      };
    case actionTypes.SET_APPLIED_FILTER_FLAG:
      return {
        ...state,
        appliedFilterFlag: action.val,
      };
    case actionTypes.SET_APPLIED_FILTER_RECORD:
      return {
        ...state,
        appliedFilterRecord: action.val,
      };

    case actionTypes.SET_FILTER_RESET_ITINENARY_DATA:
      return {
        ...state,
        filterResetItinenaryData: action.val,
      };
    case actionTypes.SET_FILTER_RESET_ITINENARY_ROWS_KEYS:
      return {
        ...state,
        filterResetItinenaryRowsKey: action.val,
      };
    case actionTypes.SET_FILTER_RESET_DETAILED_ITINENARY_ROWS:
      return {
        ...state,
        filterResetDetailedItinenaryRows: action.val,
      };
    case actionTypes.SET_FILTER_RESET_INITIAL_DATA:
      return {
        ...state,
        filterResetInitialData: action.val,
      };
    case actionTypes.SET_FILTER_ACTIVE_DATA:
      return {
        ...state,
        filterActiveData: action.val,
      };

    case actionTypes.SET_CONNECTIONS_ACTIVE_STATUS:
      return {
        ...state,
        connectionActiveStatus: action.val,
      };
    case actionTypes.SET_CONNECTION_AIRPORT_ACTIVE_STATUS:
      return {
        ...state,
        connectionAirportActiveStatus: action.val,
      };
    case actionTypes.SET_TRIP_DURATION_ACTIVE_STATUS:
      return {
        ...state,
        tripDurationActiveStatus: action.val,
      };
    case actionTypes.SET_DEPARTURE_TIME_ACTIVE_STATUS:
      return {
        ...state,
        departureTimeActiveStatus: action.val,
      };
    case actionTypes.SET_ARRIVAL_TIME_ACTIVE_STATUS:
      return {
        ...state,
        arrivalTimeActiveStatus: action.val,
      };
    case actionTypes.SET_AIRLINES_ACTIVE_STATUS:
      return {
        ...state,
        airlinesActiveStatus: action.val,
      };
    case actionTypes.SET_AIRLINES_CARRIER_DATA:
      return {
        ...state,
        airlineCarrierData: action.val,
      };
    case actionTypes.SET_ALLIANCES_ACTIVE_STATUS:
      return {
        ...state,
        allianceActiveStatus: action.val,
      };
    case actionTypes.SET_PRICE_RANGE_ACTIVE_STATUS:
      return {
        ...state,
        priceRangeActiveStatus: action.val,
      };
    case actionTypes.SET_FREE_BAGGAGE_ACTIVE_STATUS:
      return {
        ...state,
        freeBaggageActiveStatus: action.val,
      };
    case actionTypes.SET_CONNECTION_DURATION_ACTIVE_STATUS:
      return {
        ...state,
        connectionDurationActiveStatus: action.val,
      };
    case actionTypes.SET_HIDE_CHANGES_ACTIVE_STATUS:
      return {
        ...state,
        hideChangesActiveStatus: action.val,
      };

    case actionTypes.SET_FILTER_N0_RESULTS_STATUS:
      return {
        ...state,
        filterNoResults: action.val,
      };
    case actionTypes.SET_AIR_ALLIINCE_DATA:
      return {
        ...state,
        airAlliancesCarrierData: action.val,
      };
    case actionTypes.SET_PRICE_RANGE_FILTER:
      return {
        ...state,
        PriceRangeDataFilter: action.val,
      };
    case actionTypes.SET_CONNECTION_FILTER_DATA:
      return {
        ...state,
        connectionFilterData: action.val,
      };
    case actionTypes.SET_CONNECTION_AIRPORT_FILTER:
      return {
        ...state,
        connectionAirportFilterData: action.val,
      };
    case actionTypes.SET_CONNECTION_DURATION_FILTER:
      return {
        ...state,
        connectionDurationFilterData: action.val,
      };
    case actionTypes.SET_TRIP_DURATION_FILTER:
      return {
        ...state,
        tripDurationFilterData: action.val,
      };
    case actionTypes.SET_FREE_BAGGAGE_FILTER:
      return {
        ...state,
        freeBaggageFilter: action.val,
      };
    case actionTypes.SET_DEPARTURE_TIMES_FILTER:
      return {
        ...state,
        departureFilterData: action.val,
      };
    case actionTypes.SET_ARRIVAL_TIMES_FILTER:
      return {
        ...state,
        arrivalTimeFilterData: action.val,
      };
    case actionTypes.HIDE_CHANGE_FILTER:
      return {
        ...state,
        hideChangeFilter: action.val,
      };
      case actionTypes.SET_FLIGHT_NUMBERS_FILTER:
        return {
          ...state,
          flightNumbersStore: action.val,
        };
        case actionTypes.SET_FLIGHT_NUMBER_STATUS:
          return {
            ...state,
            flightNumbersStoreStatus: action.val,
          };   

    default:

      return state;
  }
};

export default reducer;
