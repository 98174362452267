import { SET_TODAYS_QUOTES, SET_YESTERDAYS_QUOTES, SET_LAST_WEEK_QUOTES, SET_ARCHEIVED_QUOTES, 
        SET_QUOTE_DETAILS, 
        SET_REPRICE_QUOTE,
        SET_CLIENT_NAME,
        SET_QUOTE_TOKEN,
        SET_QUOTES_TAB_TYPE,
        SET_SHOW_EMAIL_QUOTE,
        SET_BOOKING_WITH_QUOTE} from '../storeConstants';

export const setTodaysQuotes = (quotes) => {
    return {
        type: SET_TODAYS_QUOTES,
        payload: quotes
    }
}

export const setYesterdaysQuotes = (quotes) => {
    return {
        type: SET_YESTERDAYS_QUOTES,
        payload: quotes
    }
}

export const setLastWeeksQuotes = (quotes) => {
    return {
        type: SET_LAST_WEEK_QUOTES,
        payload: quotes
    }
}

export const setArcheivedQuotes = (quotes) => {
    return {
        type: SET_ARCHEIVED_QUOTES,
        payload: quotes
    }
}

export const setQuoteDetails = (quoteDetails) => {
    return {
        type: SET_QUOTE_DETAILS,
        payload: quoteDetails
    }
}

export const setRepriceQuote = (repriceQuote) => {
    return {
        type: SET_REPRICE_QUOTE,
        payload: repriceQuote
    }
}

export const setClientName = (value) => {
    return {
        type: SET_CLIENT_NAME,
        payload: value
    }
}

export const setQuoteToken = (value) => {
    return {
        type: SET_QUOTE_TOKEN,
        payload: value
    }
}

export const setBookingWithQuote = (value) => {
    return {
        type: SET_BOOKING_WITH_QUOTE,
        payload: value
    }
}

export const setQuotesTabType = (value) => {
    return {
        type: SET_QUOTES_TAB_TYPE,
        payload: value
    }
}

export const setShowEmailQuote = (value) => {
    return {
        type: SET_SHOW_EMAIL_QUOTE,
        payload: value
    }
}