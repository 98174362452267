import * as actionTypes from "../../actionCreators/BlockSpace/blockSpace_actionTypes";

const initialState = {
    blockSpaceList: [],
    selectedBlockSpace:{},
    NoOfPassenger:1,
    markUpCheckFlag:false,
    markUpValue:0,
    travelerInfo:[],
    bookingId:"",
    totalCostPrice:0,
    totalSalesPrice:0,
    paymentStatus:"",
    allBookings: [],
    payment:{},
    autoEmail:false,
    fop : "creditCard",
    emailDropdown:false,
    emailType:"BLOCKSPACEEMAIL",
    emailTypeValue: ""
}

const BlockSpaceReducer  = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SET_BLOCK_SPACE_LIST:
        return {
          ...state,
          blockSpaceList: action.val,
        };
        case actionTypes.SET_SELECTED_BLOCK:
        return {
          ...state,
          selectedBlockSpace: action.val,
        };
        case actionTypes.SET_SELECTED_NO_OF_PASSENGER:
          return {
            ...state,
            NoOfPassenger: action.val,
          }
          case actionTypes.SET_MARKUP_CHECK_FLAG:
            return{
              ...state,
              markUpCheckFlag: action.val
            }
            case actionTypes.SET_MARKUP_VALUE:
            return{
              ...state,
              markUpValue: action.val
            }
            case actionTypes.SET_TRAVELER_INFO:
            return{
              ...state,
              travelerInfo: action.val
            }
            case actionTypes.SET_BOOKING_ID:
            return{
              ...state,
              bookingId: action.val
            }
            case actionTypes.SET_TOTAL_COST_PRICE:
            return{
              ...state,
              totalCostPrice: action.val
            }
            case actionTypes.SET_TOTAL_SALES_PRICE:
            return{
              ...state,
              totalSalesPrice: action.val
            }
            case actionTypes.SET_PAYMENT_STATUS:
            return{
              ...state,
              paymentStatus: action.val
            }
            case actionTypes.SET_ALL_BOOKINGS:
            return{
              ...state,
              allBookings: action.val
            }
            case actionTypes.SET_PAYMENT:
            return{
              ...state,
              payment: action.val
            }
            case actionTypes.SET_AUTO_EMAIL:
              return{
                ...state,
                autoEmail: action.val
              }
              case actionTypes.SET_FOP:
                return{
                  ...state,
                  fop: action.val
                }  
              case actionTypes.SET_EMAIL_DROPDOWN:
                return{
                  ...state,
                  emailDropdown: action.val
                }
                case actionTypes.SET_EMAIL_TYPE:
                return{
                  ...state,
                  emailType: action.val
                }
                case actionTypes.SET_EMAIL_TYPE_VALUE:
                return{
                  ...state,
                  emailTypeValue: action.val
                }
        default:
            return state;
    }
}

export default BlockSpaceReducer;