import React,{useState ,createContext} from "react";


export const BookingContext = createContext();

export const BookingProvider = (props) => {

    const[booking,setBooking] = useState("")
    const[id ,setId] = useState("")

    return(
        <BookingContext.Provider value={{bookingName:[booking , setBooking], bookId :[id , setId]}}>

            {props.children}
        </BookingContext.Provider>

    )


}