import { env } from '../../../src/appConfig'
import 'react-toastify/dist/ReactToastify.css';
import {
  setAgentAuthetication,
  setAgencyGroupId,
  setOrgId,
  setUserId,
  setAgencyId,
  setAgencyLocation,
  setAgentName,
  setAgentId,
  setAgentCode,
  setAgencyName,
  setAgentEmailId,
  setAgencyDetails,
  setAgentOldUserId
} from "../../appStore/actionCreators/agent";
import { v4 as uuidv4 } from "uuid";
import { setInternalUserLogin } from '../actionCreators/user';
import getApiResponse from "../../services/getApiResponse";

export const ssoAuthetication = (orgId, token) => {
  return async (dispatch,getState) => {
    const corrId =  uuidv4();
    
    // Axios Payload
    const config = {
      method: "GET",
      url: env.REACT_APP_PROFILE_ORG_BASE_URL +`/sso/redeem?token=${token}&orgId=${orgId}`,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "cache-control": "no-cache",
        correlationId: corrId,
      }
    }

    try {
      const autheticationResult = await getApiResponse(config);
      const languages = autheticationResult.lang?.toLowerCase();

      if (
        autheticationResult &&
        autheticationResult.hasOwnProperty("travelBusinessesId")
      ) {
        
        sessionStorage.setItem("agency",autheticationResult.adAgencyCode);
        sessionStorage.setItem("user",autheticationResult.username);
        dispatch(setInternalUserLogin(false));
        dispatch(setAgencyDetails(autheticationResult))
        dispatch(setAgencyId(autheticationResult.travelBusinessesId));
        dispatch(setAgencyGroupId(autheticationResult.consortiumId));
        dispatch(setOrgId(autheticationResult.orgId));
        dispatch(setUserId(autheticationResult.username));
        dispatch(setAgentId(autheticationResult.username));
        dispatch(setAgentName(autheticationResult.firstName+` `+autheticationResult.lastName));
        dispatch(setAgentCode(autheticationResult.adAgencyCode));
        dispatch(setAgencyName(autheticationResult.adTravelAgencyName));
        dispatch(setAgentEmailId(autheticationResult.email));
        dispatch({ type: "CHANGE LANGUAGE", data: languages });
        dispatch(setAgencyLocation(
          {
            "countryCode" : autheticationResult.country,
            "stateCode": autheticationResult.provinceOrState
          }
        ))
        dispatch(setAgentOldUserId(autheticationResult.oldAgentUserId))
        dispatch(setAgentAuthetication(true));   
        return autheticationResult     
      } else {
        dispatch(setAgentAuthetication(false));
         
        return autheticationResult
      }
    } catch(er) {

    }
  };
};
