export const SET_BLOCK_SPACE_LIST = "setBlockSpaceList";
export const SET_SELECTED_BLOCK = "setSelectedBlock";
export const SET_SELECTED_NO_OF_PASSENGER = "setSelectedNoOfPassenger";
export const SET_MARKUP_CHECK_FLAG = "setMarkupCheckFlag";
export const SET_MARKUP_VALUE = "setMarkupValue";
export const SET_TRAVELER_INFO = "setTravelerInfo";
export const SET_BOOKING_ID = "setBookingId";
export const SET_TOTAL_COST_PRICE="setTotalCostPrice";
export const SET_TOTAL_SALES_PRICE="setTotalSalesPrice";
export const SET_PAYMENT_STATUS = "setPaymentStatus";
export const SET_ALL_BOOKINGS = "setAllBookings";
export const SET_PAYMENT = "setPayment";
export const SET_AUTO_EMAIL = "setAutoEmail";
export const SET_FOP = "setFOP";
export const SET_EMAIL_DROPDOWN = "setEmailDropdown";
export const SET_EMAIL_TYPE = "setEmailType";
export const SET_EMAIL_TYPE_VALUE = "setEmailTypeValue";