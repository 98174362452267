import { SET_FARE_MATRIX , SET_LOWEST_FARE_MATRIX} from '../storeConstants';

export const setFareMatrix = (fareMatrixResult) => {
    return {
        type: SET_FARE_MATRIX,
        payload: fareMatrixResult
    }
}

export const setLowestFareMatrix = (lowestFareMatrixResult) => {
    return {
        type: SET_LOWEST_FARE_MATRIX,
        payload: lowestFareMatrixResult
    }
}

