export const getCabinName = (flightDetails) => {

    let cabinNameArray = []
    let cabinNameDetails = []

    flightDetails && flightDetails.map(name => {
        let cabinName = name && name.cabin
        
        if (cabinName && !cabinNameArray.includes(cabinName)) {
            cabinNameArray.push(cabinName);
            cabinNameDetails.push({ cabin: cabinName})
        }
    })

    let cabinValue;

    if (cabinNameArray && cabinNameArray.length > 1) {
        cabinValue = "Mixed Cabin"
    } else {
        cabinValue = cabinNameDetails && cabinNameDetails[0] && cabinNameDetails[0].cabin
    }

    return cabinValue

}