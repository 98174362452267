import * as actionTypes from "../../actionCreators/Tab/tab_actionTypes";


const initialState = {
  tab_Name:"",
  };



  const TabReducer = (state = initialState, action) => {
  

  switch (action.type) {
    
    // search
     
     case actionTypes.SET_TAB:
       
       return {
            ...state,
            tab_Name:action.val
       }

       default:

			return state;

	}

  }


  export default TabReducer;