import { env } from '../../appConfig'
import { setMarketingCarrierPaidSeatData } from "../actionCreators/itinenary";
import getApiResponse from "../../services/getApiResponse";

export const getMarketingCarrierData = (carrier,from) => {
 
  return async (dispatch, getState) => {
    try {
      
       let config = env.REACT_APP_STATIC_CONTENT + `/carrier/${carrier}`
       
      const result = await getApiResponse(config);
      
      if(result){
        dispatch(setMarketingCarrierPaidSeatData(result));    
        return result
      }
    
      } catch (error) {
        console.log("error:", error);
    }
  }
}