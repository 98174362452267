
// search
export const ONEWAY_FROM = 'ONEWAY_FROM'; 
export const ONEWAY_TO = 'ONEWAY_TO'; 

// date
export const ONEWAY_DATE = "ONEWAY_DATE";

// time
export const ONEWAY_TIME = "ONEWAY_TIME";

// counter
export const ONE_WAY_ADULT_VALUE  = "ONE_WAY_ADULT_VALUE";
export const ONE_WAY_CHILD_VALUE  = "ONE_WAY_CHILD_VALUE";
export const ONE_WAY_INFANT_VALUE = "ONE_WAY_INFANT_VALUE";

// checkbox
export const ONE_WAY_AIR = "ONE_WAY_AIR";
export const ONE_WAY_LAND = "ONE_WAY_LAND";
export const ONE_WAY_CRUISE = "ONE_WAY_CRUISE";
export const ONE_WAY_LEAST = "ONE_WAY_LEAST";
export const ONE_WAY_BASIC = "ONE_WAY_BASIC";
export const ONE_WAY_MULTI = "ONE_WAY_MULTI";
export const ONE_WAY_NEAR = "ONE_WAY_NEAR";
export const ONE_WAY_FLEXIBLE = "ONE_WAY_FLEXIBLE";


// dropdown
export const ONE_WAY_CABIN = "ONE_WAY_CABIN";
export const ONE_WAY_MAXIMUM_CONN = "ONE_WAY_MAXIMUM_CONN";

// Radio button

export const ONE_WAY_FREE = "ONE_WAY_FREE";
export const ONE_WAY_FEE = "ONE_WAY_FEE";
export const ONE_ANY_ALLIANCE = "ONE_ANY_ALLIANCE";
export const ONE_SKY_TEAM = "ONE_SKY_TEAM";
export const ONE_STAR_ALLIANCE = "ONE_STAR_ALLIANCE";
export const ONE_WAY_WORLD = "ONE_WAY_WORLD";

// Rest All Value

export const REST_ONEWAY = "REST_ONEWAY"


// Token for Oneway

export const SET_ONEWAY_TOKEN = "SET_ONEWAY_TOKEN"


// Validation

export const FLYING_FROM_ONE_ERROR = "FLYING_FROM_ONE_ERROR"
export const FLYING_TO_ONE_ERROR = "FLYING_TO_ONE_ERROR"

export const FLYING_DATE_ONE_ERROR = "FLYING_DATE_ONE_ERROR"   

export const SET_ADULT_ONE_ERROR = "SET_ADULT_ONE_ERROR"



