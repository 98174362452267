import React, { useEffect, useState } from 'react'
import SelectSeatMain from "./../selectSeat/SelectSeatMain";
import {setEditSeat} from "../../appStore/actionCreators/itinenary"
import { useDispatch, useSelector } from "react-redux";
import { setSeatMapHeader } from '../../appStore/actionCreators/flightBooking';
import { BookingStatusCode } from '../../utils/constant';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

function BookingSeatSelection(props) {
    const dispatch = useDispatch()
    const [isSeat, setSeat] = useState(false)
    const seatData = useSelector((state) => state.itinenaries.seat);
    const pnrData = useSelector((state) => state.flightBooking.bookingItenaries);
    const pnrBookingDetails = useSelector((state) => state.flightBooking.bookingDetails); 
    const bookingDetails = useSelector((state) => state.flightBooking.pnrCancellationStatus);
   
    const paymentsForAddOns = bookingDetails && bookingDetails.paymentsForAddOns ? bookingDetails.paymentsForAddOns : [];
    const ticketingDetails = pnrBookingDetails && pnrBookingDetails.ticketingDetails ? pnrBookingDetails.ticketingDetails : [];
    let filterTicketDetails = ticketingDetails && ticketingDetails.filter(obj => obj?.ticketStatus === "EmdTicketed")
    const { t } = useTranslation();
    const selectSeat = () => {
        var d = document.getElementById("body");
        if(d){
            d.className += "remove-scroll";
        }
   

        let headerObj ={
            totalFare:pnrData && pnrData.totalAmount && pnrData.totalAmount.amount,        
            cabin:pnrData && pnrData.paxFares && pnrData.paxFares[0] && pnrData.paxFares[0].fareBasisDetails && pnrData.paxFares[0]?.fareBasisDetails[0]?.cabin,
            brandName:pnrData && pnrData.paxFares && pnrData.paxFares[0] && pnrData.paxFares[0].fareBasisDetails && pnrData.paxFares[0]?.fareBasisDetails[0]?.brand && pnrData?.paxFares[0]?.fareBasisDetails[0]?.brand?.brandName
         }
        dispatch(setSeatMapHeader(headerObj));
        setSeat(true)
    }

    const closeSeatPopup = () => {
        setSeat(false)
        var d = document.getElementById("body");
        if(d){
            d.classList.remove("remove-scroll");
        }
    }

    useEffect(() => {

        if (props.from === "confirmation") {
            props.clearData()
        }
        if (isSeat) {
            
            dispatch(setEditSeat([]))
           // dispatch(setSeatMapDetail([]))
        }

    }, [isSeat])
    const currentDateTime = new Date();
    let currentDate = moment(currentDateTime).tz("America/Toronto").format();
    
    return (
        <>
        {props.isSeatSelect && ((bookingDetails?.bookingStatusDetail?.code !== BookingStatusCode.NTC) || (filterTicketDetails?.length === 0)) && bookingDetails?.bookingStatusDetail?.code !== BookingStatusCode.TC && paymentsForAddOns.length === 0 && pnrData?.optionDateToDisplay > currentDate && <a className="bd-sub-head-link" onClick={selectSeat} >{t("Select Seats")}</a>}
      
           
            {isSeat &&
                <SelectSeatMain
                    closeSeatMap={()=>closeSeatPopup()}
                    isSeat={isSeat}
                    from={props.from}
                    passengerData={props.passengerData}
                    addSeat={() => props.addSeat(seatData)}
                    updateSeat={() => props.updateSeat(seatData)}
                    addedSeatList={props.addedSeatList}
                    class={props.class}
                />}

        </>
    )
}
export default BookingSeatSelection;