import { React, Suspense, lazy, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Route, Switch, useLocation } from 'react-router-dom';
import { createBrowserHistory } from "history";
// import SpinnerLoader from './components/common/spinner';
// import SearchFormContainerNew from './pages/flights/home/SearchFormContainerNew';
import SsoAuthContainer from "./pages/flights/home/ssoAuthContainer"
import BookingConfirmationContainer from "./pages/flights/bookingConfirmation/BookingConfirmationContainer"
import LoginContainer from './pages/flights/home/LoginContainter'
import { env } from './appConfig';
import queryString from 'query-string'
import { useState } from 'react';
import { ProtectedRoutes } from './ProtectedRoutes';
import IntermediateBookingConfirmation from './pages/flights/bookingConfirmation/IntermediateBookingConfirmation';
// const BookingConfirmationContainer = lazy(() => './pages/flights/bookingConfirmation/BookingConfirmationContainer') 
const ResultsContainer = lazy(() => import("./pages/flights/results/ResultsContainer"));
const ResultsContainerFlexiDate = lazy(() => import("./pages/flights/results/ResultsContainerFlexiDate"));
const BookingResultContainer = lazy(() => import("./pages/flights/booking/bookingResultContainer"));
const HeaderMyfiles = lazy(() => import("./components/homePageHeader/myFiles/headerMyFiles"));
//const BookingConfirmationContainer = lazy(() => import("./pages/flights/bookingConfirmation/BookingConfirmationContainer"));
const SavedQuoteDetails = lazy(() => import("./components/homePageHeader/mySavedQuotes/SavedQuoteDetails"));
const PaymentDetailContainer = lazy(() => import("./pages/flights/paymentDetail/PaymentDetailContainer"));
const MySavedQuotes = lazy(() => import("./components/homePageHeader/mySavedQuotes/MySavedQuotes"));
const BrandedFareContainer = lazy(() => import("./pages/flights/brandedFare/BrandedFareContainer"));
const BookingCancellationContainer = lazy(() => import("./pages/flights/bookingConfirmation/BookingCancellationContainer"));
const CalculatorContainer = lazy(() => import("./pages/flights/home/CalculatorContainer"));
const CalculatorBrandedFareContainer = lazy(() => import("./pages/flights/home/CalculatorBrandedFareContainer"));
const BlockSpaceContainer = lazy(()=> import("./pages/flights/blockSpace/BlockSpaceContainer"));
const BlockSpaceBookingContainer = lazy(()=> import("./pages/flights/blockSpace/BlockSpaceBookingContainer"));
const BlockSpaceConfirmationContainer = lazy(()=> import("./pages/flights/blockSpace/BlockSpaceConfirmationContainer"));
const BlockSpacePaymentContainer = lazy(()=> import("./pages/flights/blockSpace/BlockSpacePaymentContainer"));
let isHeaderCalled = false;
const history = () => {
	return createBrowserHistory();
};


function addNewRemoveHeaderFooter(location,token,language){
    const isHeaderFooterAvailable = document.querySelector("#traveBrandHeader");
    const isHeaderFooterAvail = document.querySelector(".travelbrands-header");
    if(location.pathname !== "/login" && !isHeaderFooterAvailable && !isHeaderFooterAvail){
        const elBody = document.getElementsByTagName('body');
        var xhr= new XMLHttpRequest();
        xhr.open('GET', `${env.REACT_APP_NEW_HEADER_URL}?token=${token}&lang=${language}`,true);
        xhr.onreadystatechange = function() {
        if (this.readyState!==4) return;
        if (this.status!==200) return;
        elBody[0].insertAdjacentHTML('afterbegin', this.responseText);
        };
        xhr.send();
        // FOOTER CODE
        var xhr= new XMLHttpRequest();
        xhr.open('GET', `${env.REACT_APP_NEW_FOOTER_URL}?lang=${language}&token=${token}`,true);
        xhr.onreadystatechange = function() {
        if (this.readyState!==4) return;
        if (this.status!==200) return;
		elBody[0].insertAdjacentHTML('beforeend', this.responseText);
        };
        xhr.send();
    } else if(location.pathname === "/login" && (isHeaderFooterAvailable || isHeaderFooterAvail)){
        const head = document.querySelector("head");
        const body = document.querySelector("body");
        const header = document.querySelector("#hdrWrap");
        isHeaderFooterAvailable && head.removeChild(isHeaderFooterAvailable);
		isHeaderFooterAvail && head.removeChild(isHeaderFooterAvail);
        if(header){
            const topImg = document.querySelector("#topImg");
            const footerWrapper = document.querySelector("#footerWrapper");
            header && body.removeChild(header);
            topImg && body.removeChild(topImg);
            footerWrapper && body.removeChild(footerWrapper);
        }
        window.location.reload()
    }
}

function addRemoveHeaderFooter(location, isInternalUser,language){
		const isHeaderFooterAvailable = document.querySelector("#traveBrandHeader");
		const isNewHeader = document.querySelector(".main-header");	
		const headerURL = language === "fr" ? env.REACT_APP_HEADER_URL_FRENCH : env.REACT_APP_HEADER_URL;
		const footerURL = language === "fr" ? env.REACT_APP_FOOTER_URL_FRENCH : env.REACT_APP_FOOTER_URL;
		if(location.pathname !== "/login" && !isHeaderFooterAvailable && !isNewHeader){
			const script = document.createElement('script');
			script.setAttribute('src', headerURL);
			script.setAttribute('id', "traveBrandHeader");

			script.setAttribute('async', '');
			script.onload = function handleScriptLoaded() {
			console.log('script has loaded');
			// document.getElementById('box')?.textContent = 'Script loaded successfully';
			};

			script.onerror = function handleScriptError() {
			console.log('error loading script');
			};

			document.head.appendChild(script);

			// FOOTER CODE
			const elBody = document.getElementsByTagName('body');
			var xhr= new XMLHttpRequest();
			xhr.open('GET', footerURL,true);
			xhr.onreadystatechange = function() {
			if (this.readyState!==4) return;
			if (this.status!==200) return;
				elBody[0].insertAdjacentHTML('beforeend', this.responseText);
			};
			xhr.send();
			if(isInternalUser){
				let time = 0;
				let tOut = setInterval(() => {
					const parent = document.querySelector("#subNav");
					const gearIcon = document.querySelector("#gear");
					if(parent && gearIcon){
						parent.removeChild(gearIcon);
						clearTimeout(tOut);
					} else if(time <= 9){
						time += 1;
					}else{
						clearTimeout(tOut);
					}
				}, 100);
			}
		} else if(location.pathname === "/login" && isHeaderFooterAvailable){
			const head = document.querySelector("head");
			const body = document.querySelector("body");
			const header = document.querySelector("#hdrWrap");
			head.removeChild(isHeaderFooterAvailable);
			if(header){
				const topImg = document.querySelector("#topImg");
				const footerWrapper = document.querySelector("#footerWrapper");
				header && body.removeChild(header);
				topImg && body.removeChild(topImg);
				footerWrapper && body.removeChild(footerWrapper);
			}
			window.location.reload()
		}
}
function Routes() {
	const location = useLocation();
	const isInternalUserAutheticated = useSelector((state) => state.internalUser.isInternalUserAutheticated);
	let token = useSelector((state) => state.agentProfile.agentToken);
	const userInfoFromUrl = queryString.parse(location && location.search);
    const newAccess = userInfoFromUrl && userInfoFromUrl?.isNewAccess;
	const newToken = userInfoFromUrl && userInfoFromUrl?.token;
	const newUser = newToken?.includes("XSPlus") || token?.includes("XSPlus");
	const language = useSelector( (state) => state.lang.lang)
	const status = useSelector((state) => state.agentProfile.isAgentAutheticated);
	const [tokenPresent, setTokenPresent] = useState(false)
	const flightType = useSelector((state)=>state.searchForm.flightType)
	const orgId = useSelector((state) => state.agentProfile.orgId);;

	useEffect(() => {
		if(location.pathname !== "/" || isInternalUserAutheticated){
		if (newUser == true || tokenPresent) {
			if (token && newUser){
				if(!isHeaderCalled){
					addNewRemoveHeaderFooter(location, token,language)
					isHeaderCalled = true;
				}
			}
		} else {
			addRemoveHeaderFooter(location, isInternalUserAutheticated,language);
		}
	   }
	}, [location.pathname, token,status]);

	useEffect(() => {
		const newUser = token?.includes("XSPlus");
		if (newUser == true) {
			if (token) {
				setTokenPresent(true)
			}
		} else {
			setTokenPresent(false)
		}
	}, [token])

	useEffect(()=>{
		if(flightType == "CAD"){
			window.selectedDollarValue = '$'
		}
		else{
			window.selectedDollarValue = '$'
		}
	})

	return (
		<Suspense
			fallback={
				<div className="col-md-12 text-center mt-3">
					{/* <SpinnerLoader /> */}
				</div>
			}
		>
			<Switch history={history}>

				<Route
					exact
					path="/"
					component={SsoAuthContainer}
				/>
				<Route
					exact
					path="/login"
					component={LoginContainer}
				/>
				<ProtectedRoutes path="/SearchResults" component={ResultsContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/BookingPage" component={BookingResultContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/SearchResultsFlexiDate" component={ResultsContainerFlexiDate} auth={String(orgId).length} />
				<ProtectedRoutes path="/HeaderMyFiles" component={HeaderMyfiles} auth={String(orgId).length} />
				<ProtectedRoutes path="/MySavedQuotes" component={MySavedQuotes} auth={String(orgId).length} />
				<ProtectedRoutes path="/saved-quotes/details" component={SavedQuoteDetails} auth={String(orgId).length} />
				<ProtectedRoutes path="/booking-confirmation" component={BookingConfirmationContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/payment-details" component={PaymentDetailContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/branded-fare" component={BrandedFareContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/booking-cancellation" component={BookingCancellationContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/calculator" component={CalculatorContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/branded-fare-calculator" component={CalculatorBrandedFareContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/BlockSpace" component={BlockSpaceContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/BlockSpaceBooking" component={BlockSpaceBookingContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/BlockSpaceConfirmation" component={BlockSpaceConfirmationContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/BlockSpacePayment" component={BlockSpacePaymentContainer} auth={String(orgId).length} />
				<ProtectedRoutes path="/intermediate-booking-confirmation" component={IntermediateBookingConfirmation} auth={String(orgId).length} />
			</Switch>
		</Suspense>
	)
}

export default Routes;