import { env } from '../../appConfig'
import { setEquivalentFareLoader, setEquivalentFareValue } from "../actionCreators/flightBooking";
import getApiResponse from "../../services/getApiResponse";

export const getEquivalentPublishFare = (fareId,flow,quoteId) => {
 
    return async (dispatch, getState) => {
        dispatch(setEquivalentFareLoader(true))
        const corrId = getState().searchForm.uuid;
        const tokenData = getState().initToken;
        const orgId = getState().agentProfile.orgId;
        const agencyId = getState().agentProfile.agencyId;
        const agencyGroupId = getState().agentProfile.agencyGroupId; 
        const userId = getState().agentProfile.userId;
        const agentId = getState().agentProfile.agentId;
        const flightType = getState().searchForm.flightType;
        const isInternalUserAutheticated = getState().internalUser.isInternalUserAutheticated;
        const bookingChannel = isInternalUserAutheticated ? "internal" : "external";

        let token;
        let url;
        let request;
        
        if(tokenData) {
            token = tokenData && tokenData.token;
        }
        if(flow === "quoteFlow"){
           url = env.REACT_APP_API_BASE_URL + `/pubFares/getEquivalentPubFares`
           request = {
            organizationId: orgId,
            agencyId: agencyId,
            agencyGroupId: agencyGroupId,
            userId:userId,
            quoteId: quoteId,
            agentId:agentId                   
        }
        }else{
           url = env.REACT_APP_API_BASE_URL + `/pubFares/getEquivalentPubFares/${token}`
           request = {
              fareId: fareId
           }
        }       

          // Axios payload
          const config = {
            method: "POST",
            url: url,
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                
                "Access-Control-Allow-Credentials": true,
                "cache-control": "no-cache",
                correlationId: corrId,
                flightType:flightType,
                bookingChannel:bookingChannel 

            },
            data: request
          }

        
        const publishEquivalentFarResult = await getApiResponse(config);
         
            if(publishEquivalentFarResult && !publishEquivalentFarResult.error){
                if(publishEquivalentFarResult && publishEquivalentFarResult.itineraryFare){
                    dispatch(setEquivalentFareValue(publishEquivalentFarResult.itineraryFare))
                    dispatch(setEquivalentFareLoader(false))
                }else{
                    dispatch(setEquivalentFareValue("NoFare"))
                    dispatch(setEquivalentFareLoader(false))
                }
            }else{
                dispatch(setEquivalentFareValue("NoFare"))
                dispatch(setEquivalentFareLoader(false))
            }
            

         return publishEquivalentFarResult
         
      

    }
}