import getApiResponse from "../../services/getApiResponse";
import { env } from "../../appConfig";
export const getEmail = (body) => {
    return async (dispatch, getState) => {

        const corrId = getState().searchForm.uuid;
        const flightType = getState().searchForm.flightType; 
        const isInternalUserAutheticated = getState().internalUser.isInternalUserAutheticated;
        const bookingChannel = isInternalUserAutheticated ? "internal" : "external";      

        const config = {
            method: "POST",
            url : env.CENTRALIZED_EMAIL_URL + "/sendEmail",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                
                "Access-Control-Allow-Credentials": true,
                correlationId: corrId,
                flightType:flightType,
                bookingChannel:bookingChannel 

            },
            data:
                body
            }
        const emailSendResponse = await getApiResponse(config);
        
        return emailSendResponse
       
    }
}