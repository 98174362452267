
export const initialData = {

    fareId: 0,
    penaltiesTab: {},

    itinenary: [],

    token: null,

    requestBody: {}

};