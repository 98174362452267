import * as actionTypes from './agent_actionTypes';


export const agentValue = (value) => { 
    return {
     type:actionTypes.AGENT_PROFILE_CHANGE,
     val:value,
    };
  };

  export const agentInputValue = (value) => { 
    return {
     type:actionTypes.AGENT_PROFILE_INPUT_CHANGE,
     val:value,
    };
  };

  export const agentBookingId = (value) => { 
    return {
     type:actionTypes.AGENT_PROFILE_ID,
     val:value,
    };
  };


  export const agentEnableData = (value) => { 
    return {
     type:actionTypes.AGENT_PROFILE_ENABLE,
     val:value,
    };
  };


  export const agentEnableChange = (value) => { 
    return {
     type:actionTypes.AGENT_PROFILE_ENABLE_ONE,
     val:value,
    };
  };


  export const agentBookingIdChange = (value) => { 
    return {
     type:actionTypes.AGENT_PROFILE_ID_ONE,
     val:value,
    };
  };

  
export const agentChange = (value) => { 
  return {
   type:actionTypes.AGENT_PROFILE_CHANGE_ONE,
   val:value,
  };
};

export const agentInputChange = (value) => { 
  return {
   type:actionTypes.AGENT_PROFILE_INPUT_CHANGE_ONE,
   val:value,
  };
};

export const setAgencyDetails = (value) => { 
  return {
   type:actionTypes.AGENT_PROFILE_DETAIL,
   val:value,
  };
};

