import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { airlineWebsite } from '../../utils/constant';


function DuffleClassOfServicePopUp(props) {
    const { open, from, data, flowFrom, carrier } = props

    const handleClose = () => {
        props.handleClose()
    }
    const { t } = useTranslation();

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="fareType-dialog"
        >
            <div class="modal-header">
                <h5 class="modal-title" >{t("The booking class along with all the fare option details and rules are shown on ")}<a href={airlineWebsite?.[carrier]?.website} target="_blank">{airlineWebsite?.[carrier]?.name}</a></h5>
            </div>
        </Dialog>
    );
}

export default DuffleClassOfServicePopUp;

