import { setAgencyList } from "../../../src/appStore/actionCreators/searchForm";
import { env } from '../../appConfig'
import getApiResponse from "../../services/getApiResponse";

export const getAgency = (value) => {
 
        return async (dispatch, getState)  => {
         
        const orgId  = getState().agentProfile.orgId;
        const corrId = getState().searchForm.uuid;
        const flightType = getState().searchForm.flightType;
        const isInternalUserAutheticated = getState().internalUser.isInternalUserAutheticated;
        const bookingChannel = isInternalUserAutheticated ? "internal" : "external";
        const agecnyData = [];

        const config = {
            method: "GET", 
            url : env.REACT_APP_PROFILE_ORG_BASE_URL + `/travelBusinesses/autoSuggest?orgId=${orgId}&adAgencyCode=${value}`,
            redirect: "follow",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                
                correlationId: corrId,
                flightType:flightType,
                bookingChannel:bookingChannel
            },
        }
         
        const result =  await getApiResponse(config);  
        result && result && Array.isArray(result) && result.map((data)=> { 
            data['seacrhVariable'] = `${data.adAgencyCode} - ${data.searchTravelBusinessesName} - ${data.adNetworkId}`
            agecnyData.push({value: data, label: data['seacrhVariable'] });
        })
        if(result?.message === "No records found."){
           dispatch((setAgencyList([])));
        } else {
           dispatch((setAgencyList(agecnyData)));
        }



    }
}