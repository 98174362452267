
import { useDispatch } from 'react-redux';
import { setFareOption, setActiveFareId, setFareIdForBrandedFare, setActiveIndexForBrandedFare, setKeyForBrandedFareId,
    setRadioClickedStatus, setInboundBrandData, setGrpItinenaryFareId, setBrandedItinenaryId, setBrandCode } from '../actionCreators/fareOption';

export function ResetFareOptions() {
    const dispatch = useDispatch();

    dispatch(setFareOption([]));
    dispatch(setActiveFareId(""));
    dispatch(setFareIdForBrandedFare([]));
    dispatch(setActiveIndexForBrandedFare(0));
    dispatch(setKeyForBrandedFareId(""));
    dispatch(setRadioClickedStatus([]));
    dispatch(setInboundBrandData(""));
    dispatch(setGrpItinenaryFareId(""));
    dispatch(setBrandedItinenaryId(""));
    dispatch(setBrandCode(""));
}