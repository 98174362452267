import { ResetPNRToken } from './stateManagment/ResetPNRToken';
import { ResetAgent } from './stateManagment/ResetAgent';
import { ResetFareMatrix } from './stateManagment/ResetFareMatrix';
import { ResetFareOptions } from './stateManagment/ResetFareOptions';
import { ResetFlightBooking } from './stateManagment/ResetFlightBooking';
import { ResetInitToken } from './stateManagment/ResetInitToken';
import { ResetItinenary } from './stateManagment/ResetItinenary';
import { ResetMyFiles } from './stateManagment/ResetMyFiles';
import { ResetMyQuotes } from './stateManagment/ResetMyQuotes';
import { ResetPenaltiesTab } from './stateManagment/ResetPenaltiesTab';
import { ResetUser } from './stateManagment/ResetUser';
import { ResetSearchForm } from './stateManagment/ResetSearchForm';
import { ResetFilter } from './stateManagment/ResetFilter';

export function  resetAppStore() {
    ResetPNRToken();
    ResetAgent();
    ResetFareMatrix();
    ResetFareOptions();
    ResetFlightBooking();
    ResetInitToken();
    ResetItinenary();
    ResetMyFiles();
    ResetMyQuotes();
    ResetPenaltiesTab();
    ResetUser();
    ResetSearchForm();
    ResetFilter();
}