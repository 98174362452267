import { SET_INTERNAL_USER_LOGIN, SET_INTERNAL_USER_ACCESS_TOKEN, SET_LOGGED_IN_USER_DATA } from '../storeConstants';

export const setInternalUserLogin = (value) => {
    return {
        type: SET_INTERNAL_USER_LOGIN,
        payload: value
    }
}

export const setInternalUserAccessToken = (value) => {
    return {
        type: SET_INTERNAL_USER_ACCESS_TOKEN,
        payload: value
    }
}

export const setLoggedInUserData = (value) => {
    return {
        type: SET_LOGGED_IN_USER_DATA,
        payload: value
    }
}
