import * as actionTypes from "../../actionCreators/OpenJaw/openjaw_actionTypes";

const initialState = {
  openJaw_From_A: "",
  openJaw_To_A: "",

  openJaw_From_B: "",
  openJaw_To_B: "",

  openJaw_Date_A: null,
  openJaw_Date_B: null,

  openJaw_Time_A: "",
  openJaw_Time_B: "",

  openJawAdult_count: 1,
  openJawChild_count: 0,
  openJawInfant_count: 0,

  openJawAir: true,
  openJawLand: false,
  openJawCruise: false,
  openJawLeast: false,
  openJawBasic: false,
  openJawMulti: false,
  openJawNear: false,
  openJawFlexible: false,

  cabin_openJawValue: "",
  max_openJawConn: "Two",

  free_open_R1: false,
  fee_open_R2: false,
  anyallin_open_R1: false,
  skyteam_open_R2: false,
  staralli_open_R3: false,
  oneworld_open_R4: false,

  openjaw_token: "",

  // validation

  flying_from_oj_err: "",
  flying_to_oj_err: "",
  flying_from_ojb_err: "",
  flying_to_ojb_err: "",

  flying_date_oja_err: "",
  flying_date_ojb_err: "",
  adult_oj_err: "",

  openJawData:[]
};

const OpenJawReducer = (state = initialState, action) => {
  switch (action.type) {
    // search

    case actionTypes.OPENJAW_FROM_A:
      return {
        ...state,
        openJaw_From_A: action.val,
      };

    case actionTypes.OPENJAW_TO_A:
      return {
        ...state,
        openJaw_To_A: action.val,
      };

    case actionTypes.OPENJAW_FROM_B:
      return {
        ...state,
        openJaw_From_B: action.val,
      };

    case actionTypes.OPENJAW_TO_B:
      return {
        ...state,
        openJaw_To_B: action.val,
      };

    // date

    case actionTypes.OPENJAW_DATE_A:
      return {
        ...state,
        openJaw_Date_A: action.val,
      };

    case actionTypes.OPENJAW_DATE_B:
      return {
        ...state,
        openJaw_Date_B: action.val,
      };

    //time

    case actionTypes.OPENJAW_TIME_A:
      return {
        ...state,
        openJaw_Time_A: action.val,
      };

    case actionTypes.OPENJAW_TIME_B:
      return {
        ...state,
        openJaw_Time_B: action.val,
      };

    // counter
    case actionTypes.OPEN_JAW_ADULT_VALUE:
      return {
        ...state,
        openJawAdult_count: action.val,
      };

    case actionTypes.OPEN_JAW_CHILD_VALUE:
      return {
        ...state,
        openJawChild_count: action.val,
      };

    case actionTypes.OPEN_JAW_INFANT_VALUE:
      return {
        ...state,
        openJawInfant_count: action.val,
      };

    // checkbox

    case actionTypes.OPEN_JAW_AIR:
      return {
        ...state,
        openJawAir: action.val,
      };

    case actionTypes.OPEN_JAW_LAND:
      return {
        ...state,
        openJawLand: action.val,
      };

    case actionTypes.OPEN_JAW_CRUISE:
      return {
        ...state,
        openJawCruise: action.val,
      };

    case actionTypes.OPEN_JAW_LEAST:
      return {
        ...state,
        openJawLeast: action.val,
      };

    case actionTypes.OPEN_JAW_BASIC:
      return {
        ...state,
        openJawBasic: action.val,
      };

    case actionTypes.OPEN_JAW_MULTI:
      return {
        ...state,
        openJawMulti: action.val,
      };

    case actionTypes.OPEN_JAW_NEAR:
      return {
        ...state,
        openJawNear: action.val,
      };

    case actionTypes.OPEN_JAW_FLEXIBLE:
      return {
        ...state,
        openJawFlexible: action.val,
      };

    // Dropdown
    case actionTypes.OPEN_JAW_CABIN:
      return {
        ...state,
        cabin_openJawValue: action.val,
      };

    case actionTypes.OPEN_JAW_MAXIMUM_CONN:
      return {
        ...state,
        max_openJawConn: action.val,
      };

    // radio-button

    case actionTypes.OPEN_JAW_FREE:
      return {
        ...state,
        free_open_R1: action.val,
      };

    case actionTypes.OPEN_JAW_FEE:
      return {
        ...state,
        fee_open_R2: action.val,
      };

    case actionTypes.OPEN_ANY_ALLIANCE:
      return {
        ...state,
        anyallin_open_R1: action.val,
      };

    case actionTypes.OPEN_SKY_TEAM:
      return {
        ...state,
        skyteam_open_R2: action.val,
      };

    case actionTypes.OPEN_STAR_ALLIANCE:
      return {
        ...state,
        staralli_open_R3: action.val,
      };

    case actionTypes.OPEN_JAW_WORLD:
      return {
        ...state,
        oneworld_open_R4: action.val,
      };

    case actionTypes.REST_OPEN_JAW:
      return {
        ...initialState,
      };

    case actionTypes.FLYING_FROM_OJ_ERROR:
      return {
        ...state,
        flying_from_oj_err: action.val,
      };

    case actionTypes.FLYING_TO_OJ_ERROR:
      return {
        ...state,
        flying_to_oj_err: action.val,
      };

    case actionTypes.FLYING_FROM_OJ_B_ERROR:
      return {
        ...state,
        flying_from_ojb_err: action.val,
      };

    case actionTypes.FLYING_TO_OJ_B_ERROR:
      return {
        ...state,
        flying_to_ojb_err: action.val,
      };

    case actionTypes.FLYING_DATE_OJA_ERROR:
      return {
        ...state,
        flying_date_oja_err: action.val,
      };

    case actionTypes.FLYING_DATE_OJB_ERROR:
      return {
        ...state,
        flying_date_ojb_err: action.val,
      };

    case actionTypes.ADULT_OJ_ERROR:
      return {
        ...state,
        adult_oj_err: action.val,
      };

      case actionTypes.SET_OPENJAW_TOKEN:
      return {
        ...state,
        openjaw_token: action.val,
      };

      case actionTypes.SET_OPENJAW_DATA:
         return {
           ...state,
           openJawData:action.val,
         };



    default:
      return state;
  }
};

export default OpenJawReducer;
